@charset "UTF-8";
/*-----------------------------------------------------------------------------------
	Template Name: MyConextion
	Description: Multipurpose Landing Page HTML Template
	Author: WebTend 
	Author URI: https://webtend.net/
	Version: 1.0

-----------------------------------------------------------------------------------
	
	>>> TABLE OF CONTENTS:
	=======================

	01. Common/Reusable CSS
		1.1 - Utility CSS
		1.2 - Section Padding
		1.3 - Template Button 
		1.4 - Section Title
		1.5 - Search Modal Form
		1.6 - Custom Animation 
		1.7 - Preloader
		1.8 - Scroll To Top
		1.9 - List Style
		1.10 - List Style V2
		1.11 - List Style V3
		1.12 - Header CSS
		1.13 - Menu Active Overlay
		1.14 - Header OffCanvas Menu
		1.15 - Sticky Menu
		1.16 - Language Selection Dropdown
		1.17 - Header Navigation V2
		1.18 - Header Navigation V3
		1.19 - Header Navigation V4
		1.20 - Header Navigation V5
		1.21 - Header Navigation V6
		1.22 - Breadcrumb Area 
		1.23 - Particle Effect 
		1.24 - Particle Effect V2
		1.25 - Particle Effect V3
		1.26 - Particle Effect V4
	02. App Feature Area 
		2.1 - Single Service Box
		2.2 - Single Service Box V2
		2.3 - Single Service Box V3
	03. Data Analysis Feature Area 
	04. What We Do / Services Area
		4.1 - Single Feature Box
		4.2 - Single Feature Box V2
		4.3 - Single Feature Box V2 Border Effect 
		4.4 - Single Feature Box V3
	05. App Statistic Area
		5.1 - Company Statistic Area
	06. Testimonial Area
		6.1 - Single Testimonial
		6.2 - Single Testimonial V2
		6.3 - Single Testimonial V3
		6.4 - Single Testimonial V4
		6.5 - Single Testimonial V5
		6.6 - Single Testimonial V6
		6.7 - Testimonial V5 Right Image
		6.8 - Testimonial Custom Rating
		6.9 - Testimonial Sliders Arrows
		6.10 - Testimonial Sliders Dots
		6.11 - Testimonial Slider V2 Slick Dots
	07. Counter Up Area
		7.1 - Single Counter Up Box
		7.2 - Counter Up Box V2
		7.3 - Counter Up Box
		7.4 - Fact and CTA Area
		7.5 - Simple Counter Up
	08. Call to Action Area
		8.1 - Call to Action V2
		8.2 - Simple CTA Area
		8.3 - App CTA Area
		8.4 - Video and CTA Area
	09. Hero Area 
		9.1 - Hero Area V1
		9.2 - Hero Area V2
		9.3 - Hero Area V3
		9.4 - Hero Area V4
		9.5 - Hero Area V5
		9.6 - Hero Area V6
	10. Brands Slider
		10.1 - Brands Slider V2
	11. Team Member Area
		11.1 - Single Team Member 
		11.2 - Single Team Member V2
	12. Newsletter Search Area 
		12.1 - Newsletter Form 
		12.2 - Newsletter Form V2
		12.3 - Newsletter Search Area V2 
		12.4 - Subscribe Newsletter 
	13. FAQ Area 
		13.1 - FAQ Accordion 
		13.2 - FAQ Accordion V2 
	14. Pricing Table
		14.1 - Pricing Table V2
	15. Blog and News Area
		15.1 - Blog and News V2
		15.2 - Blog Post Item V2
		15.3 - Blog Post Item V4
		15.4 - Blog Post Item V5
	16. Features Area 
		16.1 - Single Iconic Box 
		16.2 - Iconic Box V2
		16.3 - Iconic Box V3
		16.4 - Iconic Box V4
		16.5 - Iconic Box V5
		16.6 - Simple Icon Box
		16.7 - Simple Icon Box V2
	17. Manage Data Area Iconic List
		17.1 - Single Iconic List 
		17.2 - Iconic list V2
		17.3 - Why Choose Us Area (Iconic List)
	18. Collaboration Area
		18.1 - Collaboration Feature Preview
		18.2 - Collaboration Area V2
	19. UI Design Feature Area
	20. Manage Data Feature Area
	21. Featured Video
	22. About Us Area
		22.1 - Why Choose Us Area
	23. Team Skills Area 
		23.1 - Team Skills Bar 
	24. Contact Page 
		24.1 - Contact Info Box
		24.2 - Contact Box
		24.3 - Single Info Box
		24.4 - Our Services Area V3
		24.5 - Services Law Area
		24.6 - Contact Form Area
		24.7 - Contact Form Area V2
		24.8 - Contact Page Maps
	25. 404 Not Found Area 
	26. Coming Soon Page
	27. Service Details Page
	28. Blog Standard Page
	29. Blog Details Page 
		29.1 - Blog Details Comments Area
		29.2 - Blog Details Comments Form
		29.3 - Blog Posts Sidebar
		29.4 - Blog Posts Pagination 
	30. Footer Area 
		30.1 - Footer Area V2
		30.2 - Footer Area V3
		30.2 - Footer Area V4
	
-----------------------------------------------------------------------------------*/

/* 01. Common/Reusable CSS */
@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/circularstd/CircularStdBold.ttf');
	src: url('../fonts/circularstd/CircularStdBold.eot') format('embedded-opentype'), url('../fonts/circularstd/CircularStdBold.woff2') format('woff2'), url('../fonts/circularstd/CircularStdBold.woff') format('woff'), url('../fonts/circularstd/CircularStdBold.ttf') format('truetype'), url('../fonts/circularstd/CircularStdBold.svg#CircularStdBold') format('svg');
}
@font-face {
	font-family: 'CircularStdMedium';
	src: url('../fonts/circularstd/CircularStdMedium.ttf');
	src: url('../fonts/circularstd/CircularStdMedium.eot') format('embedded-opentype'), url('../fonts/circularstd/CircularStdMedium.woff2') format('woff2'), url('../fonts/circularstd/CircularStdMedium.woff') format('woff'), url('../fonts/circularstd/CircularStdMedium.ttf') format('truetype'), url('../fonts/circularstd/CircularStdMedium.svg#CircularStdMedium') format('svg');
}
@font-face {
	font-family: 'CircularStdBook';
	src: url('../fonts/circularstd/CircularStdBook.ttf');
	src: url('../fonts/circularstd/CircularStdBook.eot') format('embedded-opentype'), url('../fonts/circularstd/CircularStdBook.woff2') format('woff2'), url('../fonts/circularstd/CircularStdBook.woff') format('woff'), url('../fonts/circularstd/CircularStdBook.ttf') format('truetype'), url('../fonts/circularstd/CircularStdBook.svg#CircularStdBook') format('svg');
} 

.hidden {
	display: none !important;
}


body {
	font-family: 'Open Sans';
	font-size: 17px;
	color: #003669;
	line-height: 30px;
	font-weight: 300;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-family: 'Open Sans';
	font-weight: 700;
}
h1 {
	font-size: 65px;
	line-height: 80px;
}
h2{
	font-size: 42px;
	line-height: 50px;
}
h3 {
	font-size: 30px;
	line-height: 38px;
}
h4{
	font-size: 24px;
	line-height: 32px;
}
h5 {
	font-size: 20px;
	line-height: 28px;
}
h6 {
	font-size: 16px;
	line-height: 24px;
}
p {
	margin: 0px;
	padding: 0px;
}
img {
	max-width: 100%;
	height: auto;
}
ul, ol {
	list-style: none;
	padding: 0;
	margin: 0;
}
a {
	color: inherit;
	text-decoration: none;
	-webkit-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
}
a:hover, a:focus {
	text-decoration: none;
}
a:focus, input:focus, textarea:focus, button:focus, .form-control:focus {
	text-decoration: none;
	outline: none;
	-webkit-box-shadow: 0px 10px 30px 0px #dbe6f1;
	box-shadow: 0px 10px 30px 0px #dbe6f1;
	border-color: #dbe6f1;
}
i, span, a {
	display: inline-block;
}
button {
	border: none;
}

.pull-right {
	float: right;
}

.fa-warning:before, .fa-exclamation-triangle:before {
	content: "\f071";
}

.fa-circle-o-notch:before {
	content: "\f1ce";
}


/* Utility CSS */
.bg-ocean-blue {
	background-color: #0040e5;
}
.bg-clear-blue {
	background-color: #034380;
}
.bg-royal-blue {
	background-color: #034380;
}
.bg-burning-orange{
	background-color: #F65911;
}
.bg-gray-dark {
	background-color: #6e727d;
}
.bg-magnolia {
	background-color: #eef2ff;
}
.bg-light-magnolia {
	background-color: #F1F5FF;
}
.bg-water {
	background-color: #EDF2FD;
}
.bg-tuna {
	background-color: #36234B;
}
.bg-alice-blue {
	background-color: #F0F6FF;
}
.bg-solid-dark {
	background-color: #1B202F;
}
.bg-dark-black{
	background-color: #003669;
}
.bg-dark-blue {
	background-color: #2B354F;
}
.bg-dark-black-v2 {
	background-color: #1B202F;
}
.bg-white-lilac {
	background-color: #F4F7FA;
}
.bg-contain {
	background-size: contain;
	background-position: top center;
	background-attachment: scroll;
	background-repeat: no-repeat;
}
.bg-cover{
	background-size: cover;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
}
.border-top{
	border-top: 1px solid #EDF2FD;
}
.border-top-blue{
	border-top: 1px solid rgb(71 59 240 / 10%);
}
.border-bottom-purple{
	border-bottom: 1px solid rgb(130 8 254 / 12%);
}
.section-gradient-1 {
	background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
}
.position-relative{
	position: relative;
	z-index: 1;
}
.content-left-spacer {
	padding-left: 70px;
}
.content-right-spacer{
	padding-right: 70px;
}

/* Section Padding */
.section-padding-top {
	padding-top: 130px;
}
.section-padding-bottom {
	padding-bottom: 130px;
}
.section-padding-bottom-2{
	padding-bottom: 80px;
}
.section-padding-2 {
	padding: 85px 0;
}
.section-padding{
	padding: 130px 0;
}
/* Template Button */
.filled-btn, .filled-btn.btn-bordered {
	display: inline-block;
	background: #034380;
	color: #fff;
	padding: 12px 40px;
	font-size: 15px;
	border-radius: 50px;
	font-family: 'Open Sans';
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	cursor: pointer;
}
.filled-btn i, .filled-btn.btn-bordered i {
	margin-left: 8px;
	font-size: 15px;
}
.filled-btn, .filled-btn.btn-bordered {
	position: relative;
	z-index: 1;
	overflow: hidden;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.36);
	box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.36);
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	text-transform: capitalize;
	vertical-align: middle;
}
.filled-btn.button-radius,
.filled-btn.button-radius:after,
.filled-btn.btn-bordered.button-radius,
.filled-btn.btn-bordered.button-radius:after{
	border-radius: 7px;
}
.filled-btn:after, .filled-btn.btn-bordered:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 0;
	top: 0;
	left: 0;
	background-color: #003669;
	z-index: -1;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	border-radius: 50px;
}
.filled-btn.btn-bordered {
	background: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #003669;
	border: 2px solid #D1D1F8;
	padding: 10px 40px;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.filled-btn.btn-bordered {
	background: transparent;
	border: 2px solid #0040E5;
	color: #0040E5;
}
.filled-btn:hover:after, .filled-btn.btn-bordered:hover:after {
	width: 100%;
}
.filled-btn:hover {
	-webkit-box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
	box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
	color: #fff;
}
.filled-btn.btn-bordered:hover {
	color: #fff;
	background: #003669;
}
.filled-btn.btn-bordered:hover {
	border: 2px solid #003669;
}
.filled-btn.filled-bg-white{
	background-color: #ffffff;
	-webkit-box-shadow: none;
			box-shadow: none;
	color: #003669;
}
.filled-btn.filled-bg-white:hover {
	background-color: #003669;
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
}
.filled-btn.filled-bg-white:hover{
	color: #fff;
}
.filled-btn.bg-rose-bud{
	background-color: #F6B9A7;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(246, 185, 167, 0.61);
			box-shadow: 0px 10px 30px 0px rgba(246, 185, 167, 0.61);
	color: #034380;
}
.filled-btn.bg-rose-bud:hover {
	background: #003669;
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
	color: #fff;
}
.filled-btn.bg-mandy{
	background-color: #E25C6A;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36);
	color: #fff;
}
.filled-btn.bg-purple{
	background-color: #8208FE;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(130, 8, 254, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(130, 8, 254, 0.36);
	color: #fff;
}
.filled-btn.bg-mandy:after,
.filled-btn.bg-purple:after,
.filled-btn.bg-blue-chalk.button-radius:after{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #003669;
	content: "";
	border-radius: 7px;
	-webkit-transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
			transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
	-webkit-transform-origin: bottom center;
		-ms-transform-origin: bottom center;
			transform-origin: bottom center;
	-webkit-transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
	transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
	-o-transition: transform 0.4s linear, transform 0.4s linear;
	transition: transform 0.4s linear, transform 0.4s linear;
	transition: transform 0.4s linear, transform 0.4s linear, -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
	z-index: -1;
}
.filled-btn.bg-mandy:hover:after,
.filled-btn.bg-purple:hover:after,
.filled-btn.bg-blue-chalk.button-radius:hover:after{
	-webkit-transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
			transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
	-webkit-transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
	transition: -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
	-o-transition: transform 0.4s linear, transform 0.4s linear;
	transition: transform 0.4s linear, transform 0.4s linear;
	transition: transform 0.4s linear, transform 0.4s linear, -webkit-transform 0.4s linear, -webkit-transform 0.4s linear;
}
.filled-btn.bg-tuna{
	background-color: #36234B;
	color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(54, 35, 75, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(54, 35, 75, 0.36);
}
.filled-btn.bg-ocean-blue{
	background-color: #0040e5;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(0, 64, 229, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(0, 64, 229, 0.36);
}
.filled-btn.bg-ocean-blue:hover {
	-webkit-box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
	box-shadow: 0 0 30px 0px rgb(0 0 0 / 30%);
}
.filled-btn.bg-magenta{
	background-color: #ED00CE;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(237, 0, 206, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(237, 0, 206, 0.36);
}
.filled-btn.bg-magenta:hover, 
.filled-btn.bg-burning-orange:hover,
.filled-btn.bg-rose-bud:hover,
.filled-btn.bg-mandy:hover,
.filled-btn.bg-purple:hover{
	-webkit-box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
			box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
}
.filled-btn.bg-ocean-blue-gradient{
	background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
			box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
}
.filled-btn.bg-ocean-blue-gradient:after{
	background-color: transparent;
	background-image: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
	box-shadow: 0px 7px 25px 0px rgb(0 64 229 / 40%);
}
.filled-btn.bg-ocean-blue-gradient:hover {
	background: #fff;
	-webkit-transition-delay: 0.2s;
		 -o-transition-delay: 0.2s;
			transition-delay: 0.2s;
}
.filled-btn.bg-burning-orange {
	background-color: #F65911;
	color: #fff;
	-webkit-box-shadow: none;
			box-shadow: none; 
}
.filled-btn.bg-burning-orange.active {
	background-color: #F65911;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(346, 89, 17 0.36);
			box-shadow: 0px 10px 30px 0px rgba(346, 89, 17 0.36); 
}
.filled-btn.bg-burning-orange.active:hover {
	background-color: #C94102;
}

.filled-btn.bg-burning-orange-soft {
	background-color: #F37C21;
	color: #fff;
}
.filled-btn.bg-burning-orange-soft.active {
	background-color: #F37C21;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(243, 124, 33, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(243, 124, 33, 0.36); 
}
.filled-btn.bg-blue {
	background-color: #034380;
	-webkit-box-shadow: none;
			box-shadow: none; 
}
.filled-btn.bg-blue.active {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(3, 67, 128 0.36);
			box-shadow: 0px 10px 30px 0px rgba(3, 67, 128 0.36); 
}
.filled-btn.bg-blue-soft {
	background-color: #054F93;
	color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(5, 79, 147, 0.36);
	box-shadow: 0px 10px 30px 0px rgba(5, 79, 147, 0.36); 
}
.filled-btn.bg-light {
	background-color: #dee2e6 !important;
	color: #003669;
	-webkit-box-shadow: none;
			box-shadow: none; 
}
.filled-btn.bg-light:hover {
	color: #FFFFFF;
}


.filled-btn.bg-blue-chalk{
	background-color: #E5ECFC;
	color: #1a1b1e;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(236, 235, 253, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(236, 235, 253, 0.36); 
}
.filled-btn.bg-blue-chalk:hover{
	color: #fff;
}
.filled-btn.btn-bordered.bg-royal-blue{
	border-color: rgb(71 59 240 / 10%);
	color: #003669;
}
.filled-btn.btn-bordered.bg-royal-blue:hover,
.filled-btn.btn-bordered.bg-clear-blue:hover{
	color: #fff;
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
}
.filled-btn.btn-bordered.bg-clear-blue{
	background-color: transparent;
	border-color: rgb(51 119 255 / 10%);
	color: #003669;
	box-shadow: none;
}
.filled-btn.btn-bordered.bg-clear-blue:hover {
	border: 2px solid #002547;
	background-color: #002547;
}
.filled-btn.btn-bordered.btn-off-white {
	border: 2px solid #FFFFFF;
	color: #fff;
}
.filled-btn.btn-bordered.btn-off-white:hover {
	box-shadow: none;
	background-color: #fff;
	border-color: #fff;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
	color: #034380;
}
.filled-btn.btn-bordered.btn-off-white:after {
	background-color: #fff;
}
.filled-btn.bg-clear-blue {
	background-color: #034380;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 30%);
	box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 30%);
}
.filled-btn.bg-clear-blue:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
	box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
}
.filled-btn.bg-clear-orange {
	background-color: #F65911;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(246 89 17 / 30%);
	box-shadow: 0px 10px 30px 0px rgb(246 89 17 / 30%);
}
.filled-btn.bg-clear-orange:hover {
	-webkit-box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
	box-shadow: 0px 10px 30px 0px rgb(22 28 45 / 30%);
}

.link-btn {
	cursor: pointer;
	color: #F65911;
	background: transparent;
}
.link-btn:hover {
	color: #C94102;
}

.navigation-btn {
	cursor: pointer;
	color: #343a40;
	background: transparent;
}
.navigation-btn:hover {
	color: #F65911;
}

.disabled-btn {
	cursor: pointer;
	color: #dee2e6 !important;
}
.disabled-btn:hover {
	color: #b0b5bb !important;
}


.filled-btn.bg-burning-orange {
  box-shadow: 0px 5px 10px 0px rgba(244, 115, 42, 0.36);
}

.filled-btn.disabled-btn {
	background: #a9a9a9 !important;
	color: #FFFFFF !important;
	box-shadow: 0px 5px 10px 0px rgb(169 169 169 / 36%) !important;
}
.filled-btn.disabled-btn:hover {
	color: #FFFFFF !important;
}
.filled-btn.disabled-btn:hover::after {
	background-color: #6e727d !important;
}

.play-btn {
	height: 54px;
	width: 54px;
	background-color: #FFFFFF;
	color: #F65911;
	font-size: 14px;
	text-align: center;
	line-height: 54px;
	border-radius: 50px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.play-btn:hover {
	color: #0040e5;
}
/* Play Video Button Pulse Animation */
.pulse-animated {
	position: relative;
	z-index: 1;
}
.pulse-animated:after {
	content: "";
	position: absolute;
	left: 6px;
	top: 8px;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 56px;
	height: 56px;
	background: rgb(255 255 255 / 0.5);
	border-radius: 50%;
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation: pulse-border 1500ms ease-out infinite;
	z-index: -1;
}
@-webkit-keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}
@keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}


.btn-small {
	padding: 8px 20px !important;
	font-size: 0.85em !important;
}

.btn-small.btn-icon-only {
	padding: 0.75rem !important;
	height: 25px !important;
	width: 25px !important;
}

/* Section Title */
.section-title h2 {
	margin-bottom: 25px;
	font-size: 42px;
	line-height: 50px;
	text-wrap: balance;
}
.section-title h2 span {
	position: relative;
	z-index: 1;
}
.section-title .section-title-quote {
	font-family: 'CircularStdMedium';
	padding-left: 30px;
	margin-bottom: 35px;
}
.section-title .section-title-quote {
	position: relative;
}
.section-title .section-title-quote:after {
	position: absolute;
	content: "";
	left: 0;
	top: 3px;
	background-color: #F65911;
	height: 90%;
	width: 4px;
}
.section-title .section-title-description {
	margin-bottom: 30px;
	color: #606060;
}
.section-title .section-title-description p {
	font-size: 17px;
	color: #6e727d;
	line-height: 30px;
}
.section-title .section-heading-tag {
	margin-bottom: 26px;
}
.section-title .section-heading-tag .single-heading-tag {
	display: inline-block;
}
.section-title .section-heading-tag .single-heading-tag {
	background: #bee0ff;
	color: #034380;
	font-size: 18px;
	height: 32px;
	padding: 0 20px;
	border-radius: 50px;
	line-height: 32px;
	font-family: 'CircularStdMedium';
}
.section-title .section-heading-tag .single-heading-tag.bordered-tag {
	background: transparent;
}
.section-title .section-heading-tag .single-heading-tag.bg-burning-orange{
	background-color: #F65911;
}
.section-title .section-heading-tag .single-heading-tag.bg-transparent-ocean-blue{
	background-color: #1A53E8;
}
.section-title .section-heading-tag .single-heading-tag.bg-royal-blue{
	background: #034380;
	color: #fff;
}
.section-title .section-heading-tag .single-heading-tag.text-white{
	color: #fff;
}
.section-title .section-heading-tag .single-heading-tag.text-old-lavender {
	color: #6f667b;
}
.section-title.section-title-ocean-blue .section-heading-tag .single-heading-tag {
	background-color: #E5ECFC;
	color: #0040E5;
}
.section-title.section-title-ocean-blue .section-heading-tag {
	margin-bottom: 27px;
}
.section-title.section-title-ocean-blue h2 span {
	color: #0040e5;
}
.section-title h2 span:after{
	position: absolute;
	content: "";
	height: 30px;
	width: 100%;
	left: 0;
	bottom: -30px;
	z-index: -1;
	background-attachment: scroll;
	background-position: bottom;
	background-repeat: no-repeat;
	padding-bottom: 8px;
	background-size: contain;
}
.section-title.section-title-white.section-title-white-v2 h2 span:after{
	bottom: -30px;
}
.section-title.section-title-tuna h2 span:after, 
.section-title.section-title-mirage h2 span:after,
.section-title.section-title-white.section-title-white-v2 h2 span:after{
	background-image: url(../img/particle/section-title-v3-line-burning-orange.png);
}
.section-title.section-title-white h2 span:after{
	background-image: url(../img/particle/section-title-v2-line-white.png);
	background-size: contain;
	bottom: -10px;
}
.section-title.section-title-ocean-blue h2 span:after{
	background-image: url(../img/particle/section-title-v2-line-blue-2.png);
	bottom: -24px;
	background-size: contain;
}
.section-title.section-title-tuna h2, 
.section-title.section-title-mirage h2{
	margin-bottom: 70px;
}
.section-title.section-title-tuna .section-heading-tag{
	margin-bottom: 15px;
}
.section-title.section-title-ocean-blue h2{
	margin-bottom: 48px;
}
.section-title.section-title-tuna .section-title-description p{
	color: #6f667b;
}
.section-title.section-title-ocean-blue .section-button-wrapper .filled-btn {
	background: #0040E5;
}
.section-title.section-title-white,
.section-title.section-title-white .section-title-description p {
	color: #fff;
}
.section-title.section-title-white .section-title-quote:after {
	background-color: #fff;
}
.section-title.section-title-tuna {
	color: #034380;
}
.section-title.section-title-mirage {
	color: #003669;
}
.section-title.section-title-mirage .section-heading-tag,
.section-title.section-title-white .section-heading-tag{
	margin-bottom: 15px;
}
.section-title.section-title-mirage .section-heading-tag .single-heading-tag{
	color: #6e727d;
	background: transparent;
	padding: 0;
}
.section-title.section-title-white .filled-btn.btn-bordered {
	border: 2px solid #356CFB;
	color: #fff;
}
.section-title.section-title-white .filled-btn.btn-bordered:hover:after,
.section-title.section-title-white .filled-btn.btn-bordered:after{
	background-color: #fff;
}
.section-title.section-title-white .filled-btn.btn-bordered:hover {
	border-color: #fff;
	color: #0040e5;
}
.section-title.section-title-white .filled-btn.btn-bordered:hover{
	background-color: #fff;
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
}
.section-title .section-button-wrapper.section-dual-button {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	gap: 11px;
	-ms-flex-wrap: wrap;
		flex-wrap: wrap;
}
.section-title.section-title-clear-blue .single-heading-tag {
	padding: 0;
	background: transparent;
	color: #034380;
	font-family: 'Open Sans';
}
.section-title.section-title-clear-blue .section-heading-tag {
	margin-bottom: 15px;
}

/* Search Modal Form */
.search-modal {
	background: rgba(23, 26, 33, 0.85);
}
.search-modal .modal-dialog {
	border: none;
	outline: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.search-modal form {
	max-width: 555px;
	position: relative;
}
.search-modal .modal-content {
	background: 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: none;
}
.search-modal .modal-content:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.search-modal .form_control {
	padding-left: 0;
	background: transparent;
	border: none;
	border-bottom: 1px solid #e5e5e5;
	color: #fff;
	margin-bottom: 0;
	height: 50px;
	width: 100%;
	padding-right: 40px;
}
.search-modal .form_control:focus {
	border-color: #fff;
}
.search-modal .form_control::-webkit-input-placeholder {
	color: #fff;
}
.search-modal .form_control::placeholder {
	color: #fff;
}
.search-modal .form_control:-ms-input-placeholder {
	color: #fff;
}
.search-modal .form_control::-ms-input-placeholder {
	color: #fff;
}
.search-modal .form_control::placeholder {
	color: #fff;
}
.search-modal .search_btn {
	position: absolute;
	background: transparent;
	top: 10px;
	right: 0;
	color: #fff;
}

/* Custom Animation */
.animate-square-zoom {
	position: relative;
	overflow: hidden;
}
.animate-square-zoom:before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	display: block;
	content: "";
	width: 0px;
	height: 0px;
	-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
	opacity: 0;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 7px
}
.animate-square-zoom:hover:before {
	-webkit-animation-duration: 0.95s;
			animation-duration: 0.95s;
	-webkit-animation-timing-function: ease;
			animation-timing-function: ease;
	-webkit-animation-delay: 0s;
			animation-delay: 0s;
	-webkit-animation-iteration-count: 1;
			animation-iteration-count: 1;
	-webkit-animation-direction: normal;
			animation-direction: normal;
	-webkit-animation-fill-mode: none;
			animation-fill-mode: none;
	-webkit-animation-play-state: running;
			animation-play-state: running;
	-webkit-animation-name: circle;
			animation-name: circle;
}
@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
.animate-zoominout{
	animation-name: zoomInOut; 
	animation-duration: 3s; 
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	-webkit-animation-name: zoomInOut; 
	-webkit-animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: ease-in-out;

	animation-name: zoomInOut; 
	animation-duration: 3s; 
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;

	-ms-animation-name: zoomInOut; 
	-ms-animation-duration: 3s; 
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: ease-in-out;
	
	-o-animation-name: zoomInOut; 
	-o-animation-duration: 3s; 
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: ease-in-out;
}
@-webkit-keyframes zoomInOut {
  0% {
	-webkit-transform: rotate(0deg) scale(0.7);
	transform: rotate(0deg) scale(0.7);
	opacity:0;
  }
  50% {
	-webkit-transform: rotate(180deg) scale(1);
	transform: rotate(180deg) scale(1);
	opacity:1;
  }
  100% {
	-webkit-transform: rotate(360deg) scale(0.7);
	transform: rotate(360deg) scale(0.7);
	opacity:0;
  }
}
@keyframes zoomInOut {
  0% {
	-webkit-transform: rotate(0deg) scale(0.7);
	transform: rotate(0deg) scale(0.7);
	opacity:0;
  }
  50% {
	-webkit-transform: rotate(180deg) scale(1);
	transform: rotate(180deg) scale(1);
	opacity:1;
  }
  100% {
	-webkit-transform: rotate(360deg) scale(0.7);
	transform: rotate(360deg) scale(0.7);
	opacity:0;
  }
}
.animate-zoom-fade{
	animation-name: zoom-fade; 
	animation-duration: 5s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	
	-webkit-animation-name: zoom-fade; 
	-webkit-animation-duration: 5s; 
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	
	animation-name: zoom-fade; 
	animation-duration: 5s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	
	-ms-animation-name: zoom-fade; 
	-ms-animation-duration: 5s; 
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;

	-o-animation-name: zoom-fade; 
	-o-animation-duration: 5s; 
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: linear;
}
@-webkit-keyframes zoom-fade {
  0% {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
  }
  50% {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
  }
  100% {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
  }
}
@keyframes zoom-fade {
  0% {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
  }
  50% {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
  }
  100% {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
  }
}
.animate-float-bob{
	animation-name: float-bob; 
	animation-duration: 7s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	
	-webkit-animation-name: float-bob; 
	-webkit-animation-duration: 7s; 
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	
	animation-name: float-bob; 
	animation-duration: 7s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	
	-ms-animation-name: float-bob; 
	-ms-animation-duration: 7s; 
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;
	
	-o-animation-name: float-bob; 
	-o-animation-duration: 7s; 
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: linear;
}
@-webkit-keyframes float-bob {
	0% {
	  -webkit-transform: translateX(-100px);
	  transform: translateX(-100px);
	}
  
	50% {
	  -webkit-transform: translateX(-10px);
	  transform: translateX(-10px);
	}
  
	100% {
	  -webkit-transform: translateX(-100px);
	  transform: translateX(-100px);
	}
}
@keyframes float-bob {
	0% {
	  -webkit-transform: translateX(-100px);
	  transform: translateX(-100px);
	}
  
	50% {
	  -webkit-transform: translateX(-10px);
	  transform: translateX(-10px);
	}
  
	100% {
	  -webkit-transform: translateX(-100px);
	  transform: translateX(-100px);
	}
}
.animate-float-bob-x {
	-webkit-animation-name: float-bob-x;
			animation-name: float-bob-x;
	-webkit-animation-duration: 10s;
			animation-duration: 10s;
	-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
}
@-webkit-keyframes float-bob-x {
	0% {
		-webkit-transform: translateX(-20px);
				transform: translateX(-20px);
	}

	50% {
		-webkit-transform: translateX(-10px);
				transform: translateX(-10px);
	}

	100% {
		-webkit-transform: translateX(-20px);
				transform: translateX(-20px);
	}
}
@keyframes float-bob-x {
	0% {
		-webkit-transform: translateX(-20px);
				transform: translateX(-20px);
	}

	50% {
		-webkit-transform: translateX(-10px);
				transform: translateX(-10px);
	}

	100% {
		-webkit-transform: translateX(-20px);
				transform: translateX(-20px);
	}
}
.animate-float-bob-y {
	-webkit-animation-name: float-bob-y;
			animation-name: float-bob-y;
	-webkit-animation-duration: 5s;
			animation-duration: 5s;
	-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
}
@-webkit-keyframes float-bob-y {
	0% {
		-webkit-transform: translateY(-20px);
				transform: translateY(-20px);
	}

	50% {
		-webkit-transform: translateY(-10px);
				transform: translateY(-10px);
	}

	100% {
		-webkit-transform: translateY(-20px);
				transform: translateY(-20px);
	}
}
@keyframes float-bob-y {
	0% {
		-webkit-transform: translateY(-20px);
				transform: translateY(-20px);
	}

	50% {
		-webkit-transform: translateY(-10px);
				transform: translateY(-10px);
	}

	100% {
		-webkit-transform: translateY(-20px);
				transform: translateY(-20px);
	}
}
.animate-rotate-me{
	animation-name: rotateme; 
	animation-duration: 24s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	
	-webkit-animation-name: rotateme; 
	-webkit-animation-duration: 24s; 
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	
	animation-name: rotateme; 
	animation-duration: 24s; 
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	
	-ms-animation-name: rotateme; 
	-ms-animation-duration: 24s; 
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;
	
	-o-animation-name: rotateme; 
	-o-animation-duration: 24s; 
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: linear;
}
@-webkit-keyframes rotateme {
	from {
		-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
	}
	to { 
		-webkit-transform: rotate(360deg); 
				transform: rotate(360deg);
	}
}
@keyframes rotateme {
	from {
		-webkit-transform: rotate(0deg);
				transform: rotate(0deg);
	}
	to { 
		-webkit-transform: rotate(360deg); 
				transform: rotate(360deg);
	}
}

/* Preloader */
.preloader {
	position: fixed;
	background-color: #003669;
	background-position: center center;
	background-repeat: no-repeat;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999999999999;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	text-align: center;
}
.preloader-image {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: flipInY;
	animation-name: flipInY;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	background-blend-mode: multiply;
}
@-webkit-keyframes flipInY {
	0% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	60% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}
	80% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	100% {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

@keyframes flipInY {
	0% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	40% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	60% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}

	80% {
		-webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}
	100% {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

/* Scroll To Top */
.scroll-to-top {
	display: inline-block;
	width: 45px;
	height: 45px;
	background-color: #034380;
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 99;
	text-align: center;
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	transition: all 0.4s ease;
	display: none;
	border-radius: 50%;
	transition: all 0.4s ease;
	-webkit-animation-name: float-bob-y;
			animation-name: float-bob-y;
	-webkit-animation-duration: 2s;
			animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
			animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
			animation-timing-function: linear;
}
.scroll-to-top i {
	color: #ffffff;
	font-size: 18px;
	line-height: 45px;
}
.scroll-to-top:hover {
	background-color: #003669;
}
.scroll-to-top:hover i {
	color: #fff;
}
.scroll-to-top.bg-burning-orange {
	background-color: #F65911;
}
.scroll-to-top.bg-burning-orange:hover {
	background-color: #003669;
}
.scroll-to-top.bg-red {
	background-color: #E25C6A;
}
.scroll-to-top.bg-clear-blue {
	background-color: #034380;
}
.scroll-to-top.bg-red:hover,
.scroll-to-top.bg-clear-blue:hover {
	background-color: #003669;
}

/* List Style */
.section-list-style ul li {
	font-size: 18px;
	color: #6e727d;
	position: relative;
	z-index: 1;
	padding-left: 30px;
	margin-bottom: 10px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.section-list-style ul li:after {
	position: absolute;
	content: "\f00c";
	left: 0;
	top: 2px;
	font-family: "Font Awesome 5 Pro";
	font-size: 16px;
	color: #034380;
	font-weight: 800;
}
.section-list-style ul li:last-of-type {
	margin: 0;
}
.section-list-style ul li:hover {
	color: #034380;
}

/* List Style V2 */
.section-list-style.list-style-v2{
	margin-bottom: 46px;
}
.section-list-style.list-style-v2 ul li:after {
	background-color: #E25C6A;
	color: #fff;
	height: 25px;
	width: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%;
	font-size: 13px;
}
.section-list-style.list-style-v2 ul li {
	padding-left: 40px;
	color: #003669;
	margin-bottom: 13px;
}
.section-list-style.list-style-v2 ul li:hover {
	color: #003669;
}

/* List Style V3 */
.section-list-style.list-style-v3 ul li:after {
	content: "";
	font-family: inherit;
	height: 7px;
	width: 7px;
	background-color: #034380;
	border-radius: 50%;
	top: 15px;
	margin-top: -3.5px;
}
.section-list-style.list-style-v3 ul li:hover {
	color: #034380;
}
/* Header CSS */
.header-navigation {
	padding: 10px;
}
.header-navigation .brand-logo a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	gap: 10px;
	color: #003669;
}
.header-navigation .brand-logo.text-logo i{
	font-size: 35px;
}
.header-navigation .brand-logo.text-logo a span{
	font-size: 40px;
	font-family: 'Open Sans';
}
.header-navigation .brand-logo.text-logo a:hover {
	color: #003669;
}
.header-navigation .main-menu ul li {
	display: inline-block;
	position: relative;
}
.header-navigation .main-menu ul li>a {
	display: block;
	font-size: 16px;
	color: #003669;
	font-weight: 500;
	text-transform: capitalize;
	padding: 40px 18px;
	line-height: 1;
	font-family: 'CircularStdMedium';
}
.header-navigation .main-menu ul li>a.nav-link:hover, 
.header-navigation .main-menu ul li>a.nav-link.active{
	color: #034380;
}
.header-navigation .main-menu ul li .sub-menu {
	position: absolute;
	left: 0;
	top: 120%;
	width: 200px;
	background-color: #fff;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	z-index: 99;
	height: auto;
	text-align: left;
	-webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
	box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
}
.header-navigation .main-menu ul li .sub-menu li {
	display: block;
	margin: 0;
}
.header-navigation .main-menu ul li .sub-menu li:last-child>a {
	border-bottom: none;
}
.header-navigation .main-menu ul li .sub-menu li a {
	display: block;
	padding: 8px 15px;
	font-size: 16px;
	position: relative;
	-webkit-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	border-radius: 0;
	line-height: 2;
	margin: 0;
	border-bottom: 1px solid #ececec;
	color: #1b3255;
}
.header-navigation .main-menu ul li .sub-menu li a:hover {
	background-color: #034380;
	color: #fff;
	border-color: transparent;
}
.header-navigation .main-menu ul li .sub-menu li .sub-menu {
	left: 100%;
	top: 50%;
}
.header-navigation .main-menu ul li .sub-menu li:hover .sub-menu {
	top: 0%;
}
.header-navigation .main-menu ul li:hover.menu-item-has-children>a:after {
	color: #034380;
}
.header-navigation .main-menu ul li:hover>.sub-menu {
	opacity: 1;
	visibility: visible;
	top: 100%;
}
.header-navigation .main-menu ul li .dd-trigger {
	display: none;
}
.header-navigation.breakpoint-on .nav-menu {
	text-align: left;
	background-color: #fff;
	position: fixed;
	top: 0;
	left: -300px;
	z-index: 9999;
	width: 300px;
	height: 100%;
	-webkit-transition-duration: 500ms;
	-o-transition-duration: 500ms;
	transition-duration: 500ms;
	padding: 0;
	-webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	display: block;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-top: 70px;
}
.header-navigation.breakpoint-on .nav-menu.menu-on {
	left: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li:first-of-type {
	border-top: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li {
	display: block;
	margin: 0;
	border-bottom: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
	display: block;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	color: #003669;
	padding: 13px 20px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover,
.header-navigation.breakpoint-on .main-menu > ul > li > a:hover {
	color: #034380;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu {
	width: 100%;
	position: relative;
	top: 0;
	left: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: transparent;
	visibility: visible;
	opacity: 1;
	display: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a {
	color: #000000;
	padding: 0px 20px 0 30px;
	line-height: 45px !important;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li .sub-menu li a {
	padding-left: 45px;
}
.header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active {
	color: #034380;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover {
	border-color: rgba(255, 255, 255, 0.5);
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	background-color: transparent;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
	display: block;
	position: absolute;
	right: 0;
	height: 45px;
	width: 45px;
	top: 0;
	border-left: 1px solid #dcdde0;
	z-index: 2;
	background: transparent;
	text-align: center;
	line-height: 45px;
	cursor: pointer;
	color: #000000;
	font-size: 20px;
}
.header-navigation.breakpoint-on .nav-menu .main-menu .sub-menu li:last-of-type {
	border: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu.menu-on {
	left: 0;
}
.header-navigation.breakpoint-on .nav-pushed-item {
	display: none;
}
.header-navigation.breakpoint-on .navbar-close, .header-navigation.breakpoint-on .navbar-toggler {
	display: block;
}
.header-navigation .navbar-toggler {
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: none;
}
.header-navigation .navbar-toggler span {
	position: relative;
	background-color: #000000;
	border-radius: 3px;
	display: block;
	height: 3px;
	margin-top: 5px;
	padding: 0;
	-webkit-transition-duration: 300ms;
	-o-transition-duration: 300ms;
	transition-duration: 300ms;
	width: 30px;
	cursor: pointer;
	display: block;
}
.header-navigation .navbar-toggler span:first-of-type {
	margin-top: -2px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(1) {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 9px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(2) {
	opacity: 0;
}
.header-navigation .navbar-toggler.active span:nth-of-type(3) {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: -7px;
}
.header-navigation .navbar-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 12;
	display: none;
	background: #034380;
	padding: 5px;
}
.header-navigation .navbar-close .cross-wrap {
	width: 34px;
	height: 34px;
	cursor: pointer;
	position: relative;
}
.header-navigation .navbar-close .cross-wrap span {
	position: absolute;
	display: block;
	width: 100%;
	height: 2px;
	border-radius: 6px;
	background: #fff;
}
.header-navigation .navbar-close .cross-wrap span.top {
	top: 17px;
	left: 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.header-navigation .navbar-close .cross-wrap span.bottom {
	bottom: 15px;
	left: 0;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.header-navigation .header-right ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}
.header-navigation .header-right li {
	display: inline-block;
}
.header-navigation .header-right li a.search-btn {
	padding-right: 30px;
	border-right: 2px solid #dcdde0;
}
.header-navigation .header-right li a.search-btn i {
	font-weight: 600;
}
.header-navigation .header-right li.get-started-wrapper {
	margin-left: 30px;
}
.header-navigation .site-branding-and-language-selection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/* Menu Active Overlay */
.header-navigation .menu-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #111;
	cursor: pointer;
	z-index: 999;
	-webkit-transition: all 0.5s ease-out 0s;
	-o-transition: all 0.5s ease-out 0s;
	transition: all 0.5s ease-out 0s;
	opacity: 0;
	visibility: hidden;
}
.header-navigation.breakpoint-on .menu-toggle.active .menu-overlay {
	opacity: 0.5;
	visibility: visible;
}

/* Header OffCanvas Menu */
.offcanvas-panel .menu-overlay{
	z-index: -1;
}
.offcanvas-panel.panel-on .menu-overlay {
	z-index: -1;
	opacity: 0.3;
	visibility: visible;
}
.header-navigation .nav-tools .icon {
	margin-left: 5px;
	width: 50px;
	height: 60px;
	text-align: center;
	line-height: 60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	border-radius: 50%;
}
.header-navigation .nav-tools .icon.off-menu span {
	width: 30px;
	height: 3px;
	background-color: #034380;
	display: block;
	margin: 3px 0;
	-webkit-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	margin-left: auto;
}
.offcanvas-panel {
	position: fixed;
	right: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
		-ms-flex-pack: end;
			justify-content: flex-end;
	-webkit-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	opacity: 0;
	visibility: hidden;
}
.offcanvas-panel.panel-on {
	visibility: visible;
	opacity: 1;
}
.offcanvas-panel.panel-on .offcanvas-panel-inner {
	visibility: visible;
	opacity: 1;
	margin-right: 0;
}
.offcanvas-panel .offcanvas-panel-inner {
	max-width: 400px;
	height: 100%;
	background-color: #fff;
	padding: 40px;
	position: relative;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-right: -400px;
	-webkit-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	opacity: 0;
	visibility: hidden;
}
.offcanvas-panel .offcanvas-panel-inner .panel-logo, 
.offcanvas-panel .offcanvas-panel-inner .offcanvas-panel, 
.offcanvas-panel .offcanvas-panel-inner .about-us-widget {
	margin-bottom: 40px;
}
.offcanvas-panel.panel-on .offcanvas-panel-inner .about-us-widget {
	-webkit-animation: slide-in .4s .1s backwards;
			animation: slide-in .4s .1s backwards;
}
.offcanvas-panel .panel-widget-title {
	font-size: 20px;
	margin-bottom: 20px;
	padding-bottom: 15px;
	position: relative;
	border-bottom: 1px solid #E8E9EC;
	font-weight: 600;
	color: #1b2336;
}
.offcanvas-panel .contact-us ul li {
	position: relative;
	padding-left: 60px;
	margin-bottom: 30px;
}
.offcanvas-panel .contact-us ul li:last-child {
	margin-bottom: 0px;
}
.offcanvas-panel .contact-us ul li i {
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
			transform: translateY(-50%);
	width: 40px;
	height: 40px;
	line-height: 40px;
	background-color: #1b0e60;
	border-radius: 50%;
	font-size: 14px;
	border-radius: 50%;
	text-align: center;
	color: #fff;
}
.offcanvas-panel .panel-close {
	position: absolute;
	top: 20px;
	right: 30px;
	font-size: 25px;
	line-height: 1;
	color: #000000;
	z-index: 2;
}
.offcanvas-panel .panel-close:hover span{
	background: #E25C6A;
}
.offcanvas-panel .panel-close span {
	width: 25px;
	height: 3px;
	background: #1E2845;
	display: block;
	margin-bottom: 5px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.offcanvas-panel.panel-on .panel-close span:nth-child(1) {
	-webkit-transform: rotate(45deg) translate(10px, 10px);
		-ms-transform: rotate(45deg) translate(10px, 10px);
			transform: rotate(45deg) translate(10px, 10px);
}
.offcanvas-panel.panel-on .panel-close span:nth-child(3) {
	-webkit-transform: rotate(-45deg) translate(2px, -1px);
		-ms-transform: rotate(-45deg) translate(2px, -1px);
			transform: rotate(-45deg) translate(2px, -1px);
}
.offcanvas-panel.panel-on .panel-close span:nth-child(2) {
	width: 0;
}
.offcanvas-panel .contact-us ul, .offcanvas-panel .contact-us ul li:last-of-type {
	display: block;
}
.offcanvas-panel .contact-us ul li {
	position: relative;
	padding-left: 60px;
	margin-bottom: 25px;
}
.offcanvas-panel .contact-us ul li span{
	display: block;
}
.offcanvas-panel.panel-on .contact-us ul li a:hover{
	color: #1b2336;
}
.offcanvas-panel.panel-on .contact-us ul li {
	-webkit-animation: slide-in .4s .2s backwards;
			animation: slide-in .4s .2s backwards;
}
.offcanvas-panel.panel-on .contact-us ul li:nth-of-type(2) {
	-webkit-animation-delay: .3s;
			animation-delay: .3s;
}
.offcanvas-panel.panel-on .contact-us ul li:nth-of-type(3) {
	-webkit-animation-delay: .4s;
			animation-delay: .4s;
}
@-webkit-keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateX(80px);
				transform: translateX(80px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
				transform: translateX(0);
	}
}
@keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateX(80px);
				transform: translateX(80px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
				transform: translateX(0);
	}
}
.offcanvas-panel .contact-us ul li i {
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
			transform: translateY(-50%);
	width: 40px;
	height: 40px;
	line-height: 40px;
	background-color: #4A83FB;
	border-radius: 50%;
	font-size: 14px;
	border-radius: 50%;
	text-align: center;
	color: #fff;
}
.offcanvas-panel .contact-us ul li:nth-child(1) i{
	background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
}
.offcanvas-panel .contact-us ul li:nth-child(2) i {
	background-image: linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
}
.offcanvas-panel .contact-us ul li:nth-child(3) i{
	background-image: linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
}

.offcanvas-panel  .about-us-widget p {
	color: #6e727d;
}
.offcanvas-panel.panel-on .contact-us ul li {
	color: #6e727d;
}

/* Sticky Menu */
.header-area.header-v1 .header-navigation {
	position: relative;
	z-index: 2;
}
.header-area .header-navigation.sticky {
	background-color: #1C1E22;
}
.header-area .header-navigation.sticky {
	background-color: #fff;
	-webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
	box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
}
.header-area .header-navigation.sticky {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	-webkit-animation: sticky 1.2s;
	animation: sticky 1.2s;
}
@-webkit-keyframes sticky {
	0% {
		top: -200px;
	}
	100% {
		top: 0;
	}
}
@keyframes sticky {
	0% {
		top: -200px;
	}
	100% {
		top: 0;
	}
}

/* Language Selection Dropdown */
.header-navigation .site-branding-and-language-selection .language-selection {
	width: 50%;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select {
	margin-left: 50px;
	background-color: #F4F7FA;
	border: 0;
	width: 160px;
	height: 50px;
	padding: 0;
	font-size: 18px;
	color: #003669;
	font-family: 'CircularStdMedium';
	font-weight: 500;
	float: none;
	border-radius: 50px;
	z-index: 1;
}
.nice-select:after,
.header-navigation .site-branding-and-language-selection .language-selection .nice-select:after {
	border: 0;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	height: auto;
	width: auto;
	top: 10px;
	right: 32px;
	content: "\f107";
	font-family: "Font Awesome 5 Pro";
	font-size: 15px;
	font-weight: 800;
}
.nice-select:after, .header-navigation .site-branding-and-language-selection .language-selection .nice-select.open:after {
	-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
			transform: rotate(180deg);
	top: -2px;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select span {
	width: 100%;
	text-align: center;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select ul.list {
	width: 100%;
	border-radius: 0;
	-webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
			box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
	margin: 0;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select ul.list li {
	padding: 3px 20px;
	font-size: 16px;
	color: #1b3255;
	border-bottom: 1px solid #ececec;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select ul.list li.selected {
	color: #034380;
	font-weight: 500;
	background: transparent;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select span.current {
	position: relative;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select span.current:after {
	content: "\f0ac";
	font-family: "Font Awesome 5 Pro";
	position: absolute;
	left: 25px;
	font-size: 18px;
	color: #034380;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select ul.list li:hover {
	background-color: #f4f7fa;
	border-color: transparent;
}
.header-navigation .site-branding-and-language-selection .language-selection .nice-select ul.list li {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

/* Header Navigation V2 */
.header-area.header-v2,
.header-area.header-v4{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
}
.header-area.header-v2 .header-navigation .main-menu ul li>a,
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a:hover  {
	color: #fff;
}
.header-area.header-v2 .header-navigation.sticky .main-menu ul li>a,
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a,
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu ul li>a  {
	color: #003669;
}
.header-area.header-v2 .header-navigation .navbar-toggler span{
	background-color: #fff;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a:hover {
	background-color: #F65911;
}
.header-area.header-v2 .header-navigation .brand-logo a{
	color: #fff;
}
.header-area.header-v2 .header-navigation .brand-logo .logo-v2 {
	display: none;
}
.header-area.header-v2 .header-navigation.sticky .brand-logo .logo-v2 {
	display: block;
}
.header-area.header-v2 .header-navigation.sticky .brand-logo .logo-v1 {
	display: none;
}
.header-area.header-v2 .header-navigation .brand-logo .logo-v1 {
	display: block;
}
.header-area.header-v2 .header-navigation.sticky .brand-logo a {
	color: #1b3255;
}
.header-area.header-v2 .header-navigation.sticky .navbar-toggler span{
	background-color: #1b3255;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a:after {
	background-color: #fff;
	top: auto;
	bottom: 15px;
}
.header-area.header-v2 .header-navigation.sticky .main-menu > ul > li > a.active:after {
	bottom: auto;
	top: 0;
	background: #F65911;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a:after{
	opacity: 0;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a.active:after{
	width: 100%;
	opacity: 1;
}
.header-area.header-v2 .header-navigation .main-menu ul li a.active:hover:after{
	width: 0;
}
.header-area.header-v2 .header-navigation.sticky .main-menu > ul > li > a:hover:after{
	width: 100%;
}
.header-area.header-v2 .header-navigation .main-menu ul li:hover>.sub-menu{
	top: 78%;
}
.header-area.header-v2 .header-navigation.sticky .main-menu ul li:hover>.sub-menu {
	top: 100%;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v2 .header-navigation.sticky .main-menu ul li>a.nav-link.active 
{
	color: #F65911;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active:hover {
	color: #fff;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a:hover{
	color: #F65911;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li.menu-item-has-children > a {
	padding-right: 15px;
	padding-left: 0;
}

.header-area.header-v2 .header-navigation .main-menu ul> li > a {
	padding-left: 0;
	padding-right: 0;
}

.header-area.header-v2 .header-navigation .main-menu > ul > li {
	margin-right: 30px;
}
.header-area.header-v2 .header-navigation .main-menu ul li.menu-item-has-children > a:before {
	right: 0;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li:last-of-type {
	margin-right: 0;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a {
	padding: 8px 15px;
}

.header-area.header-v2 .header-navigation .navbar-close{
	background-color: #0040E5;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
	padding: 13px 20px;
}
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu > ul > li {
	margin-right: 0;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li ul.sub-menu a {
	padding: 0 20px 0 30px;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li .sub-menu li a {
	padding-left: 45px;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	background-color: transparent;
}
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu > ul > li > a:hover,
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	color: #0040E5;
}
.header-area.header-v2 .header-navigation .header-right li a.search-btn {
	color: #fff;
}
.header-area.header-v2 .header-navigation .nav-tools .icon.off-menu span {
	background-color: #fff;
}
.header-area.header-v2 .header-navigation.sticky .header-right li a.search-btn {
	color: #003669;
}
.header-area.header-v2 .header-navigation.sticky .nav-tools .icon.off-menu span {
	background-color: #0040E5;
}
/* Header Navigation V3 (Home V5) */
.header-area.header-v3{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
}
.header-area.header-v3 .header-navigation .main-menu ul li>a,
.header-area.header-v3 .header-navigation .main-menu ul li .sub-menu li a:hover  {
	color: #fff;
}
.header-area.header-v3 .header-navigation.sticky .main-menu ul li>a,
.header-area.header-v3 .header-navigation .main-menu ul li .sub-menu li a,
.header-area.header-v3 .header-navigation.breakpoint-on .main-menu ul li>a {
	color: #003669;
}
.header-area.header-v3 .header-navigation.sticky .main-menu > ul > li > a:hover {
	color: #034380;
}
.header-area.header-v3 .header-navigation .navbar-toggler span{
	background-color: #fff;
}
.header-area.header-v3 .header-navigation .main-menu ul li .sub-menu li a:hover {
	background-color: #034380;
}
.header-area.header-v3 .header-navigation .brand-logo a{
	color: #fff;
}
.header-area.header-v3 .header-navigation .brand-logo .logo-v2 {
	display: none;
}
.header-area.header-v3 .header-navigation.sticky .brand-logo .logo-v2 {
	display: block;
}
.header-area.header-v3 .header-navigation.sticky .brand-logo .logo-v1 {
	display: none;
}
.header-area.header-v3 .header-navigation .brand-logo .logo-v1 {
	display: block;
}
.header-area.header-v3 .header-navigation.sticky .brand-logo a {
	color: #1b3255;
}
.header-area.header-v3 .header-navigation.sticky .navbar-toggler span{
	background-color: #1b3255;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li > a:after {
	background-color: #fff;
	top: auto;
	bottom: 15px;
}
.header-area.header-v3 .header-navigation.sticky .main-menu > ul > li > a.active:after {
	bottom: auto;
	top: 0;
	background: #034380;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li > a:after{
	opacity: 0;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li > a.active:after{
	width: 100%;
	opacity: 1;
}
.header-area.header-v2 .header-navigation .main-menu ul li a.active:hover:after {
	width: 0;
}
.header-area.header-v2 .header-navigation.sticky .main-menu > ul > li > a:hover:after {
	width: 100%;
}
.header-area.header-v3 .header-navigation .main-menu ul li:hover>.sub-menu{
	top: 78%;
}
.header-area.header-v3 .header-navigation.sticky .main-menu ul li:hover>.sub-menu {
	top: 100%;
}
.header-area.header-v3 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v3 .header-navigation.sticky .main-menu ul li>a.nav-link.active 
{
	color: #034380;
}
.header-area.header-v3 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active:hover {
	color: #fff;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li > a:hover{
	color: #fff;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li.menu-item-has-children > a {
	padding-right: 15px;
	padding-left: 0;
}
.header-area.header-v3 .header-navigation .main-menu ul> li > a {
	padding-left: 0;
	padding-right: 0;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li {
	margin-right: 30px;
}
.header-area.header-v3 .header-navigation .main-menu ul li.menu-item-has-children > a:before {
	right: 0;
}
.header-area.header-v3 .header-navigation .main-menu > ul > li:last-of-type {
	margin-right: 0;
}
.header-area.header-v3 .header-navigation .main-menu ul li .sub-menu li a {
	padding: 8px 15px;
}
.header-area.header-v3 .header-navigation .navbar-close{
	background-color: #0040E5;
}
.header-area.header-v3 .header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
	padding: 13px 20px;
}
.header-area.header-v3 .header-navigation.breakpoint-on .main-menu > ul > li {
	margin-right: 0;
}
.header-area.header-v3 .header-navigation.breakpoint-on .nav-menu .main-menu ul li ul.sub-menu a {
	padding: 0 20px 0 30px;
}
.header-area.header-v3 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li .sub-menu li a {
	padding-left: 45px;
}
.header-area.header-v3 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	background-color: transparent;
}
.header-area.header-v3 .header-navigation.breakpoint-on .main-menu > ul > li > a:hover,
.header-area.header-v3 .header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-area.header-v3 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v3 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	color: #0040E5;
}
.header-area.header-v3 .header-navigation .header-right li a.search-btn {
	color: #fff;
	padding-right: 0;
	border: 0;
	padding: 0;
}
.header-area.header-v3 .header-navigation .header-right li.nav-tools .icon.off-menu span {
	background-color: #fff;
}
.header-area.header-v3 .header-navigation .nav-tools .icon {
	margin-left: 0;
}
.header-area.header-v3 .header-navigation .nav-tools .icon span:nth-child(2) {
	width: 50px;
}
.header-area.header-v3 .header-navigation .nav-tools .icon span:nth-child(1) {
	width: 42px;
	margin-left: auto;
}
.header-area.header-v3 .header-navigation .nav-tools .icon span:nth-child(3) {
	width: 40px;
	margin-right: auto;
}
.header-area.header-v3 .header-navigation .nav-tools .icon span {
	margin: 5px 0;
}
.header-area.header-v3 .header-navigation .header-right li {
	margin-left: 30px;
}
.header-area.header-v3 .header-navigation .header-right li:first-child {
	margin-left: 0;
}
.header-area.header-v3 .header-navigation .header-right li:last-child {
	display: none;
}
.header-area.header-v3 .header-navigation .offcanvas-panel .offcanvas-panel-inner ul li {
	display: inline-block;
}
.header-area.header-v3 .header-navigation.sticky .header-right li.nav-tools .icon.off-menu span {
	background-color: #1b202f;
}
.header-area.header-v3 .header-navigation .offcanvas-panel .offcanvas-panel-inner ul li {
	margin-left: 0;
}
.header-area.header-v3 .header-navigation.breakpoint-on .navbar-toggler {
	margin-left: 0px;
}
/* Home V3 Language Selection */
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select {
	background-color: transparent;
	border: 0;
	border: 0;
	width: 100px;
	height: 50px;
	padding: 0;
	font-size: 18px;
	color: #fff;
	font-family: 'CircularStdMedium';
	font-weight: 500;
	float: none;
	border-radius: 50px;
	z-index: 1;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select .current:after {
	content: "\f0ac";
	font-family: "Font Awesome 5 Pro";
	position: absolute;
	left: -5px;
	font-size: 18px;
	color: #fff;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select .current {
	position: relative;
	width: 100%;
	text-align: center;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select:after {
	border: 0;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	height: auto;
	width: auto;
	top: 10px;
	right: 0;
	content: "\f107";
	font-family: "Font Awesome 5 Pro";
	font-size: 15px;
	font-weight: 800;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select.open:after {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	top: -2px;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select ul.list {
	width: 125px;
	border-radius: 0;
	-webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
	box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
	margin: 0;
	display: block;
	height: auto;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select ul.list li.selected {
	color: #034380;
	font-weight: 500;
	background: #fff;
}
.header-area.header-v3 .header-navigation .header-right li.language-selection .nice-select ul.list li {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	padding: 3px 20px;
	font-size: 16px;
	color: #1b202f;
	border-bottom: 1px solid #ececec;
	margin: 0;
	display: block;
}
.header-area.header-v3 .header-navigation.sticky .header-right li.language-selection .nice-select, 
.header-area.header-v3 .header-navigation.sticky  .header-right li.language-selection .nice-select .current:after,
.header-area.header-v3 .header-navigation.sticky .header-right li a.search-btn {
	color: #003669;
}

/* Header Navigation V4 */
.header-area.header-v4 .header-navigation .site-branding-and-language-selection .language-selection .nice-select {
	background-color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(212, 212, 212, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(212, 212, 212, 0.36);
}
.header-area.header-v4 .header-navigation .site-branding-and-language-selection .language-selection .nice-select span.current:after, 
.header-area.header-v4 .header-navigation .site-branding-and-language-selection .language-selection .nice-select ul.list li.selected, 
.header-area.header-v4 .header-navigation .main-menu ul li>a.nav-link:hover, 
.header-area.header-v4 .header-navigation .main-menu ul li>a.nav-link.active,
.header-area.header-v4 .header-navigation .main-menu ul li:hover>a,
.header-area.header-v4 .header-navigation.breakpoint-on .main-menu > ul > li > a:hover, 
.header-area.header-v4 .header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-area.header-v4 .header-navigation.breakpoint-on .main-menu ul li a.nav-link.active, 
.header-area.header-v4 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v4 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	color: #e25c6a;
}
.header-area.header-v4 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	background-color: transparent;
}
.header-area.header-v4 .header-navigation .main-menu > ul > li > a:after, 
.header-area.header-v4 .header-navigation .main-menu ul li .sub-menu li a:hover,
.header-area.header-v4 .header-navigation .navbar-close {
	background-color: #e25c6a;
}
.header-area.header-v4 .header-navigation .main-menu ul li .sub-menu li a:hover{
	color: #fff;
}

/* Header Navigation V5 */
.header-area.header-v5 .header-navigation {
	padding: 10px 0;
	border-bottom: 2px solid rgb(220 221 224 / 50%);
	background-color: #F4F7FA;
	-webkit-box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
			box-shadow: 0 8px 20px rgb(55 125 255 / 8%);
}

/* Header Navigation V2 */
.header-area.header-v2,
.header-area.header-v4{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
}
.header-area.header-v2 .header-navigation .main-menu ul li>a,
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a:hover  {
	color: #fff;
}
.header-area.header-v2 .header-navigation.sticky .main-menu ul li>a,
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a,
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu ul li>a  {
	color: #003669;
}
.header-area.header-v2 .header-navigation .navbar-toggler span{
	background-color: #fff;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a:hover {
	background-color: #F65911;
}
.header-area.header-v2 .header-navigation .brand-logo a{
	color: #fff;
}
.header-area.header-v2 .header-navigation .brand-logo .logo-v2 {
	display: none;
}
.header-area.header-v2 .header-navigation.sticky .brand-logo .logo-v2 {
	display: block;
}
.header-area.header-v2 .header-navigation.sticky .brand-logo .logo-v1 {
	display: none;
}
.header-area.header-v2 .header-navigation .brand-logo .logo-v1 {
	display: block;
}
.header-area.header-v2 .header-navigation.sticky .brand-logo a {
	color: #1b3255;
}
.header-area.header-v2 .header-navigation.sticky .navbar-toggler span{
	background-color: #1b3255;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a:after {
	background-color: #fff;
	top: auto;
	bottom: 15px;
}
.header-area.header-v2 .header-navigation.sticky .main-menu > ul > li > a.active:after {
	bottom: auto;
	top: 0;
	background: #F65911;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a:after{
	opacity: 0;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a.active:after{
	width: 100%;
	opacity: 1;
}
.header-area.header-v2 .header-navigation .main-menu ul li a.active:hover:after{
	width: 0;
}
.header-area.header-v2 .header-navigation.sticky .main-menu > ul > li > a:hover:after{
	width: 100%;
}
.header-area.header-v2 .header-navigation .main-menu ul li:hover>.sub-menu{
	top: 78%;
}
.header-area.header-v2 .header-navigation.sticky .main-menu ul li:hover>.sub-menu {
	top: 100%;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v2 .header-navigation.sticky .main-menu ul li>a.nav-link.active 
{
	color: #F65911;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active:hover {
	color: #fff;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li > a:hover{
	color: #F65911;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li.menu-item-has-children > a {
	padding-right: 15px;
	padding-left: 0;
}

.header-area.header-v2 .header-navigation .main-menu ul> li > a {
	padding-left: 0;
	padding-right: 0;
}

.header-area.header-v2 .header-navigation .main-menu > ul > li {
	margin-right: 30px;
}
.header-area.header-v2 .header-navigation .main-menu ul li.menu-item-has-children > a:before {
	right: 0;
}
.header-area.header-v2 .header-navigation .main-menu > ul > li:last-of-type {
	margin-right: 0;
}
.header-area.header-v2 .header-navigation .main-menu ul li .sub-menu li a {
	padding: 8px 15px;
}

.header-area.header-v2 .header-navigation .navbar-close{
	background-color: #0040E5;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
	padding: 13px 20px;
}
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu > ul > li {
	margin-right: 0;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li ul.sub-menu a {
	padding: 0 20px 0 30px;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li .sub-menu li a {
	padding-left: 45px;
}
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	background-color: transparent;
}
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu > ul > li > a:hover,
.header-area.header-v2 .header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v2 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	color: #0040E5;
}
.header-area.header-v2 .header-navigation .header-right li a.search-btn {
	color: #fff;
}
.header-area.header-v2 .header-navigation .nav-tools .icon.off-menu span {
	background-color: #fff;
}
.header-area.header-v2 .header-navigation.sticky .header-right li a.search-btn {
	color: #003669;
}
.header-area.header-v2 .header-navigation.sticky .nav-tools .icon.off-menu span {
	background-color: #0040E5;
}
/* Header Navigation V6 (Home V6) */
.header-area.header-v6{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
}
.header-area.header-v6 .header-navigation .main-menu ul li>a,
.header-area.header-v6 .header-navigation .main-menu ul li .sub-menu li a:hover  {
	color: #fff;
}
.header-area.header-v6 .header-navigation.sticky .main-menu ul li>a,
.header-area.header-v6 .header-navigation .main-menu ul li .sub-menu li a,
.header-area.header-v6 .header-navigation.breakpoint-on .main-menu ul li>a {
	color: #003669;
}
.header-area.header-v6 .header-navigation.sticky .main-menu > ul > li > a:hover {
	color: #034380;
}
.header-area.header-v6 .header-navigation .navbar-toggler span{
	background-color: #034380;
}
.header-area.header-v6 .header-navigation .main-menu ul li .sub-menu li a:hover {
	background-color: #034380;
}
.header-area.header-v6 .header-navigation .brand-logo a{
	color: #fff;
}
.header-area.header-v6 .header-navigation .brand-logo .logo-v2 {
	display: none;
}
.header-area.header-v6 .header-navigation.sticky .brand-logo .logo-v2 {
	display: block;
}
.header-area.header-v6 .header-navigation.sticky .brand-logo .logo-v1 {
	display: none;
}
.header-area.header-v6 .header-navigation .brand-logo .logo-v1 {
	display: block;
}
.header-area.header-v6 .header-navigation.sticky .brand-logo a {
	color: #1b3255;
}
.header-area.header-v6 .header-navigation.sticky .navbar-toggler span{
	background-color: #1b3255;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li > a:after {
	background-color: #fff;
	top: auto;
	bottom: 15px;
}
.header-area.header-v6 .header-navigation.sticky .main-menu > ul > li > a.active:after {
	bottom: auto;
	top: 0;
	background: #034380;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li > a:after{
	opacity: 0;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li > a.active:after{
	width: 100%;
	opacity: 1;
}
.header-area.header-v6 .header-navigation .main-menu ul li a.active:hover:after {
	width: 0;
}
.header-area.header-v6 .header-navigation .main-menu ul li a.active:after {
	background-color: #034380;
}
.header-area.header-v6 .header-navigation.sticky .main-menu > ul > li > a:hover:after {
	width: 100%;
}
.header-area.header-v6 .header-navigation .main-menu ul li:hover>.sub-menu{
	top: 78%;
}
.header-area.header-v6 .header-navigation.sticky .main-menu ul li:hover>.sub-menu {
	top: 100%;
}
.header-area.header-v6 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v6 .header-navigation.sticky .main-menu ul li>a.nav-link.active 
{
	color: #034380;
}
.header-area.header-v6 .header-navigation .main-menu ul li .sub-menu li a.nav-link.active:hover {
	color: #fff;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li > a:hover{
	color: #fff;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li.menu-item-has-children > a {
	padding-right: 15px;
	padding-left: 0;
}
.header-area.header-v6 .header-navigation .main-menu ul> li > a {
	padding-left: 0;
	padding-right: 0;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li {
	margin-right: 30px;
}
.header-area.header-v6 .header-navigation .main-menu ul li.menu-item-has-children > a:before {
	right: 0;
}
.header-area.header-v6 .header-navigation .main-menu > ul > li:last-of-type {
	margin-right: 0;
}
.header-area.header-v6 .header-navigation .main-menu ul li .sub-menu li a {
	padding: 8px 15px;
}
.header-area.header-v6 .header-navigation .navbar-close{
	background-color: #034380;
}
.header-area.header-v6 .header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
	padding: 13px 20px;
}
.header-area.header-v6 .header-navigation.breakpoint-on .main-menu > ul > li {
	margin-right: 0;
}
.header-area.header-v6 .header-navigation.breakpoint-on .nav-menu .main-menu ul li ul.sub-menu a {
	padding: 0 20px 0 30px;
}
.header-area.header-v6 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li .sub-menu li a {
	padding-left: 45px;
}
.header-area.header-v6 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	background-color: transparent;
}
.header-area.header-v6 .header-navigation.breakpoint-on .main-menu > ul > li > a:hover,
.header-area.header-v6 .header-navigation.breakpoint-on .main-menu ul li a.nav-link.active,
.header-area.header-v6 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a.nav-link.active,
.header-area.header-v6 .header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu li a:hover{
	color: #034380;
}
.header-area.header-v6 .header-navigation .header-right li a.search-btn {
	color: #fff;
	padding-right: 0;
	border: 0;
	padding: 0;
}
.header-area.header-v6 .header-navigation .header-right li.nav-tools .icon.off-menu span {
	background-color: #012e59;
}
.header-area.header-v6 .header-navigation .nav-tools .icon {
	margin-left: 0;
}
.header-area.header-v6 .header-navigation .nav-tools .icon span:nth-child(2) {
	width: 40px;
	border-radius: 50px;
	height: 3px;
}
.header-area.header-v6 .header-navigation .nav-tools .icon span:nth-child(1) {
	width: 42px;
	margin-left: -5px;
	border-radius: 50px;
	height: 3px;
}
.header-area.header-v6 .header-navigation .nav-tools .icon span:nth-child(3) {
	width: 42px;
	margin-left: -5px;
	border-radius: 50px;
	height: 3px;
}
.header-area.header-v6 .header-navigation .nav-tools .icon span {
	margin: 5px 0;
}
.header-area.header-v6 .header-navigation .header-right li {
	margin-left: 30px;
}
.header-area.header-v6 .header-navigation .header-right li:first-child {
	margin-left: 0;
}
.header-area.header-v6 .header-navigation .header-right li:last-child {
	display: none;
}
.header-area.header-v6 .header-navigation .offcanvas-panel .offcanvas-panel-inner ul li {
	display: inline-block;
}
.header-area.header-v6 .header-navigation.sticky .header-right li.nav-tools .icon.off-menu span {
	background-color: #1b202f;
}
.header-area.header-v6 .header-navigation .offcanvas-panel .offcanvas-panel-inner ul li {
	margin-left: 0;
}
.header-area.header-v6 .header-navigation.breakpoint-on .navbar-toggler {
	margin-left: 0px;
}
.header-area.header-v3 .header-navigation .get-started-wrapper .filled-btn,
.header-area.header-v6 .header-navigation .filled-btn.bg-clear-blue {
	box-shadow: none;
}
.header-area.header-v2 .header-navigation .get-started-wrapper .filled-btn:hover, 
.header-area.header-v2 .header-navigation .get-started-wrapper .filled-btn:after,
.header-area.header-v3 .header-navigation .get-started-wrapper .filled-btn:hover, 
.header-area.header-v3 .header-navigation .get-started-wrapper .filled-btn:after,
.header-area.header-v6 .header-navigation .filled-btn.bg-clear-blue:hover, 
.header-area.header-v6 .header-navigation .filled-btn.bg-clear-blue:after {
	background-color: #fff;
}
.header-area.header-v2 .header-navigation .get-started-wrapper .filled-btn:hover,
.header-area.header-v2 .header-navigation.sticky .get-started-wrapper .filled-btn:hover,
.header-area.header-v3 .header-navigation .get-started-wrapper .filled-btn:hover,
.header-area.header-v3 .header-navigation.sticky .get-started-wrapper .filled-btn:hover,
.header-area.header-v6 .header-navigation .filled-btn.bg-clear-blue:hover,
.header-area.header-v6 .header-navigation.sticky .filled-btn.bg-clear-blue:hover {
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
	color: #003669;
}
.header-area.header-v2 .header-navigation.sticky .get-started-wrapper .filled-btn:after,
.header-area.header-v3 .header-navigation.sticky .get-started-wrapper .filled-btn:after,
.header-area.header-v6 .header-navigation.sticky .filled-btn.bg-clear-blue:after {
	background-color: #003669;
}
.header-area.header-v2 .header-navigation.sticky .get-started-wrapper .filled-btn:hover,
.header-area.header-v3 .header-navigation.sticky .get-started-wrapper .filled-btn:hover,
.header-area.header-v6 .header-navigation.sticky .filled-btn.bg-clear-blue:hover {
	color: #fff;
}

/* Breadcrumb Area */
.breadcrumb-area {
	background-color: #F4F7FA;
	overflow: hidden;
	padding: 148px 0 153px;
}
.breadcrumb-area .section-internal{
	position: relative;
	z-index: 1;
}
.breadcrumb-area .breadcrumb-content .page-title h1 {
	font-size: 85px;
	line-height: 95px;
}
.breadcrumb-area .breadcrumb-content .page-title {
	margin-bottom: 15px;
}
.breadcrumb-area .breadcrumb-content  .page-breadcrumb .breadcrumb {
	background-color: transparent;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	margin: 0;
	padding: 0;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
}
.breadcrumb-area .breadcrumb-content .page-breadcrumb ul li {
	color: #034380;
	font-size: 24px;
	font-family: 'CircularStdMedium';
	font-weight: 500;
}
.breadcrumb-area .breadcrumb-content .page-breadcrumb ul li a:hover{
	color: #034380;
}
.breadcrumb-area .breadcrumb-content .page-breadcrumb ul li:after {
	display: inline-block;
	content: "\f105";
	padding-left: 12px;
	padding-right: 12px;
	font-family: "Font Awesome 5 Pro";
	font-size: 24px;
	color: #003669;
}
.breadcrumb-area .breadcrumb-content .page-breadcrumb ul li:last-child:after {
	display: none;
}
.breadcrumb-area .breadcrumb-content .page-breadcrumb ul li.active {
	color: #003669;
}

/* Particle Effect */
.section-particle-effect {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.section-particle-effect .particle-1 {
	position: absolute;
	top: -40px;
	left: 0;
}
.section-particle-effect .particle-2 {
	position: absolute;
	left: 70px;
	top: 20px;
}
.section-particle-effect .particle-3 {
	position: absolute;
	right: 20px;
	top: -60px;
}
.section-particle-effect .particle-4 {
	position: absolute;
	top: 28%;
	left: 100px;
}
.section-particle-effect .particle-5 {
	top: 30%;
	right: 100px;
	position: absolute;
}
.section-particle-effect .particle-6 {
	position: absolute;
	position: absolute;
	bottom: 44px;
	right: -44px;
	max-width: 180px;
	max-width: 180px;
}

/* Particle Effect V2 */
.section-particle-effect.section-particle-effect-v2 .particle-1, 
.section-particle-effect.section-particle-effect-v2 .particle-2 {
	top: 15px;
	left: -190px;
}
.section-particle-effect.section-particle-effect-v2 .particle-2 {
	top: 70px;
	left: -125px;
}
.section-particle-effect.section-particle-effect-v2 .particle-3 {
	top: 50px;
	right: -75px;
}
.section-particle-effect.section-particle-effect-v2 .particle-5 {
	top: auto;
	bottom: 100px;
	right: -80px;
}
.section-particle-effect.section-particle-effect-v2 .particle-7 {
	position: absolute;
	top: auto;
	bottom: 0;
	left: -250px;
}
.section-particle-effect.section-particle-effect-v2 .particle-4 {
	left: -110px;
	top: 50%;
	margin-top: -15px;
}

/* Particle Effect V3 */
.section-particle-effect.section-particle-effect-v3 .particle-1 {
	top: -25px;
	left: -88px;
}
.section-particle-effect.section-particle-effect-v3 .particle-2 {
	left: -25px;
	top: 35px;
}
.section-particle-effect.section-particle-effect-v3 .particle-3 {
	right: 142px;
	top: -65px;
}
.section-particle-effect.section-particle-effect-v3 .particle-4 {
	bottom: -60px;
	top: auto;
	left: -10px;
}
.section-particle-effect.section-particle-effect-v3 .particle-5 {
	top: auto;
	bottom: 0;
	right: -90px;
}

/* Section Particle Effect V4 */
.section-particle-effect.section-particle-effect-v4 .particle-1 {
	left: -170px;
	top: -50px;
}
.section-particle-effect.section-particle-effect-v4 .particle-2 {
	left: 35px;
	top: 0;
}
.section-particle-effect.section-particle-effect-v4 .particle-3 {
	left: -135px;
	bottom: 15px;
	top: auto;
}
.section-particle-effect.section-particle-effect-v4 .particle-4 {
	bottom: -45px;
	top: auto;
	left: 125px;
}

/* 02. App Feature Area */
.app-features-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 30px;
}

/* Single Service Box */
.single-service-box {
	background: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.4);
	box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.4);
	padding: 50px 50px;
	text-align: center;
	position: relative;
	margin-bottom: 30px;
}
.single-service-box .service-thumbnail {
	margin-bottom: 28px;
}
.single-service-box .service-box-title {
	margin-bottom: 24px;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
}
.single-service-box:after {
	position: absolute;
	content: "";
	height: 3px;
	width: 0;
	top: 0;
	left: 0;
	background: #034380;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-service-box:hover:after {
	width: 100%;
}
.single-service-box.active:after {
	width: 100%;
}
.single-service-box.active .service-box-btn {
	margin-bottom: 9px;
}
.single-service-box .service-box-btn a{
	cursor: pointer;
	height: 55px;
	width: 55px;
	background: #fff;
	border-radius: 50px;
	line-height: 50px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	position: relative;
	z-index: 1;
	display: inline-block;
	color: #034380;
	border: 2px solid #ECEBFD;
}
.single-service-box .service-box-btn a:hover {
	border-color: #034380;
}
.single-service-box:hover .service-box-btn a {
	border-color: #034380;
}

/* Single Service Box V2 */
.single-service-box.single-service-box-v2 {
	border-radius: 7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	z-index: 1;
	height: 100%;
	padding: 43px 45px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	margin-bottom: 30px;
}
.single-service-box.single-service-box-v2:hover,
.single-service-box.active.single-service-box-v2  {
	-webkit-box-shadow: 0px 10px 60px 0px rgba(184, 179, 252, 0.6);
			box-shadow: 0px 10px 60px 0px rgba(184, 179, 252, 0.6);
	-webkit-transform: translate(0px, -10px);
		-ms-transform: translate(0px, -10px);
			transform: translate(0px, -10px);
}
.single-service-box.single-service-box-v2:after {
	height: 5px;
	border-radius: 7px 7px 0 0;
}
.single-service-box.single-service-box-v2 .service-box-title {
	font-size: 22px;
}
.single-service-box.single-service-box-v2 .service-thumbnail {
	background-color: #F2F5FE;
	height: 180px;
	width: 180px;
	margin: 0 auto;
	line-height: 180px;
	border-radius: 50%;
	margin-bottom: 30px;
}
.single-service-box.single-service-box-v2 .service-thumbnail {
	margin: 0 auto;
	margin-bottom: 25px;
}

/* Single Service Box V3 (Home V6) */
.single-service-box.single-service-box-v3 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	text-align: left;
	border-radius: 15px;
	padding: 40px 50px;
	z-index: 2;
	position: relative;
}
.single-service-box.single-service-box-v3:hover {
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
}
.single-service-box.single-service-box-v3:before {
	content: "";
	height: 100%;
	width: 100%;
	background-color: #fff;
	left: 0;
	top: 0;
	z-index: -1;
	position: absolute;
	border-radius: 10px;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	-webkit-box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
	box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
	visibility: hidden;
	opacity: 0;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
}
.single-service-box.single-service-box-v3:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	z-index: -2;
	background-color: #3275FF;
	top: 0;
	left: 0;
	border-radius: 15px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	opacity: 0;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
}
.single-service-box.single-service-box-v3:hover:before {
	opacity: 1;
	right: -5px;
	left: auto;
	top: 5px;
	visibility: visible;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
.single-service-box.single-service-box-v3:hover:after {
	opacity: 1;
	top: -5px;
	left: -5px;
	visibility: visible;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
.single-service-box.single-service-box-v3 .service-thumbnail {
	min-width: 130px;
	max-width: 130px;
	margin-bottom: 0;
	margin-right: 34px;
}
.single-service-box.single-service-box-v3 .service-thumbnail img {
	border-radius: 50%;
}
.single-service-box.single-service-box-v3  .service-box-title {
	margin-bottom: 3px;
}
.single-service-box.single-service-box-v3 .service-box-content p {
	color: #7a7a7a;
	font-size: 17px;
	line-height: 30px;
	margin-bottom: 11px;
}
.single-service-box.single-service-box-v3 .service-box-content .service-box-btn a {
	width: auto;
	border: 0;
	border-radius: 0;
	height: auto;
	line-height: 1;
	color: #7a7a7a;
	font-size: 15px;
	font-family: 'Open Sans';
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	background-color: transparent;
}
.single-service-box.single-service-box-v3 .service-box-content .service-box-btn a:hover {
	color: #3275FF;
}
.single-service-box.single-service-box-v3 .service-box-content .service-box-btn a i {
	margin-left: 9px;
}
/* 03. Data Analysis Feature Area */
.data-analysis-feature-area .data-analysis-content .section-title .section-title-description {
	margin-bottom: 23px;
}
.data-analysis-feature-area .data-analysis-preview-wrapper {
	position: relative;
	height: 645px;
}
.data-analysis-feature-area .data-analysis-preview-wrapper .data-analysis-preview{
	border-radius: 10px;
	-webkit-box-shadow: 0 10px 30px 0px rgb(185 182 235 / 40%);
			box-shadow: 0 10px 30px 0px rgb(185 182 235 / 40%);
}
.data-analysis-feature-area .data-analysis-preview-1 {
	position: relative;
	z-index: 1;
}
.data-analysis-feature-area .data-analysis-preview-2 {
	position: absolute;
	left: 26%;
	z-index: 2;
	top: 75px;
}
.data-analysis-feature-area .data-analysis-preview-3 {
	position: absolute;
	right: 80px;
	z-index: 3;
	top: 25px;
}
.data-analysis-feature-area .data-analysis-preview-4 {
	position: absolute;
	left: 30px;
	z-index: 1;
	bottom: 0;
}
.data-analysis-feature-area .data-analysis-preview-5 {
	position: absolute;
	right: 20%;
	z-index: 4;
	bottom: 78px;
}
.data-analysis-feature-area .data-analysis-preview-6 {
	position: absolute;
	right: 65px;
	bottom: 40%;
	z-index: 1;
}

/* 04. What We Do / Services Area */
.our-services.our-services-v1 {
	background-size: contain;
	background-position: top center;
	background-attachment: scroll;
	background-repeat: no-repeat;
	background-color: #F2F5FE;
}
.our-services .service-area-internal {
	position: relative;
	z-index: 1;
}
.our-services .service-area-internal .section-particle-effect .particle-1 {
	top: 30%;
}
.our-services .service-area-internal .section-particle-effect .particle-3 {
	top: -10%;
}

/* Single Feature Box */
.single-feature-box {
	border: 2px solid #262C3C;
	padding: 42px 44px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 30px;
}
.single-feature-box .feature-box-content h4 {
	color: #fff;
	margin-bottom: 11px;
	font-size: 24px;
	text-transform: capitalize;
}
.single-feature-box .feature-box-icon {
	max-width: 70px;
	margin-right: 40px;
	min-width: 70px;
	line-height: 0;
}
.single-feature-box .feature-box-icon img,
.single-feature-box .feature-box-icon i {
	-webkit-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
.single-feature-box:hover .feature-box-icon img,
.single-feature-box:hover .feature-box-icon i {
	-webkit-transform: rotateY(360deg);
			transform: rotateY(360deg);
}
.single-feature-box .feature-box-icon i {
	font-size: 75px;
	color: #034380;
	-webkit-transition-delay: 0s;
		 -o-transition-delay: 0s;
			transition-delay: 0s;
}
.single-feature-box .feature-box-content p {
	color: #a8acb7;
	font-size: 17px;
	line-height: 30px;
}
.single-feature-box {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-feature-box:hover {
	border: 2px solid #034380;
}

/* Single Feature Box V2 */
.single-feature-box.single-feature-box-v2 {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
			box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
	border: 0;
	border-radius: 7px;
	position: relative;
}
.single-feature-box.single-feature-box-v2 .feature-box-content h4 {
	color: #003669;
}
.single-feature-box.single-feature-box-v2 .feature-box-content p {
	color: #6e727d;
}
.single-feature-box.single-feature-box-v2 .feature-box-icon i {
	color: #F65911;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-1 .feature-box-icon i {
	color: #F65911;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-2 .feature-box-icon i {
	color: #dc64ff;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-3 .feature-box-icon i {
	color: #2f84fb;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-4 .feature-box-icon i {
	color: #ff6c02;
}

/*  Single Feature Box V2 Border Effect */
.single-feature-box.single-feature-box-v2:before, .single-feature-box.single-feature-box-v2:after {
	position: absolute;
	content: "";
	height: 5px;
	width: 5px;
	top: 0;
	left: 0;
	background-color: #F65911;
	border-radius: 50px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	opacity: 0;
}
.single-feature-box.single-feature-box-v2:after {
	top: auto;
	bottom: 0;
	-webkit-transition-delay: 0.2s;
		 -o-transition-delay: 0.2s;
			transition-delay: 0.2s;
}
.single-feature-box.single-feature-box-v2:before {
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
}
.single-feature-box.single-feature-box-v2:hover:before {
	border-radius: 2.5px 2.5px 0 0;
}
.single-feature-box.single-feature-box-v2:hover:after {
	border-radius: 0 0 2.5px 2.5px;
}
.single-feature-box.single-feature-box-v2:hover:before, .single-feature-box.single-feature-box-v2:hover:after {
	opacity: 1;
	height: 50%;
}

.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-1:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-1:after {
	background-color: #F65911;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-2:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-2:after {
	background-color: #dc64ff;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-3:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-3:after {
	background-color: #2f84fb;
}
.single-feature-box.single-feature-box-v2.single-feature-box-v2-color-4:before, .single-feature-box.single-feature-box-v2.single-feature-box-v2-color-4:after {
	background-color: #ff6c02;
}

/* Single Feature Box V3 */
.single-feature-box.single-feature-box-v3 {
	border-radius: 7px;
	border: 2px solid rgb(71 59 240 / 7%);
	position: relative;
	z-index: 1;
	overflow: hidden;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-feature-box.single-feature-box-v3:hover {
	border-color: #034380;
	background-color: #034380;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.36);
}
.single-feature-box.single-feature-box-v3:hover .feature-box-content h4, .single-feature-box.single-feature-box-v3:hover .feature-box-content p {
	color: #fff;
}
.single-feature-box.single-feature-box-v3 .feature-box-content h4 {
	color: #003669;
}
.single-feature-box.single-feature-box-v3 .feature-box-content p {
	color: #6e727d;
}
.single-feature-box.single-feature-box-v3:after{
	position: absolute;
	content: "";
	background: #034380;
	height: 100px;
	width: 100px;
	border-radius: 50px;
	left: 50%;
	top: 34%;
	z-index: -1;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transform: scale(0.5);
	-ms-transform: scale(0.5);
	transform: scale(0.5);
	margin-left: -50px;
	margin-top: -30px;
	background-image: url(../img/services/single-service-bg.png);
	background-size: contain;
	background-position: left bottom;
	background-repeat: no-repeat;
	opacity: 0;
}
.single-feature-box.single-feature-box-v3:hover:after{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	height: 100%;
	width: 100%;
	border-radius: 5px;
	margin: 0;
	left: 0;
	top: 0;
	opacity: 1;
}
.single-feature-box.single-feature-box-v3:hover .feature-box-icon i {
	color: #fff;
}

/* 05. App Statistic Area */
.app-statistic-area .statistic-preveiw-thumbnail {
	position: relative;
	z-index: 2;
}
.app-statistic-area .statistic-preveiw-thumbnail:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 80%;
	right: 0;
	background: #F2F1FE;
	top: 0;
	z-index: -2;
	opacity: 0.7;
}
.app-statistic-area .statistic-preveiw-thumbnail:before {
	position: absolute;
	content: '';
	height: 100%;
	width: 70%;
	z-index: -1;
	right: 0;
	top: 0;
	background-image: url(../img/app/statistic-pattern.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.app-statistic-area .statistic-preveiw-thumbnail .statistic-thumbnail-4 {
	margin-top: 25px;
	margin-left: -30px;
}
.app-statistic-area .statistic-preveiw-thumbnail .statistic-thumbnail-3 {
	margin-top: -25px;
}
.app-statistic-area .statistic-preveiw-thumbnail {
	padding-top: 80px;
	padding-bottom: 50px;
}
.app-statistic-area .statistic-preveiw-thumbnail .statistic-thumbnail-2 {
	margin-left: -30px;
}
.app-statistic-area .simple-icon-box-wrapper{
	gap: 40px;
}

/* Company Statistic Area (Home V6) */
.our-statistics-images {
	position: relative;
}
.our-statistics-images .our-statistics-image-main img {
	width: 100%;
	-webkit-box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
	box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
	border-radius: 10px;
}
.our-statistics-images .our-statistics-image-main {
	margin-top: 63px;
	margin-bottom: 63px;
	margin-left: 30px;
	margin-right: 30px;
}
.our-statistics-images .our-statistics-image-relative, 
.our-statistics-images .our-statistics-image-absolute {
	-webkit-box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
	box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
}
.our-statistics-images .our-statistics-image-relative, 
.our-statistics-images .our-statistics-image-absolute {
	position: absolute;
	z-index: 1;
}
.our-statistics-images .our-statistics-image-relative {
	right: 0;
	top: -63px;
}
.our-statistics-images .our-statistics-image-absolute {
	bottom: -62px;
	left: 0;
}

/* 06. Testimonial Area */
.testimonial-area {
	border-top: 1px solid rgb(71 59 240 / 10%);
}
.testimonial-area .section-internal {
	position: relative;
	z-index: 1;
}
.testimonial-area .section-particle-effect.section-particle-effect-v2 .particle-5 {
	right: -250px;
	bottom: 0;
}
.testimonial-area .section-particle-effect.section-particle-effect-v2 .particle-1 {
	top: 90px;
}
.testimonial-area .section-particle-effect.section-particle-effect-v2 .particle-3 {
	top: 90px;
	right: -220px;
}

/* Single Testimonial */
.single-testimonial {
	text-align: center;
}
.single-testimonial .testimonial-author {
	text-align: center;
}
.single-testimonial .testimonial-author img {
	margin: 0 auto;
	border-radius: 50%;
}
.single-testimonial .testimonial-author {
	margin-top: 30px;
}
.single-testimonial .testimonial-author h6 span {
	display: block;
	color: #6e727d;
	font-size: 15px;
	font-family: 'CircularStdMedium';
	font-weight: 400;
	text-transform: capitalize;
}
.single-testimonial .testimonial-author h6 {
	font-size: 18px;
	color: #003669;
	margin-top: 19px;
	line-height: 25px;
}
.single-testimonial .quote-thumbnail{
	text-align: center;
	margin-bottom: 2px;
}
.single-testimonial .quote-thumbnail img {
	margin: 0 auto;
	margin-bottom: 30px;
}
.single-testimonial .quote-thumbnail i {
	color: #034380;
	font-size: 88px;
}
.single-testimonial p {
	font-size: 20px;
	color: #003669;
	line-height: 35px;
	font-family: 'CircularStdMedium';
}
.testimonial-slider .slick-slide {
	margin: 0 30px;
}
.testimonial-slider .slick-list {
	margin: 0 -15px;
}

/* Single Testimonial V2 */
.testimonial-area.bg-tuna.testimonial-area-overflow {
	padding-top: 379px;
	margin-top: -255px;
}

.single-testimonial.single-testimonial-v2 {
	background-color: #fff;
	border-radius: 12px;
	padding: 45px 45px 51px 45px;
	margin: 10px;
}
.single-testimonial.single-testimonial-v2 p{
	font-size: 18px;
}
.single-testimonial.single-testimonial-v2 .quote-thumbnail {
	margin-bottom: 2px;
}
.single-testimonial.single-testimonial-v2 .quote-thumbnail i {
	color: #F65911;
}
.single-testimonial.single-testimonial-v2.slick-slide.slick-current {
	-webkit-box-shadow: -5px -5px 0 0 #F65911;
			box-shadow: -5px -5px 0 0 #F65911;
}
.single-testimonial.single-testimonial-v2 .testimonial-author {
	margin-top: 27px;
}

/* Single Testimonial V3 */
.single-testimonial.single-testimonial-v3 {
	background-color: #fff;
	margin-bottom: 30px;
	text-align: left;
	padding: 45px;
	border-radius: 7px;
}
.single-testimonial.single-testimonial-v3 .testimonial-author {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	text-align: left;
	margin-top: 20px;
}
.single-testimonial.single-testimonial-v3 .testimonial-author img {
	max-width: 55px;
	min-width: 55px;
	height: 55px;
	margin-left: 0;
	margin-right: 19px;
}
.single-testimonial.single-testimonial-v3 .testimonial-author h6 {
	margin-top: 0;
}
.single-testimonial.single-testimonial-v3 .single-testimonial-body p {
	color: #6e727d;
	line-height: 30px;
	font-size: 17px;
}
.single-testimonial.single-testimonial-v3 .single-testimonial-body {
	position: relative;
}
.single-testimonial.single-testimonial-v3 .single-testimonial-body .author-rating {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
	margin-bottom: 17px;
}
  
/* Single Testimonial V4 */
.testimonial-slider.testimonial-slider-v4 .slick-list {
	margin: 0;
}
.testimonial-slider.testimonial-slider-v4 .slick-dots {
	margin-top: 35px;
}
.testimonial-slider.testimonial-slider-v4 .slick-dots {
	text-align: left;
}
.testimonial-slider.testimonial-slider-v4 .slick-dots li:after {
	display: none;
}
.testimonial-slider.testimonial-slider-v4 .slick-dots li {
	height: 12px;
	width: 12px;
	margin: 0;
	margin-right: 10px;
	background-color: #393E4C;
}
.testimonial-slider.testimonial-slider-v4 .slick-dots li.slick-active {
	background-color: #034380;
}
.single-testimonial.single-testimonial-v4 {
	text-align: left;
	margin: 0;
}
.single-testimonial.single-testimonial-v4 p {
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	font-family: 'CircularStdBook';
}
.single-testimonial.single-testimonial-v4 .testimonial-author {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	margin-top: 25px;
}
.single-testimonial.single-testimonial-v4 .testimonial-author h6 {
	margin-top: 0;
	color: #fff;
	text-align: left;
	font-size: 18px;
}
.single-testimonial.single-testimonial-v4 .testimonial-author img {
	margin: 0;
	max-width: 50px;
	min-width: 50px;
	margin-right: 15px;
}
.single-testimonial.single-testimonial-v4 .testimonial-author h6 span {
	color: #a5abbc;
	font-size: 15px;
	text-transform: capitalize;
}

/*  Single Testimonial V5 (Home V5) */
#testimonial-slider-v4 {
	margin-left: -65px;
}
#testimonial-slider-v4 .slick-arrow {
	left: -13px;
}
#testimonial-slider-v4 .next.slick-arrow {
	left: auto;
	right: -13px;
}
#testimonial-slider-v4 .slick-dots {
	margin-top: 30px;
	margin-bottom: 0px;
}
.single-testimonial.single-testimonial-v5 {
	padding: 44px 53px 54px 53px;
	background-color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(71 59 240 / 8%);
			box-shadow: 0px 10px 30px 0px rgb(71 59 240 / 8%);
	margin: 0;
	border-radius: 12px;
	margin: 30px 100px;
	position: relative;
}
.single-testimonial.single-testimonial-v5:after {
	position: absolute;
	content: "";
	height: 50%;
	width: 110%;
	background: #fff;
	top: 25%;
	left: -5%;
	z-index: -1;
	border-radius: 12px;
}

/* Single Testimonial V6 */
.single-testimonial.single-testimonial-v6 {
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 12px;
	background-color: #fff;
	padding: 40px 50px;
	margin-top: 30px;
	margin-bottom: 30px;
	position: relative;
	z-index: 2;
	-webkit-box-shadow: 0px 5px 15px 0px rgb(71 59 240 / 10%);
	box-shadow: 0px 5px 15px 0px rgb(71 59 240 / 10%);
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}
.single-testimonial.single-testimonial-v6.slick-current,
.single-testimonial.single-testimonial-v6:hover {
	box-shadow: none;
}
.single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-author {
	text-align: left;
}
.single-testimonial.single-testimonial-v6 .testimonial-author-quote img {
	border-radius: 50%;
}
.single-testimonial.single-testimonial-v6 .testimonial-author-quote {
	min-width: 80px;
	max-width: 80px;
	text-align: center;
	margin-right: 30px;
}
.single-testimonial.single-testimonial-v6 .testimonial-author-quote i {
	font-size: 56px;
	color: #034380;
	margin-top: -20px;
}
.single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-message {
	color: #7a7a7a;
	font-family: 'CircularStdBook';
	font-weight: 400;
}
.single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-author, .single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-author h6 {
	margin-top: 0;
}
.single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-author {
	margin-top: 10px;
}
.single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-author h6 {
	color: #1f1f1f;
	font-size: 20px;
}
.single-testimonial.single-testimonial-v6 .single-testimonial-body .testimonial-author h6 span {
	color: #034380;
	font-family: 'CircularStdMedium';
}
.single-testimonial.single-testimonial-v6:before {
	content: "";
	height: 100%;
	width: 100%;
	background-color: #fff;
	left: 0;
	top: 0;
	z-index: -1;
	position: absolute;
	border-radius: 12px;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
	-webkit-box-shadow: 0px 5px 15px 0px rgb(71 59 240 / 10%);
	box-shadow: 0px 5px 15px 0px rgb(71 59 240 / 10%);
	opacity: 0;
	visibility: hidden;
}
.single-testimonial.single-testimonial-v6:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	z-index: -2;
	background-color: #034380;
	top: 0px;
	left: 0px;
	border-radius: 12px;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
	visibility: hidden;
	opacity: 0;
	-webkit-box-shadow: 0px 5px 15px 0px rgb(71 59 240 / 10%);
	box-shadow: 0px 5px 15px 0px rgb(71 59 240 / 10%);
}
.single-testimonial.single-testimonial-v6:hover:before,
.single-testimonial.single-testimonial-v6:hover:after,
.single-testimonial.single-testimonial-v6.slick-current:before,
.single-testimonial.single-testimonial-v6.slick-current:after {
	opacity: 1;
	visibility: visible;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
.single-testimonial.single-testimonial-v6.slick-current:after,
.single-testimonial.single-testimonial-v6:hover:after{
	top: -10px;
	left: -10px;
}
#testimonial-slider-v5 .slick-slide {
	margin: 30px 15px;
}
#testimonial-slider-v5 .slick-dots {
	margin-top: 59px;
}
#testimonial-slider-v5 .slick-dots li {
	background-color: rgb(51 119 255 / 20%);
}
#testimonial-slider-v5 .slick-dots li.slick-active {
	background-color: #034380;
}
#testimonial-slider-v5 .slick-dots li:after {
	border-color: rgb(51 119 255 / 20%);
}
#testimonial-slider-v5 .slick-dots li.slick-active:after {
	border-color: #034380;
}
#testimonial-slider-v5 .slick-list {
	margin: 0 -15px;
}
/* Testimonial V5 Right Image (Home V5) */
.square-and-oval-image-wrapper {
	position: relative;
}
.square-and-oval-image-wrapper .image-square {
	position: relative;
	overflow: hidden;
}
.square-and-oval-image-wrapper .image-square:before {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, .7)), to(rgba(255, 255, 255, .3)));
	background: -o-linear-gradient(left, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .3) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .3) 100%);
	-webkit-transform: skewX(25deg);
		-ms-transform: skewX(25deg);
			transform: skewX(25deg);
	z-index: 2;
}
.square-and-oval-image-wrapper:hover .image-square:before {
	-webkit-animation: shine 1.5s;
	animation: shine 1.5s;
}
.square-and-oval-image-wrapper .image-oval {
	position: absolute;
	right: -41px;
	bottom: 80px;
	width: 215px;
	height: 215px;
	border-radius: 50%;
	z-index: 3;
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.3);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.3);
	overflow: hidden;
}
.square-and-oval-image-wrapper .image-oval:before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	display: block;
	content: "";
	width: 0px;
	height: 0px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
}
.square-and-oval-image-wrapper:hover .image-oval:before {
	-webkit-animation-duration: 0.95s;
	animation-duration: 0.95s;
	-webkit-animation-timing-function: ease;
	animation-timing-function: ease;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: none;
	animation-fill-mode: none;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	-webkit-animation-name: circle;
	animation-name: circle;
}
.square-and-oval-image-wrapper .image-oval img {
	border-radius: 50%;
	height: 215px;
	width: 215px;
	border: 10px solid #fff;
}
.square-and-oval-image-wrapper .image-square img {
	width: 100%;
}
.square-and-oval-image-wrapper .project-count {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.3);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.3);
	border-radius: 12px;
	position: absolute;
	top: 65px;
	left: -30px;
	z-index: 5;
	background-color: #fff;
	padding: 15px 30px;
}
.square-and-oval-image-wrapper .project-count .count-icon {
	min-width: 50px;
	max-width: 50px;
	margin-right: 15px;
}
.square-and-oval-image-wrapper .project-count .count-icon i {
	background-color: #034380;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 20px;
	border-radius: 50%;
	color: #fff;
	transition: 0.3s;
}
.square-and-oval-image-wrapper .project-count .count-details p {
	color: #7a7a7a;
	font-size: 17px;
	font-family: 'CircularStdMedium';
}
.square-and-oval-image-wrapper .project-count .count-details h4 {
	font-size: 24px;
	line-height: 30px;
}
.square-and-oval-image-wrapper:hover .project-count .count-icon i {
	transform: rotate(360deg);
}

/* Testimonial Custom Rating */
.single-testimonial .rating-review li {
	display: inline-block;
}
.single-testimonial .rating-review li {
	color: #FFA800;
}
.single-testimonial .rating-review li:last-child a {
	color: #96a6a7;
	padding-left: 5px;
}
.single-testimonial .rating-review .all-rating {
	height: 25px;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.single-testimonial .rating-review .all-rating .blank-rating {
	bottom: 0;
	color: #f19e1f;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}
.single-testimonial .rating-review .all-rating.star-5 .main-rating {
	width: 100%;
}
.single-testimonial .rating-review .all-rating.star-5 .blank-rating {
	left: 100%;
}
.single-testimonial .rating-review .all-rating.star-4 .main-rating {
	width: 81%;
}
.single-testimonial .rating-review .all-rating.star-4 .blank-rating {
	left: 81%;
}
.single-testimonial .rating-review .all-rating.star-3 .main-rating {
	width: 60%;
}
.single-testimonial .rating-review .all-rating.star-3 .blank-rating {
	left: 60%;
}
.single-testimonial .rating-review .all-rating.star-2 .main-rating {
	width: 38%;
}
.single-testimonial .rating-review .all-rating.star-2 .blank-rating {
	left: 41%;
}
.single-testimonial .rating-review .all-rating.star-1 .main-rating {
	width: 12%;
}
.single-testimonial .rating-review .all-rating.star-1 .blank-rating {
	left: 20%;
}

/* Testimonial Sliders Arrows */
.testimonial-slider .slick-arrow {
	height: 50px;
	width: 50px;
	background-color: #034380;
	text-align: center;
	line-height: 50px;
	font-size: 15px;
	color: #fff;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -25px;
	z-index: 1;
	cursor: pointer;
	border: 2px solid #034380;
}
.testimonial-slider .next.slick-arrow {
	left: auto;
	right: 0;
}
.testimonial-slider .slick-arrow.slick-disabled {
	background-color: #f4f7fa;
	border-color: rgb(22 28 45 / 10%);
	color: #034380;
}

/* Testimonial Sliders Dots */
.testimonial-slider .slick-dots {
	text-align: center;
	margin-top: 79px;
}
.testimonial-slider .slick-dots li {
	display: inline-block;
	height: auto;
}
.testimonial-slider .slick-dots li {
	height: 12px;
	width: 12px;
	background: #DAD8FC;
	border-radius: 50px;
	margin: 0 10px;
}
.testimonial-slider .slick-dots li {
	position: relative;
}
.testimonial-slider .slick-dots li:after {
	position: absolute;
	content: "";
	height: 30px;
	width: 30px;
	background: transparent;
	z-index: -1;
	border-radius: 50px;
	left: -9px;
	top: -9px;
	border: 2px solid #034380;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	z-index: 1;
}
.testimonial-slider .slick-dots li {
	margin: 0 15px;
}
.testimonial-slider .slick-dots li.slick-active:after {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.testimonial-slider .slick-dots li.slick-active {
	background: #034380;
}

/* Testimonial Slider V2 Slick Dots */
.testimonial-slider.testimonial-slider-v2 .slick-dots li {
	background-color: #544466;
}
.testimonial-slider.testimonial-slider-v2 .slick-dots li:after {
	border-color: #F65911;
}
.testimonial-slider.testimonial-slider-v2 .slick-dots li.slick-active {
	background: #F65911;
}

/* 07. Counter Up Area */
.counter-up-area {
	background-image: url(../img/particle/count-down-bg.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
}
.counter-up-area .counter-up-thumbnails {
	position: relative;
	z-index: 1;
	text-align: center;
}
.counter-up-area .counter-up-thumbnails::after {
	position: absolute;
	content: "";
	background-image: url(../img/app/count-down-main-thumbnail-border.png);
	background-repeat: no-repeat;
	background-size: 90% 100%;
	top: -95px;
	right: 0px;
	height: 150%;
	width: 100%;
	z-index: -1;
}
.counter-up-area .counter-up-thumbnails .counterup-main-thumbnail-top {
	position: absolute;
	top: -60px;
	right: 70px;
	z-index: 1;
}
.counter-up-area .counter-up-thumbnails .counterup-main-thumbnail-bottom {
	position: absolute;
	bottom: -40px;
	left: 30px;
	z-index: 1;
}

/* Single Counter Up Box */
.single-counter-up .counterup-icon i {
	font-size: 30px;
	width: 80px;
	height: 80px;
	background: rgb(255 255 255 / 7%);
	text-align: center;
	line-height: 80px;
	color: #fff;
	border-radius: 50px;
}
.single-counter-up .counterup-icon {
	margin-bottom: 24px;
}
.single-counter-up .counterup-info h2 {
	font-size: 42px;
	color: #fff;
	font-family: 'CircularStdBook';
	font-weight: 400;
}
.single-counter-up .counterup-info p {
	font-size: 17px;
	color: #fff;
}
.single-counter-up {
	margin-bottom: 80px;
}
.single-counter-up .counterup-icon i {
	position: relative;
	z-index: 1;
}
.single-counter-up .counterup-icon i:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background-color: #fff;
	left: 0;
	z-index: -1;
	border-radius: 50%;
	top: 0;
	transform: scale(0.2);
	transition: 0.3s;
	opacity: 0;
}
.single-counter-up:hover .counterup-icon i:after {
	transform: scale(1);
	opacity: 1;
}
.single-counter-up:hover .counterup-icon i {
	color: #034380;
}

/* Counter Up Box V2 */
.single-counter-up.single-counter-up-v2 .counterup-icon i:after {
	display: none;
}.single-counter-up.single-counter-up-v2:hover .counterup-icon i {
	color: #fff;
}
.single-counter-up.single-counter-up-v2 {
	margin-bottom: 30px;
}
.counter-up-area .counterup-internal {
	padding: 63px 75px 33px 75px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 0px 20px rgb(244 115 42 / 7%);
			box-shadow: 0px 0px 0px 20px rgb(244 115 42 / 7%);
	background-position: bottom center;
	background-size: contain;
	background-attachment: scroll;
	background-repeat: no-repeat;
	position: relative;
}
.counterup-internal.bg-royal-blue{
	-webkit-box-shadow: 0px 0px 0px 20px rgb(71 59 240 / 7%);
			box-shadow: 0px 0px 0px 20px rgb(71 59 240 / 7%);
}
.single-counter-up.single-counter-up-v2 .counterup-info h2 {
	min-width: 80px;
	max-width: 80px;
	margin-right: 30px;
	position: relative;
	margin-bottom: 0;
	line-height: 1;
}
.single-counter-up.single-counter-up-v2 .counterup-info h2 .sign {
	font-size: 24px;
	top: -10px;
	position: absolute;
	right: -30px;
}
.single-counter-up.single-counter-up-v2 .counterup-info {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
}
.single-counter-up.single-counter-up-v2 p {
	font-size: 18px;
	line-height: 25px;
	text-align: left;
}
.single-counter-up.single-counter-up-v2 .counterup-info h2 .counter {
	font-size: 85px;
}

/* Counter Up Box (Home V5) */
.statistic-count-boxes .single-counter-up.single-counter-up-v2 {
	margin-bottom: 55px;
}
.statistic-count-boxes .single-counter-up.single-counter-up-v2 .counterup-info .counter {
	color: #003669;
	font-size: 80px;
}
.statistic-count-boxes .single-counter-up.single-counter-up-v2 .counterup-info  .sign {
	color: #034380;
}
.statistic-count-boxes .single-counter-up.single-counter-up-v2 .counterup-info p {
	color: #7a7a7a;
}

/* Fact and CTA Area (Home V6) */
.fact-and-cta-area .single-counter-up.single-counter-up-v2 .counterup-info h2 .sign {
	color: #ffa800;
}

/* Simple Counter Up */
.simple-counter-up .single-counter-up.single-counter-up-v2 .counterup-info h2 .counter {
	color: #034380;
}
.simple-counter-up .single-counter-up.single-counter-up-v2 .counterup-info h2 .sign {
	color: #F65911;
}
.simple-counter-up .single-counter-up.single-counter-up-v2 .counterup-info {
	display: block;
}
.simple-counter-up .single-counter-up.single-counter-up-v2 p {
	color: #034380;
	font-family: 'CircularStdMedium';
}

/* 08. Call to Action Area */
.cta-area {
	padding-top: 100px;
	padding-bottom: 100px;
}
.cta-area .section-title .section-button-wrapper.section-dual-button{
	gap: 10px;
}
.cta-area .cta-section-particle .cta-particle-left {
	position: absolute;
	left: 0;
	top: 30px;
	max-width: 180px;
	border-radius: 50%;
}
.cta-area .cta-section-particle .cta-particle-right {
	position: absolute;
	right: 0;
	top: 60px;
	max-width: 160px;
}
.cta-area .cta-content-internal {
	position: relative;
}
.cta-area .cta-content .section-title .section-heading-tag {
	margin-bottom: 26px;
}
.cta-area .cta-content .section-title h2 {
	margin-bottom: 38px;
	line-height: 50px;
}
.cta-area.bg-ocean-blue .cta-content .section-title h2{
	margin-bottom: 28px;
}

/* Call to Action V2 */
.cta-area.cta-area-v2 {
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
	z-index: 1;
	padding-top: 120px;
	padding-bottom: 130px;
}
.cta-area.cta-area-v2:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	background-color: #0B1224;
	opacity: 0.75;
}
.cta-area.cta-area-v2 .cta-content .filled-btn {
	box-shadow: none;
}
.cta-area.cta-area-v2 .cta-content .filled-btn:hover {
	background-color: #fff;
	color: #003669;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.cta-area.cta-area-v2 .cta-content .filled-btn:after {
	background-color: #fff;
}
.cta-area.cta-area-v2 .cta-content .filled-btn.btn-bordered {
	border-color: #8991A7;
}
.cta-area.cta-area-v2 .cta-content .filled-btn.btn-bordered:hover {
	border-color: #fff;
	color: #003669;
}
.cta-area.cta-area-v2 .cta-content  .section-title .section-heading-tag {
	margin-bottom: 15px;
}
.cta-area.cta-area-v2 .cta-content  .section-title .section-heading-tag .single-heading-tag {
	padding: 0;
	height: auto;
	color: #fff;
	background-color: transparent;
}
/* Simple CTA Area (Home V5) */
.simple-cta-area .simple-cta-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-ms-flex-pack: distribute;
		justify-content: space-around;
	padding: 30px 0 32px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: scroll;
}
.simple-cta-area .simple-cta-content h4 {
	color: #fff;
	font-size: 24px;
	font-family: 'CircularStdBook';
	font-weight: 400;
}

/* App CTA Area */
.app-cta-area .section-title h2 {
	margin-bottom: 56px;
}
.app-cta-area .section-title .section-title-description{
	margin-bottom: 33px;
}

/* Video and CTA Area (Home V6) */

/* fact-and-cta */
.fact-and-cta-area {
	position: relative;
	z-index: 2;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
}
.fact-and-cta-area:after {
	position: absolute;
	content: "";
	height: 75px;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: -1;
	background-color: #fff;
}

.video-cta-area .featured-video-area .featured-video-content,
.video-cta-area .cta-area-2 {
	height: 350px;
	border-radius: 7px;
	margin-bottom: 30px;
}
.video-cta-area .featured-video-area .featured-video-content.video-overlay:after {
	background-color: #2B354F;
	border: 7px;
	opacity: 0.40;
}
.video-cta-area .featured-video-area .featured-video-content .play-video-btn i {
	background-color: #034380;
	height: 75px;
	width: 75px;
	line-height: 75px;
}
.video-cta-area .featured-video-area .featured-video-content  .play-video-btn i.pulse-animated:after {
	background-color: rgb(255 255 255 / 36%);
}
.video-cta-area .cta-area-2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	padding: 0 50px;
}
.video-cta-area .cta-area-2 .section-title h2 {
	font-size: 36px;
	margin-bottom: 10px;
	line-height: 42px;
}
.video-cta-area .cta-area-2 .section-title.section-title-white .filled-btn.btn-bordered {
	border-color: #fff;
	box-shadow: none;
}
.video-cta-area .cta-area-2 .section-title.section-title-white .filled-btn.btn-bordered:hover {
	color: #003669;
}
.video-cta-area .cta-area-2 .section-title .section-title-description {
	margin-bottom: 22px;
}
/* 09. Hero Area V1 */
.hero-area.hero-v1{
	background-size: 100% 100%;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	overflow: hidden;
}
.hero-area.hero-v1 .hero-internal{
	position: relative;
	z-index: 1;
	padding-top: 130px;
	padding-bottom: 132px;
}
.hero-area.hero-v1 .hero-content{
	padding-bottom: 134px;
	text-align: center;
}
.hero-area.hero-v1 .section-title .section-heading-tag {
	margin-bottom: 26px;
}
.hero-area.hero-v1 .section-title h1 {
	font-size: 85px;
	line-height: 95px;
	margin-bottom: 44px;
}
.hero-area.hero-v1 .section-title h1 span {
	color: #034380;
}
.hero-area.hero-v1 .section-title h1 span{
	padding: 5px 20px;
	position: relative;
	z-index: 1;
}
.hero-area.hero-v1 .app-dashboard-preview img {
	border-radius: 20px;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(225 223 255 / 36%);
	box-shadow: 0px 10px 30px 0px rgb(225 223 255 / 36%);
}
.hero-area.hero-v1 .section-title h1 span:after {
	position: absolute;
	content: "";
	width: 100%;
	left: 0;
	background-image: url(../img/particle/heading-line.png);
	background-repeat: no-repeat;
	background-size: 100%;
	z-index: -1;
	background-attachment: scroll;
	background-position: bottom;
	padding-bottom: 8px;
	top: auto;
	bottom: 0;
	height: 100%;
}
.hero-area.hero-v1 .hero-internal:after {
	position: absolute;
	content: "";
	z-index: -1;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: url(../img/app/app-overview-shadow.png) no-repeat scroll;
	background-size: 100% 100%;
	background-position: 0 -200px;
	z-index: -1;
}
.hero-area.hero-v1 .section-particle-effect .particle-1, .hero-area.hero-v1 .section-particle-effect .particle-3 {
	top: 100px;
}
.hero-area.hero-v1 .section-particle-effect .particle-2 {
	top: 155px;
	left: -30px;
}
.hero-area.hero-v1 .section-particle-effect .particle-3 {
	right: 140px;
	top: 85px;
}
.hero-area.hero-v1 .section-particle-effect .particle-4 {
	top: 33%;
	left: -30px;
}
.hero-area.hero-v1 .section-particle-effect .particle-1 {
	left: -100px;
}
.hero-area.hero-v1 .section-particle-effect .particle-5 {
	top: 33%;
	right: 0;
}


/* Hero Area V2 */
.hero-area.hero-v2{
	padding-top: 155px;
	padding-bottom: 40px;
}
.hero-area.hero-v2 .section-title .section-heading-tag {
	margin-bottom: 27px;
}
.hero-area.hero-v2 .section-title h1,
.hero-area.hero-v3 .section-title h1 {
	font-size: 95px;
	line-height: 105px;
	margin-bottom: 63px;
}
.hero-area .section-title.section-title-white h1 span{
	position: relative;
	z-index: 1;
}
.hero-area .section-title.section-title-white h1 span:after{
	position: absolute;
	content: "";
	width: 100%;
	left: 0;
	z-index: -1;
	background-attachment: scroll;
	background-position: bottom;
	background-repeat: no-repeat;
	padding-bottom: 8px;
	bottom: -15px;
	height: 39px;
	background-image: url(../img/particle/section-title-v2-line-white.png);
	background-size: contain;
}
.hero-area .section-title h1 {
	margin-bottom: 50px;
}

.blob-image-wrapper {
	position: relative;
	z-index: 1;
}
.blob-image-wrapper .blob-main-image {
	position: relative;
	z-index: 3;
}
.blob-image-wrapper .blob-shape-wrapper .blob-shape {
	left: 0;
	top: 0;
	position: absolute;
	max-width: 50px;
}
.blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-2 {
	left: auto;
	right: 0;
}
.blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-3 {
	top: auto;
	bottom: -50px;
	left: 120px;
}

.hero-area.hero-v2 .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-1 {
	left: 9%;
	top: 5%;
}
.hero-area.hero-v2 .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-2 {
	right: 2%;
	top: 17%;
}
.hero-area.hero-v2 .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-3 {
	left: 25%;
	bottom: -6%;
}

.hero-infobox-area .hero-infobox-internal {
	background-color: #0146F6;
	padding: 80px 80px 71px 80px;
	margin-top: 103px;
	border-radius: 15px;
	border-top: 4px solid #DCE5FB;
}
.hero-infobox-area .hero-infobox-internal {
	position: relative;
}
.hero-infobox-area .hero-infobox-internal:after {
	position: absolute;
	content: "";
	height: 70%;
	width: 2px;
	background-color: #215FFB;
	top: 15%;
	right: 50%;
}
.infobox-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.infobox-item .infobox-icon {
	max-width: 80px;
	min-width: 80px;
	margin-right: 39px;
}
.infobox-item  .infobox-content .infobox-title {
	font-size: 24px;
	line-height: 34px;
	color: #ffffff;
	margin-bottom: 9px;
	font-family: 'CircularStdMedium';
	font-weight: 500;
}
.infobox-item .infobox-content p {
	color: #fff;
}

/* Hero Area V3 */
.hero-area.hero-v3 .section-title h1{
	margin-bottom: 57px;
}
.hero-area.hero-v3 .section-title.section-title-white h1 span:after{
	background-image: url(../img/particle/section-title-v3-line-white.png);
}
.hero-area.hero-v3 .section-title.section-title-white .filled-btn.bg-rose-bud {
	-webkit-box-shadow: none;
			box-shadow: none;
}
.hero-area.hero-v3 {
	background-position: bottom right;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.hero-area.hero-v3:after{
	position: absolute;
	content: "";
	height: 340px;
	width: 270px;
	left: 0;
	top: 0;
	z-index: -1;
	background-image: url(../img/hero/hero-line.png);
	background-size: contain;
	background-attachment: scroll;
	background-position: top left;
	background-repeat: no-repeat;
}
.hero-area.hero-v3 .hero-content{
	padding: 164px 0;
}
.hero-area.hero-v3 .section-title.section-title-white .filled-btn.btn-bordered {
	border-color: rgb(255 255 255 / 15%);
}
.hero-area.hero-v3 .filled-btn.bg-rose-bud:hover {
	background-color: #fff;
	color: #003669;
}
.hero-area.hero-v3 .filled-btn.bg-rose-bud::after{
	background-color: #fff;
}
.hero-area.hero-v3 .filled-btn.bg-rose-bud:hover::after{
	background-color: #fff;
	color: #003669;
}
.hero-area.hero-v3 .hero-content{
	position: relative;
	z-index: 1;
}
.hero-area.hero-v3 .section-particle-effect .particle-1 {
	top: 200px;
	left: -100px;
}
.hero-area.hero-v3 .section-particle-effect .particle-3 {
	bottom: 120px;
	left: -80px;
	top: auto;
}

.hero-area.hero-v4 .section-title .home-title {
	font-size: 60px;
	line-height: 62px;
}
.hero-area.hero-v4 .section-title .home-title span {
	display: inline;
	position: relative;
	z-index: 1;
}
.hero-area.hero-v4 .section-title .home-title span:after{
	position: absolute;
	content: "";
	left: 0;
	z-index: 50;
	background-image: url(../img/particle/section-title-v3-line-burning-orange.png);
	background-repeat: no-repeat;
	background-size: 260px 20px;
	background-attachment: scroll;
	background-position: bottom;
	padding-bottom: 8px;
	top: 10px;
	bottom: 0;
	height: 100%;
	width: 100%;
}
.hero-area.hero-v4 .section-title h1.home-subtitle {
	font-size: 25px;
	line-height: 30px;
	margin-right: 110px;
	font-weight: 300;
}

/* Hero Area V4 */
.hero-area.hero-v4 .section-title h1{
	font-size: 90px;
	line-height: 90px;
}
.hero-area.hero-v4 .section-title h1 span {
	display: inline;
	position: relative;
	z-index: 1;
} 
.hero-area.hero-v4 .section-title h1 span:after{
	position: absolute;
	content: "";
	left: 0;
	z-index: -1;
	background-image: url(../img/particle/heading-line-mandy.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-attachment: scroll;
	background-position: bottom;
	padding-bottom: 8px;
	top: auto;
	bottom: 0;
	height: 100%;
	width: 100%;
}
.hero-area.hero-v4 .section-title .filled-btn.bg-purple {
	-webkit-box-shadow: none;
			box-shadow: none;
}

.hero-area.hero-v4 .chartboard-image-wrapper {
	position: relative;
	z-index: 1;
	height: 700px;
	width: 100%;
	margin-top: 88px;
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-app-preview {
	position: absolute;
	height: 100%;
	width: 100%;
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-image-secondary {
	position: absolute;
	right: 0;
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-image-main,
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-image-secondary {
	position: absolute;
	top: 0px;
	left: 0;
	border-radius: 20px;
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-image-secondary {
	left: auto;
	right: -250px;
	top: auto;
	bottom: 10px;
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-app-preview .chartboard-image {
	position: absolute;
	border-radius: 15px;
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-app-preview .chartboard-app-preview-3 {
	right: 180px;
	bottom: 105px;
	-webkit-box-shadow: 0 10px 30px 0px rgb(89 89 89 / 10%);
	box-shadow: 0 10px 30px 0px rgb(89 89 89 / 10%);
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-app-preview .chartboard-app-preview-2 {
	right: 38px;
	bottom: 160px;
	z-index: 2;
	-webkit-box-shadow: 0 10px 30px 0px rgb(89 89 89 / 18%);
	box-shadow: 0 10px 30px 0px rgb(89 89 89 / 18%);
}
.hero-area.hero-v4 .chartboard-image-wrapper .chartboard-app-preview .chartboard-app-preview-1 {
	right: -10px;
	top: 94px;
	z-index: 2;
}
.hero-area.hero-v4 .hero-content .user-profile-card ul li {
	display: inline-block;
	margin-right: -18px;
}
.hero-area.hero-v4 .hero-content .user-profile-card {
	margin-top: 40px;
}
.hero-area.hero-v4 .hero-content .user-profile-card ul li a {
	display: block;
	border-radius: 50%;
	height: 56px;
	width: 56px;
	line-height: 56px;
	background-color: #F0F6FF;
	color: #fff;
	font-size: 15px;
	text-align: center;
}
.hero-area.hero-v4 .hero-content .user-profile-card ul li.sign-up a {
	background: #C94102;
}
.hero-area.hero-v4 .hero-content .user-profile-card ul li a img {
	border-radius: 50%;
	border: 3px solid #fff;
}
.hero-area.hero-v4 {
	background-position: top right;
	background-size: 55% 55%;
	overflow: hidden;
	background-attachment: scroll;
	background-repeat: no-repeat;
	overflow: hidden;
}
.hero-area.hero-v4 .hero-content{
	padding: 229px 0 168px;
	position: relative;
	z-index: 1;
}
.hero-area.hero-v4 .section-particle-effect .particle-1 {
	top: 220px;
	left: -80px;
}
.hero-area.hero-v4 .section-particle-effect .particle-2 {
	right: 150px;
	left: auto;
	top: 130px;
}
.hero-area.hero-v4 .section-particle-effect .particle-3 {
	bottom: 260px;
	left: -60px;
	top: auto;
}
.hero-area.hero-v4 .section-particle-effect .particle-4 {
	top: auto;
	bottom: 128px;
	right: 0;
	left: auto;
}

/* Hero Area V5 (Home V5) */
.hero-area.hero-v5 {
	padding: 298px 0 242px;
	background-size: contain;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	overflow: hidden;
}
.hero-area.hero-v5 .hero-internal,
.hero-area.hero-v5 .hero-internal .hero-content {
	position: relative;
	z-index: 1;
}
.hero-area.hero-v5 .hero-right-thumbnail {
	height: 685px;
	width: 740px;
	position: absolute;
	right: -100px;
	top: -148px;
	border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.hero-area.hero-v5 .hero-right-thumbnail  .hero-right-bg-shape {
	background-color: #034380;
	height: 100%;
	z-index: -1;
	width: 100%;
	position: absolute;
	border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%;
	top: 10px;
	right: -10px;
}
.hero-area.hero-v5  .section-title h1 {
	margin-bottom: 35px;
	font-size: 85px;
	line-height: 95px;
}
.hero-area.hero-v5 .section-title .filled-btn {
	box-shadow: none;
}
.hero-area.hero-v5 .section-title .section-title-quote:after {
	background-color: #034380;
}
.hero-area.hero-v5 .section-title .section-title-quote {
	margin-bottom: 50px;
}
.hero-area.hero-v5 .play-btn {
	color: #034380;
}
.hero-area.hero-v5 .section-title .filled-btn:hover {
	background-color: #fff;
	color: #1B202F;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.hero-area.hero-v5 .section-title .filled-btn:after {
	background-color: #fff;
}
.hero-area.hero-v5 .hero-right-thumbnail:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background-color: #1b202f;
	opacity: 0.5;
	border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%;
}

/* Hero Area V6 (Home V6) */
.hero-slide-single {
	padding-top: 266px;
	padding-bottom: 199px;
	position: relative;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center center;
}
.hero-slide-single:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	background-image: linear-gradient( 135deg, rgba(9,16,35,0.2902) 0%, rgba(6,14,34,0.58) 61%, rgba(4,13,34,0.87843) 100%);
	background-image: -webkit-linear-gradient( 135deg, rgba(9,16,35,0.2902) 0%, rgba(6,14,34,0.58) 61%, rgba(4,13,34,0.87843) 100%);
	background-image: -ms-linear-gradient( 135deg, rgba(9,16,35,0.2902) 0%, rgba(6,14,34,0.58) 61%, rgba(4,13,34,0.87843) 100%);
}
.hero-slider .slick-arrow {
	background-color: #034380;
	position: absolute;
	bottom: 0;
	right: 70px;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
	color: #fff;
	font-size: 18px;
	cursor: pointer;
	z-index: 5;
}
.hero-slider .slick-arrow.slick-disabled {
	background-color: #FFFFFF;
	color: #2b354f;
}
.hero-slider .next.slick-arrow {
	right: 15px;
	left: auto;
}
.hero-slider .slick-dots {
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	width: auto;
}
.hero-slider .slick-dots li {
	display: block;
	height: 12px;
	width: 12px;
	background: #FFFFFF;
	border-radius: 50px;
	margin: 15px  0;
	position: relative;
	margin-left: auto;
	margin-right: 9px;
}
.hero-slider .slick-dots li.slick-active {
	background-color: #034380;
}
.hero-slider .slick-dots li:after {
	position: absolute;
	content: "";
	height: 30px;
	width: 30px;
	background: transparent;
	z-index: -1;
	border-radius: 50px;
	right: -9px;
	top: -9px;
	border: 2px solid #034380;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	z-index: 1;
}
.hero-slider .slick-dots li.slick-active:after {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.hero-area.hero-v6 .section-title h1 {
	margin-bottom: 30px;
	font-size: 80px;
	line-height: 85px;
}
.hero-area.hero-v6 .section-title .section-title-quote {
	margin-bottom: 41px;
}
.hero-area.hero-v6 .filled-btn {
	box-shadow: none;
}
.hero-area.hero-v6 .filled-btn.bg-clear-blue:hover {
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
	color: #003669;
}
.hero-area.hero-v6 .filled-btn.bg-clear-blue:hover,
.hero-area.hero-v6 .filled-btn.bg-clear-blue:after {
	background-color: #fff;
}
.hero-area .hero-btn {
	font-size: 15px;
	font-family: 'Open Sans';
	position: relative;
}
.hero-area .hero-btn:hover {
	color: #fff;
}
.hero-area.hero-v6 .section-title .section-button-wrapper.section-dual-button {
	gap: 27px;
}
.hero-area .hero-btn:after {
	position: absolute;
	content: "";
	height: 3px;
	width: 100%;
	background-color: #fff;
	bottom: -2px;
	left: 0;
}
/* 10. Brands Slider */
.brands-section-title h3 {
	font-family: 'CircularStdMedium';
	font-size: 30px;
	line-height: 35px;
}
.brands-section-title h3 span {
	color: #F65911;
}
.brands-slider .brands-item.slick-slide {
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	height: auto;
}
.brands-slider .slick-track {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.brands-slider  .brands-item.slick-slide {
	opacity: 1;
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}
.brands-slider .brands-item.slick-slide img {
	opacity: 0.40;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	cursor: pointer;
}
.brands-slider .brands-item.slick-slide:hover img {
	opacity: 1;
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}
.brands-slider .brands-item.slick-slide.slick-current img {
	opacity: 1;
}
/* Brands Slider V2 (Home V6) */
#brands-slider-v2 .brands-item.slick-slide img {
	opacity: 1;
}
/* 11. Team Member Area */

/* Single Team Member */
.single-team-member {
	position: relative;
	margin-bottom: 30px;
}
.single-team-member.fadeInUp{
	z-index: 5;
}
.single-team-member .team-member-thumb {
	overflow: hidden;
	position: relative;
	z-index: 3;
}
.single-team-member .team-member-thumb:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #F65911;
	opacity: 0;
	border-radius: 3px;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	-webkit-transform: perspective(400px) rotateX(-90deg);
			transform: perspective(400px) rotateX(-90deg);
	-webkit-transform-origin: top;
		-ms-transform-origin: top;
			transform-origin: top;
	border-radius: 6px;
	z-index: 2;
}
.single-team-member:hover .team-member-thumb:before {
	opacity: 0.6;
	-webkit-transform: perspective(400px) rotateX(0deg);
			transform: perspective(400px) rotateX(0deg);
}
.single-team-member .team-member-thumb img {
	border-radius: 7px;
	width: 100%;
}
.single-team-member .team-member-bio {
	background-color: #fff;
	border-radius: 7px;
	padding: 30px 20px;
	-webkit-box-shadow: 0px 10px 60px 0px rgba(215, 212, 255, 0.6);
			box-shadow: 0px 10px 60px 0px rgba(215, 212, 255, 0.6);
	text-align: center;
	position: absolute;
	-webkit-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
	opacity: 0;
	visibility: hidden;
	bottom: -180px;
	width: 90%;
	left: 13px;
	z-index: 4;
}
.single-team-member .team-member-bio .team-member-name {
	font-size: 20px;
	color: #1a1b1e;
	line-height: 30px;
}
.single-team-member .team-member-bio .team-member-role {
	font-size: 15px;
	font-family: 'CircularStdMedium';
	color: #F65911;
	line-height: 25px;
}
.single-team-member .social-links {
	margin-top: 13px;
}
.social-links ul li {
	margin-right: 8px;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	display: inline-block;
	margin-right: 8px;
}
.social-links ul li:last-of-type {
	margin: 0;
}
.social-links ul li a i {
	background-color: #E5EEFF;
	height: 35px;
	width: 35px;
	text-align: center;
	line-height: 35px;
	color: #0057ff;
	border-radius: 50px;
	font-size: 13px;
}
.social-links ul li a i.fa-facebook-f {
	background-color: #0040E5;
	color: #fff;
}
.social-links ul li a i.fa-twitter {
	background-color: #DDEFFB;
	color: #55aded;
}
.social-links ul li a i.fa-youtube {
	background-color: #FFE5E5;
	color: #ff0303;
}
.social-links ul li a i.fa-behance {
	background-color: #E5EEFF;
	color: #0057ff;
}
.single-team-member .social-links ul li{
	visibility: hidden;
	opacity: 0;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
	position: relative;
	left: 3px;
}
.single-team-member:hover .social-links li {
	visibility: visible;
	opacity: 1;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
.single-team-member:hover .social-links li:nth-child(2) {
	-webkit-transition-delay: .2s;
	-o-transition-delay: .2s;
	   transition-delay: .2s;
}
.single-team-member:hover .social-links li:nth-child(3) {
	-webkit-transition-delay: .3s;
	-o-transition-delay: .3s;
	   transition-delay: .3s;
}
.single-team-member:hover .social-links li:nth-child(4) {
	-webkit-transition-delay: .4s;
	-o-transition-delay: .4s;
	   transition-delay: .4s;
}
.single-team-member:hover .team-member-bio {
	opacity: 1;
	visibility: visible;
	bottom: -100px;
}

/* Single Team Member V2 */
.our-team-area.our-team-area-v2{
	background-size: contain;
	background-position: top center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-color: #F2F5FE;
	position: relative;
	z-index: 1;
}
.our-team-area.our-team-area-v2:after{
	position: absolute;
	content: "";
	height: 50%;
	width: 100%;
	background-color: #fff;
	left: 0;
	bottom: 0;
	z-index: -1;
}
.single-team-member.single-team-member-v2:hover .team-member-bio {
	bottom: 13px;
}
.single-team-member.single-team-member-v2 {
	margin-bottom: 30px;
}
.single-team-member.single-team-member-v2 .team-member-bio {
	bottom: -30px;
	-webkit-box-shadow: 0px 10px 60px 0px rgba(215, 212, 255, 0.6);
			box-shadow: 0px 10px 60px 0px rgba(215, 212, 255, 0.6);
}
.single-team-member.single-team-member-v2 .team-member-thumb:before {
	background-color: #0040e5;
}

/* 12. Newsletter Search Area */
.newsletter-search-area{
	overflow: hidden;
	position: relative;
	z-index: 1;
}
.newsletter-search-area .newsletter-search-internal {
	border-radius: 0px;
	position: relative;
	overflow: hidden;
}
.newsletter-search-area .section-bg-overflow {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 50%;
	width: 100%;
	z-index: -1;
}
.newsletter-search-area .newsletter-search-section-images {
	position: absolute;
	height: 70%;
	width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 20px 0px, bottom right;
	bottom: 0;
	width: 100%;
}
.newsletter-search-area .section-title h2 {
	font-size: 50px;
	margin-bottom: 15px;
}
.newsletter-search-area .section-title p {
	font-size: 18px;
}
.newsletter-search-area .section-title {
	margin-bottom: 35px;
}


/* .newsletter-search-area .newsletter-search-form .form-group {
	position: relative;
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	margin-bottom: 40px;
}
.newsletter-search-area .newsletter-search-form .form-group:after {
	position: absolute;
	content: "";
	height: 40px;
	width: 3px;
	background-color: #E5ECFC;
	left: 50%;
}

.newsletter-search-area .newsletter-search-form.search-form-no-divider .form-group:after {
	display: none;
}

.newsletter-search-area .newsletter-search-form .form-group input {
	height: 80px;
	padding: 0 20px;
	border: 0;
}
.newsletter-search-area .newsletter-search-form .form-group input::-webkit-input-placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.newsletter-search-area .newsletter-search-form .form-group input::placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.newsletter-search-area .newsletter-search-form .form-group input:-ms-input-placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.newsletter-search-area .newsletter-search-form .form-group input::-ms-input-placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.newsletter-search-area .newsletter-search-form .form-group input,
.newsletter-search-area .newsletter-search-form .form-group input::placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.newsletter-search-area .newsletter-search-form .form-group button {
	position: absolute;
	right: 15px;
	padding-left: 20px;
	padding-right: 20px;
}

.newsletter-search-area .newsletter-search-form .form-group input {
	border-radius: 50px;
	padding-left: 35px;
} */

/* .newsletter-search-area .newsletter-search-form .form-group input:first-of-type {
	border-radius: 50px 0 0 50px;
	padding-left: 45px;
	width: 45%;
}
.newsletter-search-area .newsletter-search-form .form-group input:last-of-type {
	border-radius: 0 50px 50px 0;
	padding-right: 213px;
	width: 55%;
	padding-left: 25px;
} */

/* Newsletter Form */
.subscribe-newsletter-area{
	background-color: #F0F6FF;
	background-size: cover;
	background-position: center center;
	background-attachment: scroll;
	background-repeat: no-repeat;
	overflow: hidden;
}
.subscribe-newsletter-area .section-internal{
	position: relative;
	z-index: 1;
}
.subscribe-newsletter-area.bg-white-lilac{
	background-color: #F4F7FA;
}
.newsletter-form .form-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 10px;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
}
.newsletter-form .form-group .filled-btn {
	-webkit-box-shadow: none;
			box-shadow: none;
	height: 65px;
}
.newsletter-form .form-group input[type="email"] {
	width: 65%;
	height: 65px;
	padding: 0 30px;
	border: 0;
	border-radius: 7px;
}
.newsletter-form .newsletter-notice {
	padding-top: 10px;
	color: #777777;
}

/* Newsletter Form V2 */
.newsletter-form.newsletter-form-v2 input[type="email"] {
	height: 90px;
	border-radius: 50px;
	width: 100%;
	padding: 0 45px;
	padding-right: 250px;
}
.newsletter-form.newsletter-form-v2  .form-group {
	display: block;
	position: relative;
}
.newsletter-form.newsletter-form-v2 input[type="email"]::-webkit-input-placeholder {
	font-size: 17px;
	color: #777777;
}
.newsletter-form.newsletter-form-v2 input[type="email"]::placeholder {
	font-size: 17px;
	color: #777777;
}
.newsletter-form.newsletter-form-v2 input[type="email"]:-ms-input-placeholder {
	font-size: 17px;
	color: #777777;
}
.newsletter-form.newsletter-form-v2 input[type="email"]::-ms-input-placeholder {
	font-size: 17px;
	color: #777777;
}
.newsletter-form.newsletter-form-v2 input[type="email"]::placeholder {
	font-size: 17px;
	color: #777777;
}
.newsletter-form.newsletter-form-v2 .form-group button {
	position: absolute;
	top: 13px;
	right: 15px;
}
.newsletter-form.newsletter-form-v2 .form-group .filled-btn {
	padding: 15px 40px;
}

/* Newsletter Search Area V2 (Home V5) */
.newsletter-search-area.newsletter-search-v2 .newsletter-search-section-images {
	height: 100%;
	background-position: 2% 100%, top right;
	background-size: inherit;
}
.newsletter-search-area.newsletter-search-v2 .section-title h2 {
	font-size: 42px;
}
.newsletter-search-area.newsletter-search-v2 .newsletter-search-form .form-group input,
.newsletter-search-area.newsletter-search-v2 .newsletter-search-form .form-group input::placeholder {
	font-family: 'CircularStdMedium';
}
.newsletter-search-area.newsletter-search-v2 .newsletter-search-form .form-group input:first-of-type {
	width: 40%;
}
.newsletter-search-area.newsletter-search-v2 .newsletter-search-form .form-group input:last-of-type {
	width: 60%;
	padding-right: 240px;
}
.newsletter-search-area.newsletter-search-v2 .newsletter-search-form .form-group:after {
	left: 40%;
}

/* Subscribe Newsletter (Home V6) */
.subscribe-newsletter .subscribe-newsletter-content h3 {
	font-size: 35px;
	color: #ffffff;
	line-height: 50px;
}
.subscribe-newsletter .subscribe-newsletter-content p {
	color: #fff;
	font-size: 18px;
}
.subscribe-newsletter .subscribe-newsletter-form .form-group {
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 10px;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
}
.subscribe-newsletter .subscribe-newsletter-form .form-group input[type="email"] {
	height: 65px;
	border-radius: 50px;
	border: 0;
	color: #1f1f1f;
	font-size: 17px;
	font-family: 'CircularStdMedium';
	padding: 0 40px;
	width: 70%;
	justify-content: space-between;
}
.subscribe-newsletter .subscribe-newsletter-form .form-group button.filled-btn {
	width: 245px;
	padding: 10px 38px;
	color: #fff;
	border-color: #fff;
	box-shadow: none;
}
.subscribe-newsletter .subscribe-newsletter-form .form-group button.filled-btn:hover {
	border-color: #fff;
	background-color: #fff;
	color: #003669;
}
.subscribe-newsletter .subscribe-newsletter-form .form-group button.filled-btn:after {
	background-color: #fff;
}

/* 13. FAQ Area */
.faq-area{
	overflow: hidden;
}
.faq-area .faq-content .section-title.section-title-ocean-blue h2 {
	margin-bottom: 56px;
}
.faq-area .section-internal{
	position: relative;
	z-index: 1;
}
.faq-area.bg-white-lilac{
	background-color: #F4F7FA;
}
.section-right-image.faq-right-image img {
	border-radius: 7px;
}
.section-right-image.faq-right-image{
	position: relative;
	z-index: 1;
	margin: 60px 60px 60px 0;
}
.section-right-image.faq-right-image:after{
	position: absolute;
	content: "";
	height: 120%;
	width: 120%;
	left: -20px;
	top: -45px;
	z-index: -1;
	background-image: url(../img/faq/faq-blob.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}
/* FAQ Accordion */
.section-accordion .card{
	margin-bottom: 15px;
}
.section-accordion .card:last-child {
	margin-bottom: 0;
}
.section-accordion .card .card-header .btn-link {
	padding: 15px 30px;
	width: 100%;
	text-align: left;
}
.section-accordion .card .card-header .btn-link, 
.section-accordion .card .card-body {
	background-color: #fff;
	font-family: 'Open Sans';
	font-size: 18px;
	border-radius: 30px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	line-height: 28px;
}
.section-accordion .card .card-body {
	padding: 0 30px 25px 30px;
	font-size: 17px;
	color: #606060;
	font-family: 'CircularStdBook';
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.section-accordion .card .card-header .btn-link[aria-expanded="true"] {
	margin-bottom: 0;
	border-radius: 30px 30px 0 0;
	padding: 15px 30px 7px 30px;
}
.section-accordion .card .card-body {
	border-radius: 0 0 30px 30px;
}

.section-accordion .card .card-header .btn-link:after {
	font-family: 'Font Awesome 5 Pro';
	background-image: none;
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
	content: "\f105";
	-ms-flex-negative: 0;
		flex-shrink: 0;
	margin-left: auto;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	font-size: 16px;
	margin-top: 2px;
	color: #1a1b1e;
	font-weight: 600;
}
.section-accordion .card .card-header .btn-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
	-o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease,-webkit-box-shadow .15s ease-in-out;
}
.section-accordion .card .card-header .btn-link:not(.collapsed)::after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

/* FAQ Accordion V2 */
.section-accordion.section-accordion-v2 .card .card-header .btn-link {
	padding: 13px 30px;
}
.section-accordion.section-accordion-v2 .card .card-header .btn-link[aria-expanded="true"]{
	padding: 13px 30px 5px 30px;
}
.section-accordion.section-accordion-v2 .card .card-header .btn-link {
	border: 2px solid #EDF2FD;
	border-radius: 7px;
}
.section-accordion.section-accordion-v2 .card .card-body {
	border-radius: 0 0 7px 7px;
}
.section-accordion.section-accordion-v2  .card .card-header .btn-link[aria-expanded="true"] {
	border-radius: 7px 7px 0 0;
	border-color: #fff;
}
.section-accordion.section-accordion-v2 .card {
	-webkit-box-shadow: 0px 10px 60px 0px rgb(184 179 252 / 30%);
			box-shadow: 0px 10px 60px 0px rgb(184 179 252 / 30%);
	border-radius: 7px;
}

/* 14. Pricing Table */
.pricing-table {
	padding: 29px 37px 47px 37px;
	border: 2px solid #E5ECFC;
	border-radius: 12px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	margin-bottom: 30px;
}
.pricing-table .pricing-plan-title {
	padding-top: 17px;
}
.pricing-table .pricing-plan-title .pricing-table-icon {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top right;
}
.pricing-table .pricing-plan-title .pricing-table-icon img {
	display: block;
	margin: 0 auto;
}
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title {
	margin-bottom: 23px;
	color: #fff;
	padding: 10px 25px;
	background: #F65911;
	display: inline-block;
	border-radius: 50px;
	font-size: 17px;
	font-family: 'Open Sans';
	-webkit-box-shadow: 0px 7px 25px 0px rgb(255 78 0 / 40%);
			box-shadow: 0px 7px 25px 0px rgb(255 78 0 / 40%);
	line-height: 1;
}
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title.bg-burning-orange-gradient {
	background-image: linear-gradient( -38deg, rgb(255,78,0) 0%, rgb(255,114,0) 59%, rgb(254,149,0) 100%);
	-webkit-box-shadow: 0px 7px 25px 0px rgba(255, 78, 0, 0.4);
			box-shadow: 0px 7px 25px 0px rgba(255, 78, 0, 0.4);
}
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title.bg-ocean-blue-gradient {
	background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(0,64,229) 0%, rgb(2,140,235) 59%, rgb(4,215,241) 100%);
	-webkit-box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
			box-shadow: 0px 7px 25px 0px rgba(0, 64, 229, 0.4);
} 
.pricing-table .pricing-plan-title .pricing-table-icon .plan-title.bg-magenta-gradient {
	background-image: linear-gradient( -38deg, rgb(246,89,17) 0%, rgb(201,65,2) 59%, rgb(161,51,0) 100%);
	-webkit-box-shadow: 0px 7px 25px 0px rgba(237, 0, 206, 0.4);
			box-shadow: 0px 7px 25px 0px rgba(237, 0, 206, 0.4);
}
.pricing-table .pricing-plan-cost {
	margin-top: 42px;
	margin-bottom: 25px;
}
.pricing-table .pricing-plan-cost .plan-price {
	font-size: 65px;
	margin-right: 10px;
	line-height: 1;
}
.pricing-table .pricing-plan-cost .plan-type {
	color: #606060;
	font-size: 17px;
}
.pricing-table .pricing-plan-cost .payment-currency {
	color: #ff4e00;
	font-size: 24px;
	top: -25px;
	position: relative;
}
.pricing-table .pricing-plan-features ul li {
	color: #606060;
	font-size: 17px;
	position: relative;
	margin-bottom: 9px;
}
.pricing-table .pricing-plan-features ul li:after {
	position: absolute;
	content: "\f00c";
	font-family: "Font Awesome 5 Pro";
	font-size: 9px;
	background-color: #ff4e00;
	color: #fff;
	border-radius: 50%;
	height: 15px;
	width: 15px;
	top: 7.5px;
	line-height: 15px;
	right: 0;
	text-align: center;
}
.pricing-table .pricing-plan-features ul li.plan-feature.plan-feature-disabled:after {
	background-color: #B2C5F7;
}
.pricing-table .pricing-plan-features {
	margin-bottom: 37px;
}
.pricing-table.professional-plan .pricing-plan-cost .payment-currency {
	color: #0040e5;
}
.pricing-table.professional-plan{
	position: relative;
	border-color: transparent;
	z-index: 1;
}
.pricing-table.professional-plan::before {
	content:"";
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	border-radius:12px; 
	padding:2px; 
	background: -o-linear-gradient( 128deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%); 
	background: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%); 
	-webkit-mask: linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%) content-box, 
	linear-gradient( -38deg, rgb(4,215,241) 0%, rgb(2,140,235) 59%, rgb(0,64,229) 100%);
	-webkit-mask-composite: destination-out; 
	mask-composite: exclude; 
	z-index: -1;
	transition: 0.3s;
}
.pricing-table.premium-plan .pricing-plan-cost .payment-currency {
	color: #ff4e00;
}
.pricing-table.professional-plan .pricing-plan-features ul li:after {
	background-color: #0040e5;
}
.pricing-table.premium-plan .pricing-plan-features ul li:after {
	background: #ed00ce;
}
.pricing-table.starter-plan:hover {
	border-color: #F65911;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(244 115 42 / 18%);
	box-shadow: 0px 10px 30px 0px rgb(244 115 42 / 18%);
}
.pricing-table.professional-plan:hover {
	-webkit-box-shadow: 0px 7px 25px 0px rgb(0 64 229 / 18%);
	box-shadow: 0px 7px 25px 0px rgb(0 64 229 / 18%);
	border-color: #0040e5;
}
.pricing-table.professional-plan:hover:before {
	opacity: 0;
}
.pricing-table.premium-plan:hover {
	border-color: #ed00ce;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(237 0 206 / 18%);
	box-shadow: 0px 10px 30px 0px rgb(237 0 206 / 18%);
}

/* Pricing Table V2 */
.pricing-area .pricing-area-content .section-title h2 {
	margin-bottom: 60px;
}
.pricing-area .pricing-area-content .section-title .section-title-description{
	margin-bottom: 46px;
}
.pricing-table.pricing-table-v2:hover{
	border-color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(206, 189, 224, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(206, 189, 224, 0.36);
}
.pricing-table.pricing-table-v2 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 30px;
}
.pricing-table.pricing-table-v2 .pricing-plan-cost .plan-price {
	font-size: 60px;
}
.pricing-table.pricing-table-v2 .pricing-plan-title {
	width: 50%;
}
.pricing-table.pricing-table-v2 .pricing-table-foot {
	width: 50%;
}
.pricing-table.pricing-table-v2 .pricing-plan-title {
	padding-top: 0;
}
.pricing-table.pricing-table-v2  .pricing-plan-cost {
	margin-top: 0;
	margin-bottom: 23px;
}
.pricing-table.pricing-table-v2 .pricing-plan-features {
	margin-bottom: 0;
}
.pricing-table.pricing-table-v2 {
	padding: 42px 49px 31px 49px;
	margin-bottom: 20px;
}
.pricing-table.pricing-table-v2 .plan-price {
	color: #034380;
}
.pricing-table.pricing-table-v2  .pricing-plan-title .pricing-table-icon .plan-title {
	margin-bottom: 30px;
}

/* 15. Blog and News Area */
.blog-and-news-area{
	background-size: contain;
	background-position: top center;
	background-attachment: scroll;
	background-repeat: no-repeat;
	overflow: hidden;
}
.blog-and-news-area .section-internal {
	position: relative;
	z-index: 1;
}
.blog-post-item {
	border-radius: 10px;
}
.blog-post-item {
	background: #fff;
	padding: 20px 20px 40px 20px;
	margin-bottom: 30px;
}
.blog-post-item .post-thumbnail img {
	width: 100%;
	border-radius: 12px;
}
.blog-post-item .post-thumbnail a {
	display: block;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item .post-thumbnail {
	position: relative;
}
.blog-post-item .post-thumbnail .post-tag {
	background-color: #F94D1B;
	display: inline-block;
	height: 30px;
	color: #fff;
	padding: 0 15px;
	border-radius: 7px;
	font-family: 'Open Sans';
	font-size: 15px;
	position: absolute;
	bottom: -15px;
	left: 15px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item:hover .post-thumbnail  .post-tag.bg-purple{
	background-color: #F94D1B;
}
.blog-post-item .post-thumbnail  .post-tag.bg-purple,
.blog-post-item:hover .post-thumbnail .post-tag {
	background-color: #8400FF;
}
.blog-post-item .post-thumbnail  .post-tag.bg-magenta {
	background-color: #FF00FF;
}
.blog-post-item:hover .post-thumbnail  .post-tag.bg-magenta {
	background-color: #F94D1B;
}
.blog-post-item .post-meta-wrapper ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	gap: 14px;
	-ms-flex-wrap: wrap;
		flex-wrap: wrap;
}
.blog-post-item .post-thumbnail a:hover {
	color: #fff;
}
.blog-post-item .post-meta-wrapper {
	margin-bottom: 8px;
}
.blog-post-item .post-meta-wrapper ul li a {
	font-size: 15px;
	color: #606060;
	font-family: 'CircularStdMedium';
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item .post-meta-wrapper ul li a:hover {
	color: #0040e5;
}
.blog-post-item .post-meta-wrapper ul li a i {
	margin-right: 7px;
}
.blog-post-item .post-title {
	font-size: 22px;
	line-height: 32px;
	margin-bottom: 26px;
}
.blog-post-item .post-title a {
	transition: 0.3s;
}
.blog-post-item .post-title a:hover{
	color: #0040E5;
}
.blog-post-item  .blog-post-entry-content {
	padding: 34px 15px 0px 15px;
}

/* Blog and News V2 (Home V5) */
.blog-and-news-area.blog-news-v2 .section-title h2{
	margin-bottom: 22px;
}
.blog-and-news-area.blog-news-v2 .section-title .section-title-description p {
	color: #fff;
}
.blog-and-news-area.blog-news-v2 .section-button-wrapper .filled-btn {
	border: 2px solid rgb(255 255 255 / 15%);
	color: #fff;
	box-shadow: none;
	background-color: transparent;
	padding: 11px 40px;
}
.blog-and-news-area.blog-news-v2 .section-button-wrapper .filled-btn:after {
	background-color: #034380;
}
.blog-and-news-area.blog-news-v2 .section-button-wrapper .filled-btn:hover {
	border-color: #034380;
	background-color: #034380;
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
}
.blog-and-news-area.blog-news-v2 .section-button-wrapper,
.blog-and-news-area.blog-news-v3 .section-button-wrapper {
	position: absolute;
	top: 10px;
	right: 0;
}
/* Blog Post Item V2 */
.blog-post-item.blog-post-item-v2 {
	padding: 0;
	height: 100%;
	border-radius: 0;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
			box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
	margin-left: 20px;
	padding: 20px 34px 28px 34px;
	margin-top: -40px;
	position: relative;
	background-color: #fff;
	z-index: 1;
	border-radius: 7px 0 7px 7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item.blog-post-item-v2 .post-thumbnail a {
	display: block;
}
.blog-post-item.blog-post-item-v2 .post-thumbnail a img {
	width: 100%;
	border-radius: 7px;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-title {
	font-size: 21px;
	margin-bottom: 15px;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-title a:hover,
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-meta-wrapper ul li a:hover {
	color: #e25c6a;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-meta-wrapper {
	margin-bottom: 8px;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-author a {
	display: inline-block;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-author .post-author-thumb {
	margin-right: 12px;
	max-width: 40px;
	min-width: 40px;
	height: 40px;
	border-radius: 50%;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-author .post-author-name {
	font-size: 18px;
	color: #87888b;
	font-family: 'CircularStdMedium';
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-author .post-author-thumb img {
	border-radius: 50%;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-meta-wrapper ul li a {
	text-transform: uppercase;
}
.blog-post-item.blog-post-item-v2 .blog-post-entry-content .post-meta-wrapper ul li a i {
	color: #e24c6a;
	margin-right: 12px;
	font-size: 14px;
}
.blog-post-item.blog-post-item-v2:hover .blog-post-entry-content {
	margin-left: 0;
	border-radius: 0px 0px 7px 7px;
}
.blog-post-item.blog-post-item-v2:hover .post-thumbnail img {
	-webkit-transform: scale(1.2);
		-ms-transform: scale(1.2);
			transform: scale(1.2);
}
.blog-post-item.blog-post-item-v2 .post-thumbnail {
	overflow: hidden;
	position: relative;
}
.blog-post-item.blog-post-item-v2 .post-thumbnail:before {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(255, 255, 255, .2);
	border-radius: 7px;
	transform: translate(-50%, -50%);
	opacity: 0;
	z-index: 10;
}
.blog-post-item.blog-post-item-v2:hover .post-thumbnail:before {
	-webkit-animation: circle .75s;
	animation: circle .75s;
}
.blog-post-item.blog-post-item-v2 .post-thumbnail img {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
/* Blog Post Item V4 (Home V5) */
.blog-post-item.blog-post-item-v4 .post-thumbnail .post-tag {
	border-radius: 15px;
	font-size: 17px;
}
.blog-post-item.blog-post-item-v4  .post-meta-wrapper ul li a {
	color: #7a7a7a;
}
.blog-post-item.blog-post-item-v4:hover .filled-btn:after {
	background-color: #034380;
	width: 100%;
}
.blog-post-item.blog-post-item-v4:hover .filled-btn {
	color: #fff;
}
.blog-post-item.color-reddish-orange .post-thumbnail .post-tag,
.blog-post-item.color-reddish-orange:hover .filled-btn:after  {
	background-color: #F94D1B;
}
.blog-post-item.color-reddish-orange .post-title a:hover, 
.blog-post-item.color-reddish-orange .post-meta-wrapper ul li a:hover {
	color: #f94d1b;
}
.blog-post-item.color-purple .post-thumbnail .post-tag,
.blog-post-item.color-purple:hover .filled-btn:after {
	background-color: #8400FF;
}
.blog-post-item.color-purple .post-title a:hover, 
.blog-post-item.color-purple .post-meta-wrapper ul li a:hover {
	color: #8400FF;
}
.blog-post-item.color-magenta .post-thumbnail .post-tag,
.blog-post-item.color-magenta:hover .filled-btn:after {
	background-color: #FF00FF;
}
.blog-post-item.color-magenta .post-title a:hover, 
.blog-post-item.color-magenta .post-meta-wrapper ul li a:hover {
	color: #FF00FF;
}

/* Blog Post Item v5 (Home V6) */
.blog-post-item.blog-post-item-v5 {
	padding: 0;
	border-radius: 7px;
}
.blog-post-item.blog-post-item-v5 .post-meta-wrapper ul li a {
	font-size: 17px;
	color: #034380;
	font-family: 'CircularStdBook';
}
.blog-post-item.blog-post-item-v5 .post-thumbnail img {
	border-radius: 7px 7px 0 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item.blog-post-item-v5:hover .post-thumbnail img {
	transform: scale(1.1);
}
.blog-post-item.blog-post-item-v5 .post-thumbnail {
	position: relative;
	overflow: hidden;
}
.blog-post-item.blog-post-item-v5 .post-thumbnail:before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	display: block;
	content: "";
	width: 0px;
	height: 0px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 0px;
}
.blog-post-item.blog-post-item-v5:hover .post-thumbnail:before {
	-webkit-animation-duration: 0.95s;
	animation-duration: 0.95s;
	-webkit-animation-timing-function: ease;
	animation-timing-function: ease;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: none;
	animation-fill-mode: none;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	-webkit-animation-name: circle;
	animation-name: circle;
}
.blog-post-item.blog-post-item-v5:hover {
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
}
.blog-post-item.blog-post-item-v5 .blog-post-entry-content {
	border: 2px solid rgb(51 119 255 / 10%);
	border-radius: 0 0 7px 7px;
	border-top: 0;
	padding: 30px 39px 38px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item.blog-post-item-v5:hover .blog-post-entry-content {
	border-color: #fff;
}
.blog-post-item.blog-post-item-v5 .post-title {
	line-height: 30px;
}
.blog-post-item.blog-post-item-v5 .post-title a:hover {
	color: #034380;
}
.blog-post-item.blog-post-item-v5 .post-meta-wrapper {
	margin-bottom: 4px;
}
.blog-post-item.blog-post-item-v5:hover .filled-btn {
	color: #fff;
}
.blog-post-item.blog-post-item-v5:hover .filled-btn:after {
	background-color: #034380;
	width: 100%;
}

/* 16. Features Area */

/* Single Iconic Box */
.single-iconic-box{
	margin-bottom: 40px;
}
.single-iconic-box .iconic-box-icon {
	height: 60px;
	width: 60px;
	text-align: center;
	line-height: 60px;
	background-image: linear-gradient( -38deg, rgb(246,89,17) 0%, rgb(246,89,17) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(246,89,17) 0%, rgb(246,89,17) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(246,89,17) 0%, rgb(246,89,17) 100%);
	
	-webkit-box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
	box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
	/* -webkit-box-shadow: none;
	box-shadow: none; */

	border-radius: 50%;
	font-size: 30px;
	color: #fff;
	margin-bottom: 27px;
}
.single-iconic-box .iconic-box-icon.unactive {
	background-image: none !important;
	background: #dee2e6 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}
.single-iconic-box  .iconic-box-icon.iconic-box-gradient-2 {
	background-image: linear-gradient( -38deg, rgb(255,146,0) 0%, rgb(246,73,103) 59%, rgb(161,51,0) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(255,146,0) 0%, rgb(246,73,103) 59%, rgb(161,51,0) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(255,146,0) 0%, rgb(246,73,103) 59%, rgb(161,51,0) 100%);
	-webkit-box-shadow: 0px 10px 60px 0px rgba(253, 132, 19, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(253, 132, 19, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-3 {
	background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	-webkit-box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-4 {
	background-image: linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	-webkit-box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-5 {
	background-image: linear-gradient( -38deg, rgb(31,93,251) 0%, rgb(16,165,207) 59%, rgb(0,237,162) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(31,93,251) 0%, rgb(16,165,207) 59%, rgb(0,237,162) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(31,93,251) 0%, rgb(16,165,207) 59%, rgb(0,237,162) 100%);
	-webkit-box-shadow: 0px 10px 60px 0px rgba(33, 113, 238, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(33, 113, 238, 0.28);
}
.single-iconic-box .iconic-box-icon.iconic-box-gradient-6 {
	background-image: linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	-webkit-box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
}
.single-iconic-box .iconic-box-body .iconic-box-title {
	color: #034380;
	font-size: 22px;
	margin-bottom: 7px;
	line-height: 30px;
}
.single-iconic-box .iconic-box-body .iconic-box-content {
	font-size: 17px;
	line-height: 25px;
	color: #6f667b;
	margin-bottom: 16px;
}
.single-iconic-box .iconic-box-body .iconic-box-content span {
	display: contents;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a {
	cursor: pointer;
	color: #6f667b;
	font-family: 'Open Sans';
	text-transform: capitalize;
	font-weight: 400;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	position: relative;
	z-index: 1;
	display: inline-block;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a i {
	padding-left: 8px;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a:after {
	position: absolute;
	content: "";
	height: 2px;
	width: 100%;
	background-color: #9C92A9;
	left: 0;
	bottom: -3px;
	z-index: -1;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a:hover, .single-iconic-box:hover .iconic-box-body .iconic-box-btn a {
	color: #034380;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a:hover:after, .single-iconic-box:hover .iconic-box-body .iconic-box-btn a:after {
	background-color: #034380;
}

.single-iconic-box .iconic-box-body .iconic-box-btn a.primary-link {
	color: #F65911;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a.primary-link:hover {
	color: #034380;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a.primary-link:after {
	background-color: #F65911;
}
.single-iconic-box .iconic-box-body .iconic-box-btn a.primary-link:hover:after {
	background-color: #034380;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover .iconic-box-icon {
	-webkit-box-shadow: none;
	box-shadow: none;
}


/* Iconic Box V2 */
.icon-boxes-wrapper.icon-boxes-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
		flex-wrap: wrap;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2 {
	width: 50%;
	border: 1px solid #ddd;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(1), .icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(3) {
	border-right: 0;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(1), .icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:nth-child(2) {
	border-bottom: 0;
}
.single-iconic-box.iconic-box-v2 {
	padding: 38px 35px;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}
.single-iconic-box.iconic-box-v2 .iconic-box-content {
	margin-bottom: 0;
}
.single-iconic-box.iconic-box-v2:hover {
	background: #fff;
	-webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
			box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2 {
	position: relative;
	z-index: 2;
	margin-bottom: 0;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:before {
	content: "";
	height: 100%;
	width: 100%;
	background-color: #fff;
	left: 0;
	top: 0;
	z-index: -1;
	position: absolute;
	border-radius: 10px;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	-webkit-box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
	box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
	visibility: hidden;
	opacity: 0;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	z-index: -2;
	background-color: #EF7A38;
	top: 0;
	left: 0;
	border-radius: 10px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
	opacity: 0;
	-webkit-transform: rotateY(-70deg);
	-ms-transform: rotateY(-70deg);
	transform: rotateY(-70deg);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover:before {
	opacity: 1;
	right: -5px;
	left: auto;
	top: 5px;
	visibility: visible;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover:after {
	opacity: 1;
	top: -5px;
	left: -5px;
	visibility: visible;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	transform: rotateY(0deg);
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2:hover {
	border-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:hover + .single-iconic-box.iconic-box-v2:nth-child(2) {
	border-left-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:hover ~ .single-iconic-box.iconic-box-v2:nth-child(3) {
	border-top-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:nth-child(2):hover ~ .single-iconic-box.iconic-box-v2:nth-child(3) {
	border-top-color: #E2DDE4;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:nth-child(2):hover ~ .single-iconic-box.iconic-box-v2:nth-child(4) {
	border-top-color: transparent;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box:nth-child(3):hover ~ .single-iconic-box.iconic-box-v2:nth-child(4) {
	border-left-color: transparent;
}

/* Iconic Box V3 */
.single-iconic-box.iconic-box-v3 .iconic-box-icon {
	height: auto;
	width: auto;
	background-image: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	text-align: left;
	max-width: 60px;
	margin-bottom: 33px;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-title {
	color: #003669;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body  .iconic-box-content {
	color: #6e727d;
	margin-bottom: 25px;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a:after {
	height: 15px;
	width: 15px;
	border-radius: 50px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	background-color: #1D79FB;
	z-index: -1;
	bottom: 0;
	left: 50%;
	margin-left: -7.5px;
	top: 50%;
	margin-top: -7.5px;
	opacity: 0;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a i {
	padding: 0;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a {
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
	border: 1px solid #1D79FB;
	border-radius: 50px;
	color: #1D79FB;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a {
	overflow: hidden;
	z-index: 1;
}
.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a:hover:after, .single-iconic-box.iconic-box-v3:hover .iconic-box-body .iconic-box-btn a:after {
	height: 50px;
	width: 50px;
	top: 0;
	left: 0;
	margin: 0;
	opacity: 1;
}

.single-iconic-box.iconic-box-v3 .iconic-box-body .iconic-box-btn a:hover,
.single-iconic-box.iconic-box-v3:hover .iconic-box-body .iconic-box-btn a {
	color: #fff;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1 .iconic-box-body .iconic-box-btn a {
	color: #19CB55;
	border: 1px solid rgb(25 203 85 / 25%);
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1:hover .iconic-box-body .iconic-box-btn a {
	color: #fff;
	border: 1px solid #19CB55;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1 .iconic-box-body .iconic-box-btn a:hover {
	border: 1px solid #19CB55;
	color: #fff;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-1 .iconic-box-body .iconic-box-btn a:hover:after, .single-iconic-box.iconic-box-v3.exclusive-iconic-box-1:hover .iconic-box-body .iconic-box-btn a:after {
	background-color: #19CB55;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-3 .iconic-box-body .iconic-box-btn a {
	border: 1px solid rgb(249 95 117 / 25%);
	color: #f95f75;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-3 .iconic-box-body .iconic-box-btn a:after {
	background-color: #f95f75;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-3 .iconic-box-body .iconic-box-btn a:hover, .single-iconic-box.iconic-box-v3.exclusive-iconic-box-3:hover .iconic-box-body .iconic-box-btn a {
	color: #fff;
	border: 1px solid #f95f75;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-4 .iconic-box-body .iconic-box-btn a {
	color: #ff8500;
	border: 1px solid rgb(255 133 0 / 25%);
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-4 .iconic-box-body .iconic-box-btn a:after {
	background-color: #ff8500;
}
.single-iconic-box.iconic-box-v3.exclusive-iconic-box-4 .iconic-box-body .iconic-box-btn a:hover, .single-iconic-box.iconic-box-v3.exclusive-iconic-box-4:hover .iconic-box-body .iconic-box-btn a {
	color: #fff;
	border: 1px solid #ff8500;
}

/* Iconic Box V4 */
.single-iconic-box.iconic-box-v4 {
	-webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
			box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
	border-radius: 10px;
	padding: 35px 28px;
}
.single-iconic-box.iconic-box-v4 .iconic-box-body .iconic-box-title {
	color: #003669;
}
.single-iconic-box.iconic-box-v4 .iconic-box-body .iconic-box-content {
	color: #6e727d;
}
.single-iconic-box.iconic-box-v4 .iconic-box-content {
	margin-bottom: 0;
}

/* Iconic Box V5 */
.single-iconic-box.iconic-box-v5 {
	margin-bottom: 60px;
}
.single-iconic-box.iconic-box-v5 .iconic-box-icon {
	background-image: none;
	box-shadow: none;
	background-color: transparent;
	height: auto;
	width: auto;
	text-align: left;
	margin-bottom: 25px;
}
.single-iconic-box.iconic-box-v5 .iconic-box-title {
	font-size: 20px;
	color: #1f1f1f;
}
.single-iconic-box.iconic-box-v5 .iconic-box-content {
	color: #7a7a7a;
	margin-bottom: 0;
}

/* Simple Icon Box */
.simple-icon-box-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.simple-single-icon-box h4 {
	font-size: 22px;
	line-height: 24px;
	margin-bottom: 6px;
}
.simple-single-icon-box p {
	color: #6e727d;
}
.simple-single-icon-box i {
	margin-bottom: 22px;
	font-size: 40px;
	color: #034380;
}
/* Simple Icon Box V2 */
.simple-single-icon-box.simple-single-icon-box-v2 i {
	margin-bottom: 13px;
}
.simple-single-icon-box.simple-single-icon-box-v2 h4 {
	font-size: 20px;
	line-height: 24px;
}

/* 17. Manage Data Area Iconic List */

/* Single Iconic List */
.single-iconic-list.iconic-list-bg {
	background-color: #fff;
	border-radius: 15px;
	-webkit-box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
			box-shadow: 0px 10px 60px 0px rgb(189 189 189 / 30%);
}
.single-iconic-list.iconic-list-padding {
	padding: 35px;
}
.single-iconic-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.single-iconic-list .iconic-list-icon {
	max-width: 30px;
	min-width: 30px;
	margin-right: 20px;
}
.single-iconic-list .iconic-list-icon i {
	background-color: #F4752D;
	font-size: 15px;
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 30px;
	border-radius: 50px;
	color: #fff;
}
.single-iconic-list .iconic-list-body .iconic-list-title {
	font-size: 22px;
	color: #034380;
	line-height: 30px;
	margin-bottom: 7px;
	text-transform: capitalize;
}
.single-iconic-list .iconic-list-body .iconic-list-content {
	font-size: 17px;
	color: #6f667b;
	line-height: 30px;
}
.iconic-list-wrapper .single-iconic-list:last-child {
	margin-bottom: 0;
	margin-top: 5px;
}

/* Iconic list V2 */
.single-iconic-list.iconic-list-v2 {
	background-color: #FFFFFF;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-bottom: 15px;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	padding: 20px  35px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.28);
}
.single-iconic-list.iconic-list-v2 .iconic-list-body .iconic-list-title {
	margin: 0;
	font-size: 20px;
}
.iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:nth-child(1), .iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:nth-child(4) {
	margin-left: 14px;
}
.iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:nth-child(2), .iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:nth-child(5) {
	margin-left: 30px;
}
.iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:nth-child(3), .iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:nth-child(6) {
	margin-left: 0;
}
.iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2 {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.iconic-list-wrapper.iconic-list-wrapper-v2 .single-iconic-list.iconic-list-v2:hover {
	margin-left: 0;
}

/* Why Choose Us Area (Iconic List) - (Home V5) */
.why-choose-us-area .single-iconic-list.iconic-list-v2 {
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
}
.why-choose-us-area .single-iconic-list.iconic-list-v2 .iconic-list-icon i {
	background-color: #034380;
}
.why-choose-us-area .single-iconic-list.iconic-list-v2 .iconic-list-body .iconic-list-title {
	color: #003669;
}

/* 18. Collaboration Area */
.collaboration-area .collaboration-content{
	padding-left: 100px;
	padding-right: 100px;
}
.collaboration-area .collaboration-content .section-title h2{
	margin-bottom: 60px;
}
.collaboration-area .collaboration-content .section-title .section-title-description{
	margin-bottom: 31px;
}
.collaboration-area .section-right-image {
	position: absolute;
	right: 30px;
	height: 100%;
	width: 35%;
	top: 0;
	z-index: 5;
}
.collaboration-area .section-right-image  img {
	-webkit-box-shadow: 0 10px 80px rgb(54 35 75 / 27%);
			box-shadow: 0 10px 80px rgb(54 35 75 / 27%);
	border-radius: 35px;
}
.section-right-image.section-image-circle-shape:after {
	position: absolute;
	content: "";
	height: 275px;
	width: 275px;
	background-color: #fff;
	top: -75px;
	right: -100px;
	z-index: -1;
	border-radius: 50%;
}

/* Collaboration Feature Preview */
.collaboration-area.collaboration-area-v1 .app-feature-preview-main img {
	width: 100%;
}
.collaboration-area.collaboration-area-v1 .app-feature-preview-main {
	padding: 0 70px;
}
.collaboration-area .app-preview-image {
	position: relative;
}
.collaboration-area.collaboration-area-v1 .app-preview-image .app-feature-preview-relative {
	position: absolute;
	top: -78px;
	left: 0;
	z-index: 1;
}
.collaboration-area.collaboration-area-v1 .app-preview-image .app-feature-preview-relative img,
.collaboration-area.collaboration-area-v1 .app-preview-image .app-feature-preview-absolute img {
	border-radius: 12px;
	-webkit-box-shadow: 0 10px 30px 0px rgb(89 89 89 / 18%);
	box-shadow: 0 10px 30px 0px rgb(89 89 89 / 18%);
}
.collaboration-area.collaboration-area-v1 .app-preview-image .app-feature-preview-absolute {
	position: absolute;
	right: 20px;
	bottom: -47px;
	z-index: 1;
}
.collaboration-area.collaboration-area-v1 .app-preview-image .app-feature-preview-absolute img {
	border-radius: 15px;
}
.collaboration-area.collaboration-area-v1 .app-preview-image .app-feature-preview-backward {
	position: absolute;
	height: 100%;
	top: -78px;
	width: 100%;
	text-align: center;
	z-index: -1;
}

.app-preview-image.oval-gradient{
	position: relative;
	z-index: 2;
}
.app-preview-image.oval-gradient:after{
	background-image: url(../img/app/oval-gradient.png);
	content: "";
	height: 700px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-attachment: scroll;
	background-position: center center;
	top: -220px;
	left: 0;
	z-index: -2;
	position: absolute;
}

/* Collaboration Area V2 */
.collaboration-area.collaboration-area-v1 .section-title .section-title-description {
	margin-bottom: 32px;
}
.collaboration-area.collaboration-area-v2 {
	overflow: hidden;
}
.collaboration-area.collaboration-area-v2 .app-preview-image.oval-gradient:after {
	top: -100px;
	width: 115%;
	left: -55px;
}
.collaboration-area.collaboration-area-v2 .section-title .section-title-description p:last-of-type {
	color: #003669;
}
.collaboration-area.collaboration-area-v2 .section-title .section-title-description {
	margin-bottom: 36px;
}

/* 19. UI Design Feature Area */
.design-feature-area .app-preview-image {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
}
.design-feature-area .app-preview-image.oval-gradient:after {
	top: -100px;
	background-size: 100% 100%;
	height: 760px;
	background-image: url(../img/app/ui-design-feature-oval-gradient.png);
}

.design-feature-area .app-preview-image .app-feature-preview-main img{
	border-radius: 18px;
	-webkit-box-shadow: 0 10px 30px 0px rgb(193 193 193 / 40%);
			box-shadow: 0 10px 30px 0px rgb(193 193 193 / 40%);
}
.design-feature-area .app-preview-image .app-feature-preview-absolute {
	margin-left: -100px;
}
.design-feature-area .app-preview-image .app-feature-preview-absolute img{
	border-radius: 21px;
	-webkit-box-shadow: 0 10px 30px 0px rgb(193 193 193 / 40%);
			box-shadow: 0 10px 30px 0px rgb(193 193 193 / 40%);
}

/* 20. Manage Data Feature Area */
.manage-data-area .section-title h2 {
	margin-bottom: 63px;
}
.manage-data-area .manage-data-image{
	position: relative;
	z-index: 1;
}
.manage-data-area .manage-data-image:after{
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	z-index: -1;
	top: 0;
	left: 0;
	background-size: contain;
	background-attachment: scroll;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../img/app/oval-gradient-2.png);
}

/* 21. Featured Video */
.featured-video-content {
	position: relative;
	z-index: 1;
	text-align: center;
	height: 650px;
	border-radius: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: scroll;
	background-position: top center;
}
.featured-video-content.video-overlay:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: #36234B;
	z-index: -1;
	border-radius: 10px;
	opacity: 0.3;
}
.play-video-btn{
	z-index: 1;
	height: 100%;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
}
.play-video-btn i {
	height: 80px;
	width: 80px;
	background-color: #36234B;
	color: #fff;
	line-height: 80px;
	border-radius: 50px;
}
.play-video-btn i.pulse-animated{
	position: relative;
	z-index: -1;
}
.play-video-btn i.pulse-animated:after {
	content: "";
	position: absolute;
	z-index: -1;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 110px;
	height: 110px;
	background-color: rgb(54 35 75 / 30%);
	border-radius: 50%;
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation: pulse-border 1500ms ease-out infinite;
	z-index: -1;
}
@-webkit-keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}
@keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
				transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

/* 22. About Us Area */
.about-us-area .section-title.section-title-ocean-blue h2 span:after {
	bottom: -5px;
}
.about-us-area .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-2 {
	right: 0;
}
.about-us-area .blob-main-image img {
	max-width: 100%;
}
.about-images .about-thumbnail {
	width: 100%;
	margin-bottom: 30px;
	border-radius: 12px;
}
.about-images .about-thumbnail.about-thumbnail-1 {
	margin-top: 60px;
}
.about-us-area .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-1 {
	left: 9%;
	top: 5%;
}
.about-us-area .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-2 {
	right: 13%;
	top: 17%;
}
.about-us-area .blob-image-wrapper .blob-shape-wrapper .blob-shape.blob-shape-3 {
	left: 25%;
	bottom: -6%;
}
.about-us-area .about-us-content .section-title h2{
	margin-bottom: 20px;
}
.about-us-area .section-title .section-title-description {
	margin-bottom: 36px;
}

/* Why Choose Us Area (Home V5) */
.why-choose-us-area .section-title h2 {
	margin-bottom: 20px;
}

/* 23. Team Skills Area */
.team-skills-area .team-details-images {
	position: relative;
	padding-right: 130px;
}
.team-skills-area .team-details-images img {
	border-radius: 20px;
	width: 100%;
}
.team-skills-area .team-details-images .team-details-thumbnail.team-details-thumbnail-secondary {
	position: absolute;
	right: 50px;
	bottom: -94px;
	width: 50%;
}
.team-skills-area .team-details-thumbnail.team-details-thumbnail-main {
	position: relative;
	overflow: hidden;
}
.team-skills-area .team-details-thumbnail.team-details-thumbnail-main:before {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, .7)), to(rgba(255, 255, 255, .3)));
	background: -o-linear-gradient(left, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .3) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .3) 100%);
	-webkit-transform: skewX(25deg);
		-ms-transform: skewX(25deg);
			transform: skewX(25deg);
	z-index: 2;
}
.team-skills-area .team-details-thumbnail.team-details-thumbnail-main:hover:before {
	-webkit-animation: shine 1.5s;
	animation: shine 1.5s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

/* Team Skills Bar */
.skill-list .single-bar {
	margin-bottom: 32px;
}
.skill-list .single-bar .progress-title h5 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
	color: #003669;
	font-size: 18px;
	text-transform: capitalize;
	line-height: 30px;
	margin-bottom: 10px;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
}
.skill-list .single-bar .progress .progress-bar {
	height: 3px;
	border-radius: 0px;
	background-color: #4B83FC;
}
.skill-list .single-bar .progress {
	height: auto;
	background: transparent;
}
.skill-list .single-bar:last-of-type {
	margin-bottom: 0;
}
.skill-list .single-bar .progress .progress-bar.bg-royal-blue {
	background-color: #034380;
}
.skill-list .single-bar .progress .progress-bar.bg-burning-orange {
	background-color: #F65911;
}
.skill-list .single-bar .progress .progress-bar.bg-magenta {
	background-color: #FF00FC;
}
.skill-list .single-bar .progress .progress-bar.bg-blue {
	background-color: #00B9EE;
}

/* 24. Contact Page */

/* Contact Info Box */
.info-iconic-box {
	-webkit-box-shadow: 0px 0px 30px 0px rgba(234, 234, 234, 0.5);
			box-shadow: 0px 0px 30px 0px rgba(234, 234, 234, 0.5);
	margin-bottom: 75px;
	padding: 30px 35px;
	text-align: center;
	border-radius: 5px;
}
.info-iconic-boxes .info-iconic-box:last-child {
	margin-bottom: 0;
}
.info-iconic-box .info-icon i {
	font-size: 40px;
	line-height: 90px;
	color: #fff;
}
.info-iconic-box .info-icon {
	height: 90px;
	width: 90px;
	text-align: center;
	margin: 0 auto;
	border-radius: 50px;
	background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	-webkit-box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
			box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
	margin-bottom: 35px;
	margin-top: -75px;
}
.info-iconic-box .info-body h5 {
	font-size: 24px;
	margin-bottom: 10px;
	text-transform: capitalize;
}
.info-iconic-box .info-body  p {
	color: #6e727d;
	font-family: 'CircularStdMedium';
}
.info-iconic-box .info-body a {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.info-iconic-box .info-body a:hover {
	color: #003669;
}
.info-iconic-box .info-icon.info-icon-gradient-1 {
	background-image: linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(243,57,70) 0%, rgb(200,29,154) 59%, rgb(156,0,237) 100%);
	box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
}
.info-iconic-box .info-icon.info-icon-gradient-2 {
	background-image: linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(70,57,243) 0%, rgb(113,29,240) 59%, rgb(156,0,237) 100%);
	box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
}
.info-iconic-box .info-icon.info-icon-gradient-3 {
	background-image: linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	background-image: -webkit-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	background-image: -ms-linear-gradient( -38deg, rgb(253,186,26) 0%, rgb(245,93,100) 59%, rgb(237,0,173) 100%);
	box-shadow: 0px 10px 60px 0px rgba(243,124,33, 0.28);
}

/* Contact Box (Home V5) */
.single-contact-box h4 {
	font-size: 24px;
	color: #003669;
	line-height: 32px;
	margin-bottom: 15px;
}
.single-contact-box {
	background-color: #fff;
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
	padding: 40px 56px 43px 56px;
	border-radius: 7px;
	margin-bottom: 25px;
}
.single-contact-box h4 span {
	color: #034380;
}
.single-contact-box:hover .filled-btn:after,
.single-contact-box.single-contact-box-active .filled-btn:after {
	width: 100%;
}
.single-contact-box:hover .filled-btn,
.single-contact-box.single-contact-box-active .filled-btn{
	color: #fff;
	border-color: #003669;
	background-color: #003669;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
	box-shadow: none;
}
.single-contact-box.single-contact-box-active .filled-btn:hover {
	background-color: #034380;
	border-color: #034380;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}
.single-contact-box.single-contact-box-active:hover .filled-btn {
	background-color: transparent;
	border-color: rgb(71 59 240 / 10%);
	color: #003669;
}
.single-contact-box.single-contact-box-active:hover .filled-btn:after {
	width: 0;
}

/* Single Info Box (Home V5) */
.our-services.our-services-v2 .section-title h2,
.company-statistic-area .section-title h2 {
	margin-bottom: 22px;
}
.single-info-box {
	margin-bottom: 30px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-info-box:hover {
	-webkit-box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
			box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
}
.single-info-box .infobox-thumbnail a {
	display: block;
}
.single-info-box .infobox-thumbnail img {
	border-radius: 7px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	width: 100%;
}
.single-info-box  .infobox-body {
	background-color: #fff;
	padding: 21px 26px 28px;
}
.single-info-box .infobox-body .infobox-title {
	font-size: 18px;
	color: #003669;
	margin-bottom: 4px;
}
.single-info-box .infobox-body .infobox-title a:hover {
	color: #034380;
}
.single-info-box .infobox-body .infobox-title a {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-info-box .infobox-body .infobox-excerpt {
	color: #7a7a7a;
	font-size: 17px;
	margin-bottom: 4px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-info-box .infobox-body .infobox-btn a {
	color: #7a7a7a;
	font-size: 15px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	display: inline-block;
}
.single-info-box .infobox-body .infobox-btn a i {
	margin-left: 10px;
}
.single-info-box .infobox-body .infobox-btn a {
	position: relative;
}
.single-info-box .infobox-body .infobox-btn a:after {
	position: absolute;
	content: "";
	height: 2px;
	width: 0;
	background-color: #7a7a7a;
	left: 0;
	bottom: 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.single-info-box:hover .infobox-body .infobox-btn a:after {
	width: 100%;
	background-color: #034380;
}
.single-info-box:hover .infobox-body .infobox-btn a {
	color: #034380;
}
.single-info-box:hover .infobox-body .infobox-excerpt {
	height: auto;
}
.single-info-box .infobox-thumbnail {
	position: relative;
	overflow: hidden;
}
.single-info-box .infobox-thumbnail:before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	display: block;
	content: "";
	width: 0px;
	height: 0px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 0px;
}
.single-info-box:hover .infobox-thumbnail:before {
	-webkit-animation-duration: 0.95s;
	animation-duration: 0.95s;
	-webkit-animation-timing-function: ease;
	animation-timing-function: ease;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: none;
	animation-fill-mode: none;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	-webkit-animation-name: circle;
	animation-name: circle;
}
.single-info-box:hover .infobox-thumbnail img {
	transform: scale(1.2);
}

/* Our Services Area V3 (Home V6) */
.our-services.our-services-v3 .section-title h2 {
	margin-bottom: 11px;
}
.our-services.our-services-v3  .section-title .section-title-description p {
	color: #7a7a7a;
}
/* Services Law Area (Home V6) */
.services-law-images {
	position: relative;
}
.services-law-images .services-law-image-main {
	margin-top: 50px;
	margin-bottom: 50px;
}
.services-law-images .services-law-image-main img {
	border-radius: 7px;
	width: 100%;
}
.services-law-images .services-law-image-relative, 
.services-law-images .services-law-image-absolute {
	position: absolute;
}
.services-law-images .services-law-image-relative {
	right: 0px;
	top: -50px;
}
.services-law-images .services-law-image-absolute {
	left: 0;
	bottom: -50px;
	-webkit-box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
	box-shadow: 0px 10px 60px 0px rgb(51 119 255 / 15%);
}
/* Contact Form Area */
.contact-respond .form-control:focus {
	outline: 0;
	border-color: #EFF0F2;
	-webkit-box-shadow: none;
			box-shadow: none;
}
.contact-respond .form-control {
	border: 1px solid #EFF0F2;
	height: 60px;
	padding: 0 30px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	font-size: 18px;
	color: #1b2336;
	background-color: #F2F1FE;
	border-radius: 7px;
	border: 2px solid #F2F1FE;
}
.contact-respond .input-group {
	margin-bottom: 30px;
}
.contact-respond .input-group:last-child{
	margin-bottom: 0;
}
.contact-respond textarea.form-control {
	padding: 15px 30px;
	height: 250px;
}
.contact-respond .input-group label {
	position: absolute;
	z-index: 1;
	height: 100%;
	display: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
		-ms-flex-direction: column;
			flex-direction: column;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	padding: 0 30px;
	font-size: 18px;
	color: #003669;
	font-weight: 500;
}
.contact-respond input::-webkit-input-placeholder, .contact-respond textarea::-webkit-input-placeholder{
	font-size: 16px;
}
.contact-respond input::placeholder, .contact-respond textarea::placeholder{
	font-size: 16px;
}
.contact-respond input:-ms-input-placeholder, .contact-respond textarea:-ms-input-placeholder{
	font-size: 16px;
}
.contact-respond input::-ms-input-placeholder, .contact-respond textarea::-ms-input-placeholder{
	font-size: 16px;
}
.contact-respond input::placeholder,
.contact-respond textarea::placeholder{
	font-size: 16px;
}
.contact-respond input::-webkit-input-placeholder, .contact-respond textarea::-webkit-input-placeholder{
	font-weight: 400;
	color: #6e727d;
}
.contact-respond input::placeholder, .contact-respond textarea::placeholder{
	font-weight: 400;
	color: #6e727d;
}
.contact-respond input:-ms-input-placeholder, .contact-respond textarea:-ms-input-placeholder{
	font-weight: 400;
	color: #6e727d;
}
.contact-respond input::-ms-input-placeholder, .contact-respond textarea::-ms-input-placeholder{
	font-weight: 400;
	color: #6e727d;
}
.contact-respond input::placeholder,
.contact-respond textarea::placeholder{
	font-weight: 400;
	color: #6e727d;
}
.contact-respond .input-group .form-control:focus {
	padding-left: 115px;
	border-color: #034380;
	background-color: transparent;
}
.contact-respond .form-control:focus + label {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	z-index: 5;
}
.contact-respond textarea.form-control:focus + label{
	-webkit-box-pack: start;
		-ms-flex-pack: start;
			justify-content: flex-start;
	padding-top: 15px;
}
.contact-respond input:focus::-webkit-input-placeholder{
	color: transparent;
}
.contact-respond input:focus::placeholder{
	color: transparent;
}
.contact-respond input:focus:-ms-input-placeholder{
	color: transparent;
}
.contact-respond input:focus::-ms-input-placeholder{
	color: transparent;
}
.contact-respond input:focus::placeholder{
	color: transparent;
}
.contact-respond .input-group.form-textarea label {
	display: none;
}
.contact-respond .input-group.form-textarea .form-control:focus {
	padding-left: 30px;
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}
.contact-respond .subject-dropdown {
	width: 100%;
}
.contact-respond .subject-dropdown .nice-select.open:after {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	top: 13px;
	content: "\f106";
}
.contact-respond .subject-dropdown .nice-select:after {
	color: #034380;
}
.contact-respond .nice-select {
	width: 100%;
	height: 60px;
	padding: 0 30px;
	float: none;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 30px;
	background-color: #F4F7FA;
	border: 0;
	font-weight: 400;
	color: #6e727d;
	font-size: 16px;
}
.contact-respond .nice-select ul.list {
	width: 100%;
	border-radius: 7px;
	margin-top: 5px;
	box-shadow: 0 0 0 1px #f4f7fa;
}
.contact-respond .nice-select ul.list li {
	font-size: 16px;
	color: #1b2336;
	font-weight: 500;
}
.contact-respond .nice-select ul.list li.selected.focus, .contact-respond .nice-select ul.list li:hover {
	background: #f4f7fa;
}
.contact-respond .nice-select ul.list li {
	padding: 5px 30px;
}

/* Contact Form Area V2 (Home V5) */
.contact-form-area.contact-form-area-v2 .contact-respond .input-group {
	margin-bottom: 15px;
}
.contact-form-area.contact-form-area-v2 .contact-respond .input-group:last-child {
	margin: 0;
}
.contact-form-area.contact-form-area-v2 .contact-respond .form-control {
	background-color: #F4F7FA;
	border-color: #F4F7FA;
}
.contact-form-area.contact-form-area-v2 .contact-respond textarea.form-control {
	height: 165px;
}
.contact-form-area.contact-form-area-v2 .contact-respond {
	padding: 58px 45px;
	border: 2px solid #EFEFF0;
	border-radius: 7px;
}


/* Contact Page Maps */
.map-box iframe {
	height: 670px;
	width: 100%;
	border: 0;
}

/* 25. 404 Not Found Area */
.error-page-content {
	margin-top: 80px;
}
.error-page-content h2 {
	margin-bottom: 20px;
	font-size: 85px;
	line-height: 1;
}
.error-page-content p {
	margin-bottom: 33px;
	font-size: 30px;
	font-family: 'CircularStdMedium';
	line-height: 40px;
	color: #003669;
}

/* 26. Coming Soon Page */
.coming-soon-content .newsletter-form .form-group {
	display: block;
	position: relative;
}
.coming-soon-content .newsletter-form .form-group button {
	height: 45px;
	width: 45px;
	line-height: 45px;
	text-align: center;
	padding: 0;
	position: absolute;
	right: 0;
	top: 10px;
	right: 10px;
}
.coming-soon-content .newsletter-form .form-group button i {
	margin-left: 0;
}
.coming-soon-content .newsletter-form .form-group input[type="email"] {
	-webkit-box-shadow: 0px 10px 30px 0px rgba(188, 183, 255, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(188, 183, 255, 0.36);
	border-radius: 33px;
	width: 100%;
	font-size: 17px;
	padding: 0 35px;
	height: 65px;
	padding-right: 70px;
}
.coming-soon-content .newsletter-form .form-group input[type="email"]::-webkit-input-placeholder {
	font-size: 17px;
	color: #777777;
}
.coming-soon-content .newsletter-form .form-group input[type="email"]::placeholder {
	font-size: 17px;
	color: #777777;
}
.coming-soon-content .newsletter-form .form-group input[type="email"]:-ms-input-placeholder {
	font-size: 17px;
	color: #777777;
}
.coming-soon-content .newsletter-form .form-group input[type="email"]::-ms-input-placeholder {
	font-size: 17px;
	color: #777777;
}
.coming-soon-content .newsletter-form .form-group input[type="email"]::placeholder {
	font-size: 17px;
	color: #777777;
}
.coming-soon-content h2 {
	margin-bottom: 24px;
	font-size: 92px;
	line-height: 95px;
}
.coming-soon-area {
	padding-top: 69px;
	padding-bottom: 140px;
	background-color: #F4F7FA;
}
.coming-soon-content .newsletter-form .newsletter-notice {
	color: #003669;
	font-size: 20px;
	font-family: 'CircularStdMedium';
	padding-top: 15px;
}

/* 27. Service Details Page */
.service-details-content h1, 
.service-details-content h2{
	margin-bottom: 25px;
}
.service-details-content h3, 
.service-details-content h4, 
.service-details-content h5, 
.service-details-content h6 {
	margin-bottom: 12px;
}
.service-details-content h2 {
	font-size: 42px;
}
.service-details-content p {
	color: #6e727d;
	line-height: 32px;
}
.service-details-content .image-block {
	margin-bottom: 35px;
}
.service-details-content h3 {
	font-size: 30px;
	line-height: 32px;
} 
.service-details-content .customer-faq{
	margin-bottom: 60px;
} 
.service-details-content p {
	margin-bottom: 28px;
}
.service-details-content .icon-boxes-wrapper{
	margin-top: 44px;
	margin-bottom: 30px;
}
.service-details-content h5.card-header {
	margin-bottom: 0;
}
.service-details-content .card-body p {
	margin-bottom: 0;
}
.service-details-content blockquote {
	font-family: 'CircularStdMedium';
	font-style: italic;
	margin-bottom: 30px;
}
.section-accordion.section-accordion-v2 .faq-accordion-title {
	margin-bottom: 28px;
}

/* 28. Blog Standard Page */
.blog-list-wrapper .blog-list-content,
.blog-details-wrapper .blog-details-content{
	padding-right: 35px;
}
.blog-details-wrapper .blog-details-content .blog-post-item .post-thumbnail img {
	border-radius: 0;
}
.blog-post-item.blog-post-item-v3 {
	padding: 0;
	margin-bottom: 60px;
}
.blog-post-item.blog-post-item-v3 .post-thumbnail {
	overflow: hidden;
}
.blog-post-item.blog-post-item-v3 .post-thumbnail img {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	border-radius: 0;
}
.blog-post-item.blog-post-item-v3:hover  .post-thumbnail img {
	-webkit-transform: scale(1.2);
		-ms-transform: scale(1.2);
			transform: scale(1.2);
}
.blog-post-item.blog-post-item-v3 .post-meta-wrapper {
	margin-bottom: 14px;
}
.blog-post-item.blog-post-item-v3 .blog-post-entry-content {
	padding: 32px 0px 0px 0px;
}
.blog-post-item.blog-post-item-v3 .blog-post-entry-content .post-title {
	font-size: 30px;
	line-height: 40px;
	margin-bottom: 13px;
}
.blog-post-item.blog-post-item-v3 .blog-post-entry-content .post-excerpt {
	color: #6e727d;
	margin-bottom: 30px;
}
.blog-post-item.blog-post-item-v3 .post-meta-wrapper ul li a {
	font-size: 17px;
	color: #6e727d;
}
.blog-post-item.blog-post-item-v3 .post-meta-wrapper ul li a i{
	margin-right: 10px;
}
.blog-post-item.blog-post-item-v3 .post-meta-wrapper ul li {
	margin-right: 10px;
}
.blog-post-item.blog-post-item-v3 .post-meta-wrapper ul li:last-child {
	margin-right: 0;
}
.blog-post-item.blog-post-item-v3 .blog-post-entry-content .post-title a:hover, .blog-post-item.blog-post-item-v3 .post-meta-wrapper ul li a:hover {
	color: #034380;
}
.blog-post-item.blog-post-item-v3 .blog-post-entry-content .post-title a, .blog-post-item.blog-post-item-v3 .post-meta-wrapper ul li a {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item.blog-post-item-v3.post-offwhite {
	background-color: #F3F3F4;
}
.blog-post-item.blog-post-item-v3.post-offwhite .blog-post-entry-content {
	padding-top: 0;
	padding-bottom: 0;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute {
	position: relative;
	z-index: 1;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute {
	overflow: hidden;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .post-thumbnail .post-thumbnail-bg {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute:hover .post-thumbnail .post-thumbnail-bg {
	-webkit-transform: scale(1.2);
		-ms-transform: scale(1.2);
			transform: scale(1.2);
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute {
	position: relative;
	z-index: 1;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .post-thumbnail .post-thumbnail-bg {
	background-size: cover;
	background-position: center center;
	background-attachment: scroll;
	background-repeat: no-repeat;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: -1;
}
.blog-post-item.blog-post-item-v3.post-offwhite, .blog-post-item.blog-post-item-v3.post-type-featured-absolute .blog-post-entry-content {
	padding: 35px 50px 39px 50px;
}
.blog-post-item.blog-post-item-v3.post-offwhite .blog-post-entry-content .post-excerpt {
	margin-bottom: 25px;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .post-meta-wrapper ul li a {
	color: #fefeff;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .post-title {
	color: #fefeff;
	margin-bottom: 28px;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .post-title a:hover {
	color: #fff;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute:after {
	position: absolute;
	content: "";
	height: 100%;
	width: 100%;
	background: #003669;
	top: 0;
	left: 0;
	opacity: 0.9;
	z-index: -1;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .post-thumbnail {
	position: static;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .filled-btn{
	-webkit-box-shadow: none;
			box-shadow: none;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .filled-btn:hover {
	background-color: #FFFFFF;
	-webkit-transition-delay: 0.1s;
		 -o-transition-delay: 0.1s;
			transition-delay: 0.1s;
	color: #003669;
}
.blog-post-item.blog-post-item-v3.post-type-featured-absolute .filled-btn:hover:after, .blog-post-item.blog-post-item-v3.post-type-featured-absolute .filled-btn:after {
	background-color: #FFFFFF;
}
.blog-post-item.blog-post-item-v3.post-offwhite .filled-btn:after {
	background-color: #034380;
}
.blog-post-item.blog-post-item-v3.post-offwhite .filled-btn {
	background-color: #FFFFFF;
	color: #003669;
	-webkit-box-shadow: none;
			box-shadow: none;
}
.blog-post-item.blog-post-item-v3.post-offwhite .filled-btn:hover {
	color: #fff;
}
.blog-list-wrapper .blog-list-content .blog-post-item:last-child {
	margin-bottom: 0;
}

/* 29. Blog Details Page */
.blog-details-wrapper .blog-details-content .blog-post-item {
	padding: 0;
	margin-bottom: 70px;
}
.blog-details-wrapper .blog-post-item .entry-content h3,
.blog-details-wrapper .blog-post-item .entry-content h4{
	margin-bottom: 13px;
}
.blog-details-wrapper .blog-post-item .entry-content h3 {
	font-size: 30px;
	line-height: 40px;
}
.blog-details-wrapper .blog-details-content .entry-content {
	padding-top: 33px;
}
.blog-details-wrapper .blog-details-content .entry-content .post-meta-wrapper {
	margin-bottom: 13px;
}
.blog-details-wrapper .blog-details-content .blog-post-item .post-meta-wrapper ul li a{
	font-size: 17px;
}
.blog-details-wrapper .blog-post-item .entry-content h4{
	font-size: 30px;
	line-height: 40px;
}
.blog-details-wrapper .blog-post-item .entry-content p {
	margin-bottom: 39px;
	color: #6e727d;
	font-size: 17px;
	line-height: 32px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote {
	margin-bottom: 40px;
	background-color: #F3F3F4;
	padding: 44px 50px 45px 50px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote h4{
	margin-bottom: 10px;
	color: #003669;
	font-weight: 700;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote h4 {
	font-size: 24px;
	line-height: 35px;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote h5 {
	font-weight: 700;
	font-size: 18px;
	position: relative;
	padding-left: 60px;
	margin-bottom: 0;
}
.blog-details-wrapper .blog-post-item .entry-content blockquote h5:after {
	position: absolute;
	content: "";
	height: 3px;
	width: 40px;
	background: #034380;
	top: 13px;
	left: 0;
}
.blog-details-wrapper .post-author-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
}
.blog-details-wrapper .post-author-box .author-box-content h4 {
	font-size: 22px;
	line-height: 1;
}
.blog-details-wrapper .post-author-box .author-box-content p {
	color: #6e727d;
	font-size: 17px;
	line-height: 32px;
}
.blog-details-wrapper .post-author-box .author-box-content h4 {
	margin-bottom: 12px;
}
.blog-details-wrapper .post-author-box .author-box-content p {
	margin-bottom: 5px;
}
.blog-details-wrapper .post-author-box {
	background-color: #F3F3F4;
	padding: 37px 50px 30px 50px;
	margin-bottom: 70px;
}
.blog-details-wrapper .post-author-box .post-author-thumbnail {
	max-width: 160px;
	min-width: 160px;
	border-radius: 50%;
	margin-right: 35px;
}
.blog-details-wrapper .post-author-box .post-author-thumbnail img {
	border-radius: 50%;
}

.blog-details-wrapper .posts-pagination {
	margin-bottom: 50px;
}

.social-links li {
	display: inline-block;
}
.social-links li a {
	display: block;
	color: #415674;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.social-links li {
	margin-right: 10px;
}
.social-links ul li:last-of-type {
	margin: 0;
}
.social-links li a:hover {
	color: #4b83fc;
}
.blog-details-wrapper .post-share .post-tag-cloud {
	margin-bottom: 10px;
}
.blog-details-wrapper .post-share .post-tag-cloud ul li {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}
.blog-details-wrapper .post-share .post-tag-cloud ul li a {
	font-size: 15px;
	color: #003669;
	display: inline-block;
	background-color: #F3F3F4;
	padding: 0px 11px;
	border-radius: 5px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	font-family: 'CircularStdMedium';
}
.blog-details-wrapper .post-share .post-tag-cloud ul li a:hover {
	background-color: #034380;
	color: #fff;
}
.blog-details-wrapper .social-links{
	padding-top: 0;
}
.blog-details-wrapper .post-share .item-heading {
	color: #003669;
	font-size: 18px;
	font-family: 'Open Sans';
}
.blog-details-wrapper .post-share ul li.item-heading,
.blog-details-wrapper .social-links ul li.item-heading {
	width: 130px;
}
.blog-details-wrapper .blog-details-content .social-links ul li a i {
	height: auto;
	width: auto;
	background: transparent;
	color: #6e727d;
	font-size: 16px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-details-wrapper .blog-details-content .social-links ul li a:hover i {
	color: #034380;
}
.blog-details-wrapper .blog-details-content .social-links ul li {
	margin-right: 15px;
}

/* Blog Details Comments Area */
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 42px;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-avatar {
	min-width: 100px;
	max-width: 100px;
	height: 100px;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment  .comment-wrap {
	margin-left: 30px;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-wrap .comment-author-content .author-name {
	color: #003669;
	font-size: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
		-ms-flex-pack: justify;
			justify-content: space-between;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	margin-bottom: 5px;
	font-weight: 700;
	font-family: 'Open Sans';
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-wrap .comment-author-content .author-name .date {
	font-size: 17px;
	color: #003669;
	font-family: 'CircularStdMedium';
	font-weight: 500;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-wrap .comment-author-content p {
	margin-bottom: 5px;
	font-size: 17px;
	color: #6e727d;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-wrap .comment-author-content .reply {
	color: #415674;
	font-size: 17px;
	text-transform: uppercase;
	font-family: 'Open Sans';
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-wrap .comment-author-content .reply:hover{
	color: #034380;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment .comment-wrap .comment-author-content .reply:after {
	content: "\f178";
	display: inline-block;
	font-family: "Font Awesome 5 Pro";
	padding-left: 10px;
}
.blog-details-wrapper .blog-details-content .comments-area ul.comments-list .comment:nth-child(even) {
	padding-left: 60px;
}
.blog-details-wrapper .blog-details-content .comments-area .comments-title {
	margin-bottom: 40px;
	font-size: 24px;
	line-height: 32px;
}

/* Blog Details Comments Form */
.blog-details-wrapper .blog-details-content .comments-respond{
	margin-top: 70px;
	padding-top: 75px;
	border-top: 1px solid #E7E8EA;
}
.blog-details-wrapper .blog-details-content .comments-respond .comments-heading {
	font-size: 24px;
	margin-bottom: 32px;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control,
.blog-details-wrapper .blog-details-content .comments-respond textarea {
	background-color: #fff;
	border: 2px solid #E7E8EA;
	border-radius: 5px;
	height: 65px;
	padding: 20px 30px;
	font-size: 17px;
	font-weight: 600;
	color: #003669;
	padding-right: 45px;
	font-weight: 700;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control:focus,
.blog-details-wrapper .blog-details-content .comments-respond textarea:focus{
	border-color: #034380;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control::-webkit-input-placeholder, .blog-details-wrapper .blog-details-content .comments-respond textarea::-webkit-input-placeholder{
	font-size: 17px;
	color: #6e727d;
	font-weight: 400;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control::placeholder, .blog-details-wrapper .blog-details-content .comments-respond textarea::placeholder{
	font-size: 17px;
	color: #6e727d;
	font-weight: 400;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control:-ms-input-placeholder, .blog-details-wrapper .blog-details-content .comments-respond textarea:-ms-input-placeholder{
	font-size: 17px;
	color: #6e727d;
	font-weight: 400;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control::-ms-input-placeholder, .blog-details-wrapper .blog-details-content .comments-respond textarea::-ms-input-placeholder{
	font-size: 17px;
	color: #6e727d;
	font-weight: 400;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-control::placeholder,
.blog-details-wrapper .blog-details-content .comments-respond textarea::placeholder{
	font-size: 17px;
	color: #6e727d;
	font-weight: 400;
}
.blog-details-wrapper .blog-details-content .comments-respond textarea {
	min-height: 140px;
	padding: 15px 30px;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-group {
	position: relative;
	margin-bottom: 20px;
}
.blog-details-wrapper .blog-details-content .comments-respond .form-group i {
	color: #034380;
	font-size: 17px;
	position: absolute;
	right: 20px;
	top: 25px;
}
.blog-details-wrapper .blog-details-content .comments-respond button.filled-btn{
	margin-top: 10px;
}

/* Blog Posts Sidebar */
.posts-sidebar .widget {
	margin-bottom: 55px;
}
.posts-sidebar .widget:last-child {
	margin-bottom: 0;
}
.posts-sidebar .widget .sidebar-widget-title {
	border-bottom: 1px solid #E6E6E6;
	padding-bottom: 15px;
	font-size: 24px;
	line-height: 32px;
	position: relative;
	margin-bottom: 25px;
}
.posts-sidebar .widget .sidebar-widget-title:after {
	position: absolute;
	content: "";
	height: 2px;
	width: 60px;
	background-color: #034380;
	left: 0;
	bottom: -2px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.posts-sidebar .widget .sidebar-widget-title:hover:after {
	-webkit-transform: translate(15px, 0px);
		-ms-transform: translate(15px, 0px);
			transform: translate(15px, 0px);
}
.posts-sidebar  .widget.search-widget {
	background-color: #034380;
	padding: 23px 30px 13px 30px;
}
.posts-sidebar .widget.search-widget h4 {
	color: #fff;
	margin-bottom: 15px;
	text-transform: capitalize;
}
.posts-sidebar .widget.search-widget input {
	height: 60px;
	border-radius: 30px;
	border: 0;
	background-color: #F4F4F5;
	font-size: 18px;
	padding: 0 30px;
	line-height: 1;
	padding-right: 65px;
	color: #003669;
}
.posts-sidebar .widget.search-widget button {
	height: 45px;
	width: 45px;
	border-radius: 50px;
	position: absolute;
	top: 8px;
	right: 8px;
	background: #034380;
	color: #fff;
}
.posts-sidebar .widget.search-widget form {
	position: relative;
}
.posts-sidebar  .widget.category-widget {
	background-color: #F4F7FA;
	padding: 32px 35px 30px 35px;
}
.posts-sidebar .widget.category-widget .category-link li {
	margin-bottom: 10px;
}
.posts-sidebar .widget.category-widget .category-link li a {
	color: #003669;
	font-size: 17px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	text-transform: capitalize;
	display: block;
	position: relative;
}
.posts-sidebar .widget.category-widget .category-link li a:after {
	display: inline-block;
	position: absolute;
	right: 0;
	content: "\f105";
	font-family: "Font Awesome 5 Pro";
	font-weight: 400;
}
.posts-sidebar .widget.category-widget .category-link li:last-child {
	margin-bottom: 0;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item {
	margin-bottom: 13px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-thumbnail {
	min-width: 75px;
	max-width: 75px;
	margin-right: 20px;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-thumbnail img {
	border-radius: 7px;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-item-content .popular-posts-title {
	font-size: 17px;
	line-height: 25px;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-item-content .popular-posts-title a:hover {
	color: #034380;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-item-content .posts-date {
	color: #6e727d;
	font-size: 16px;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-item-content .posts-date i {
	padding-right: 7px;
}
.posts-sidebar .widget.latest-post-widget .popular-posts-item:last-child {
	margin-bottom: 0;
}
.posts-sidebar  .widget.widget-tag-cloud a {
	display: inline-block;
	padding: 3px 20px;
	background-color: #E7E8EA;
	border-radius: 50px;
	font-size: 17px;
	color: #6e727d;
	margin: 0 5px 10px 0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.posts-sidebar .widget.widget-tag-cloud a:hover {
	background-color: #034380;
	color: #fff;
}
.posts-sidebar .widget.category-widget .category-link li a:hover {
	color: #034380;
}
.posts-sidebar .widget.widget-testimonial {
	padding: 36px 34px 26px 34px;
	background-color: #003669;
}
.posts-sidebar .widget.widget-testimonial .single-testimonial.single-testimonial-v4 .testimonial-author {
	margin-top: 23px;
}
.posts-sidebar .widget.widget-testimonial .testimonial-slider.testimonial-slider-v4 .slick-dots{
	margin-top: 39px;
}

/* Blog Posts Pagination */
.posts-navigation {
	margin-top: 80px;
}
.posts-navigation ul li a {
	border: 2px solid #E8E9EC;
	border-radius: 5px;
	margin: 0;
	font-weight: 700;
	font-size: 18px;
	color: #003669;
	padding: 0;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 46px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	font-family: 'Open Sans';
}
.posts-navigation ul li {
	margin-right: 10px;
}
.posts-navigation ul li:last-of-type {
	margin-right: 0;
}
.posts-navigation .page-item:not(:first-child) .page-link {
	margin: 0;
}
.posts-navigation ul li.active a {
	background: #034380;
	color: #fff;
	border: 2px solid #034380;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.5);
			box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.5);
	-o-transition: 0.3s;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.posts-navigation ul li a:hover {
	color: #034380;
}
.posts-navigation ul li.active a:hover {
	color: #fff;
}

/* 30. Footer Area */
.footer-area {
	border-top: 1px solid rgb(71 59 240 / 10%);
	border-bottom: 5px solid #034380;
}
.footer-area.bg-white-lilac {
	background-color: #F4F7FA;
}
.footer-area .footer-area-internal{
	padding-top: 76px;
	padding-bottom: 40px;
}
.footer-area .widget-title {
	margin-bottom: 28px;
}
.single-footer-widget{
	margin-bottom: 30px;
}
.single-footer-widget.footer-text-widget img {
	margin-bottom: 15px;
}
.single-footer-widget.footer-text-widget p {
	color: #6e727d;
	font-size: 17px;
	line-height: 32px;
	margin-bottom: 20px;
}
.footer-area .single-footer-widget.contact-us-widget .footer-widget-menu ul li a:after {
	display: none;
}
.footer-widget-menu ul li:last-child a{
	margin-bottom: 0;
}
.footer-area .single-footer-widget.contact-us-widget .footer-widget-menu ul li a {
	padding-left: 33px;
}
.footer-area .single-footer-widget.contact-us-widget .footer-widget-menu ul li a i {
	position: absolute;
	left: 0;
	top: 7px;
}
.footer-contact-link {
	font-size: 18px;
	color: #003669;
	font-family: 'CircularStdMedium';
}
.footer-contact-link:hover{
	color: #034380;
}
.footer-widget-menu ul li a {
	margin-bottom: 10px;
	color: #6e727d;
	font-size: 17px;
	position: relative;
}
.footer-widget-menu ul li a:after {
	content: "\f054";
	position: absolute;
	left: 0;
	font-family: 'Font Awesome 5 Pro';
	font-weight: 800;
	font-size: 14px;
	top: 0px;
}
.footer-widget-menu ul li a {
	padding-left: 20px;
}
.footer-widget-menu ul li a {
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.footer-widget-menu ul li a:hover {
	color: #034380;
}
.footer-social-links ul li {
	display: inline-block;
}
.footer-social-links ul li a {
	display: block;
	height: 35px;
	width: 35px;
	background: #ECEBFD;
	border-radius: 50px;
	text-align: center;
	line-height: 36px;
	font-size: 14px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	margin-right: 8px;
}
.footer-social-links ul li a:hover {
	background: #034380;
	color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.36);
			box-shadow: 0px 10px 30px 0px rgba(3, 67, 128, 0.36);
}
.footer-social-links {
	margin-bottom: 30px;
}
.footer-copyright p {
	font-size: 17px;
	color: #6e727d;
	line-height: 32px;
}
.footer-copyright a {
	color: #034380;
}
.footer-social-links ul li:last-of-type a {
	margin-right: 0;
}
.footer-contact-link i {
	margin-right: 5px;
	color: #034380;
}
.footer-copyright-area {
	border-top: 1px solid #1A53E8;
	padding-top: 30px;
	padding-bottom: 30px;
}
.footer-copyright-area .footer-copyright{
	text-align: right;
}
.footer-area.footer-area-v1 {
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: scroll;
	background-position: bottom;
}
.footer-area.footer-area-v1 .footer-area-internal{
	padding-top: 80px;
	padding-bottom: 40px;
}
.footer-area.footer-area-v1 .footer-social-links{
	margin-bottom: 20px;
}
.footer-area.footer-area-v1 .single-footer-widget.footer-text-widget p{
	margin-bottom: 10px;
}
.single-footer-widget .footer-app-download .filled-btn {
	margin-bottom: 10px;
}
/* Footer Area V2 */
.footer-area.footer-area-v2 {
	border-top: 0;
	border-bottom: 0;
}
.footer-area.footer-area-v2 .footer-area-internal {
	border-top: 1px solid #1A53E8;
}
.footer-area.footer-area-v2 .widget-title {
	color: #fff;
}
.footer-area.footer-area-v2 .footer-widget-menu ul li a {
	color: #fff;
}
.footer-area.footer-area-v2 .single-footer-widget.footer-text-widget p {
	color: #fff;
}
.footer-area.footer-area-v2 .footer-contact-link {
	color: #fff;
	margin-bottom: 10px;
	font-family: 'CircularStdBook';
}
.footer-area.footer-area-v2 .footer-contact-link i {
	color: #fff;
}
.footer-area.footer-area-v2 .footer-copyright p {
	color: #fff;
}
.footer-area.footer-area-v2 .footer-copyright a {
	color: #fff;
}
.footer-area.footer-area-v2 .footer-copyright a:hover {
	color: #F65911;
}
.footer-area.footer-area-v2 .footer-social-links ul li a {
	background: transparent;
	border: 2px solid rgb(255 255 255 / 15%);
	color: #fff;
}
.footer-area.footer-area-v2 .footer-social-links ul li a:hover {
	background-color: #F65911;
	border-color: #F65911;
}
.footer-area.footer-area-v2 .footer-widget-menu ul li a:hover {
	color: #F65911;
}
.footer-area.footer-area-v2.bg-tuna .footer-copyright-area{
	border-color: rgb(255 255 255 / 15%);
}
.footer-area.footer-area-v2 .footer-copyright-area{
	padding: 35px 0;
}
/* Footer Area V3 */
.footer-area.footer-area-v3,
.footer-area.footer-area-v3 .footer-copyright-area {
	border: 0;
}
.footer-area.footer-area-v3 .footer-widget-menu ul li a:hover {
	color: #8208fe;
}
.footer-area.footer-area-v3 .footer-social-links ul li a {
	color: #6e727d;
}
.footer-area.footer-area-v3 .footer-social-links ul li a:hover {
	background: #E25C6A;
	-webkit-box-shadow:  0px 10px 30px 0px rgba(226, 92, 106, 0.36);
			box-shadow:  0px 10px 30px 0px rgba(226, 92, 106, 0.36);
	color: #fff;
}
.footer-area.footer-area-v3 .footer-copyright a {
	color: #6e727d;
}
.footer-area.footer-area-v3 .footer-copyright a:hover {
	color: #8208FE;
}
.footer-area.footer-area-v3 .footer-app-download .filled-btn {
	-webkit-box-shadow: none;
			box-shadow: none;
}
.footer-area.footer-area-v3 .footer-copyright-area{
	padding: 30px 0;
}

/* Footer Area V4 (Home V6) */
.footer-area.footer-area-v4,
.footer-area.footer-area-v4 .footer-copyright-area {
	border: 0;
}
.footer-area.footer-area-v4 .footer-area-internal {
	border-bottom: 1px solid #394565;
}
.footer-area.footer-area-v4 .widget-title {
	color: #fff;
	margin-bottom: 23px;
}
.footer-area.footer-area-v4 .footer-widget-menu ul li a {
	color: #8496c6;
}
.footer-area.footer-area-v4 .single-footer-widget.footer-text-widget p {
	color: #8496c6;
}
.footer-area.footer-area-v4 .footer-contact-link {
	color: #8496c6;
	margin-bottom: 10px;
	font-family: 'CircularStdBook';
}
.footer-area.footer-area-v4 .footer-contact-link i {
	color: #8496c6;
}
.footer-area.footer-area-v4 .footer-copyright p {
	color: #8496c6;
}
.footer-area.footer-area-v4 .footer-copyright a {
	color: #8496c6;
}
.footer-area.footer-area-v4 .footer-copyright a:hover {
	color: #fff;
}
.footer-area.footer-area-v4 .footer-social-links ul li a {
	background: #3C4869;
	border: 2px solid #3C4869;
	color: #7285b7;
}
.footer-area.footer-area-v4 .footer-widget-menu ul li a i{
	color: #034380;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.footer-area.footer-area-v4 .footer-social-links ul li a:hover {
	background-color: #034380;
	border-color: #034380;
	color: #fff;
	-webkit-box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 36%);
	box-shadow: 0px 10px 30px 0px rgb(51 119 255 / 36%);
}
.footer-area.footer-area-v4 .footer-widget-menu ul li a:hover,
.footer-area.footer-area-v4 .footer-widget-menu ul li a:hover i {
	color: #fff;
}
.footer-area.footer-area-v4 .footer-copyright-area{
	padding: 26px 0 26px;
}
.footer-area.footer-area-v4 .footer-area-internal {
	padding-top: 75px;
	padding-bottom: 43px;
}

/* Gallery Widget */
.single-footer-widget.gallery-widget .gallery-list {
	padding-top: 8px;
	position: relative;
	overflow: hidden;
	margin-left: -10px;
	margin-right: -10px;
}
.single-footer-widget.gallery-widget .gallery-list li {
	float: left;
	padding: 0px 5px 0px;
}
.single-footer-widget.gallery-widget .gallery-list li .gallery-inner .gallery-img-box img {
	border-radius: 7px;
}
.single-footer-widget.gallery-widget .gallery-list li .gallery-inner {
	position: relative;
	display: inline-block;
}
.single-footer-widget.gallery-widget .gallery-list li .gallery-inner .gallery-inner-overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	opacity: 0;
	background: #034380;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
		-ms-flex-direction: row;
			flex-direction: row;
	-webkit-box-pack: center;
		-ms-flex-pack: center;
			justify-content: center;
	-webkit-box-align: center;
		-ms-flex-align: center;
			align-items: center;
	-webkit-transform: skew(0deg, 0deg) scale(1.2, 1.2);
		-ms-transform: skew(0deg, 0deg) scale(1.2, 1.2);
			transform: skew(0deg, 0deg) scale(1.2, 1.2);
	-webkit-transition: all 0.4s linear;
	-o-transition: all 0.4s linear;
	transition: all 0.4s linear;
	height: 100%;
	width: 100%;
}
.single-footer-widget.gallery-widget .gallery-list li:hover .gallery-inner .gallery-inner-overlay {
	opacity: 0.75;
	-webkit-transform: translate(0px);
		-ms-transform: translate(0px);
			transform: translate(0px);
}
.single-footer-widget.gallery-widget .gallery-list li .gallery-inner .gallery-inner-overlay a {
	color: #fff;
	position: relative;
	display: inline-block;
	font-size: 24px;
	line-height: 24px;
	-webkit-transform: scale(0);
		-ms-transform: scale(0);
			transform: scale(0);
	-webkit-transition: all 500ms ease 100ms;
	-o-transition: all 500ms ease 100ms;
	transition: all 500ms ease 100ms;
}
.single-footer-widget.gallery-widget .gallery-list li:hover .gallery-inner .gallery-inner-overlay a {
	-webkit-transform: scale(1.0);
		-ms-transform: scale(1.0);
			transform: scale(1.0);
	-webkit-transition: all 700ms ease 600ms;
	-o-transition: all 700ms ease 600ms;
	transition: all 700ms ease 600ms;
}
.single-footer-widget.gallery-widget .gallery-list li .gallery-inner .gallery-img-box {
	overflow: hidden;
	position: relative;
	border-radius: 7px;
}




.step .btn-step {
	display: block;
	position: relative;
	margin: 0 auto;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	cursor: pointer;
}
.step .btn-step app-feather-icons {
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.step .btn-step img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 40px;
	width: 40px;
}
.step .step-txt {
	margin-top: 5px;
	color: #161C2D;
	text-align: center;
}
.step .step-txt:hover .btn-step {
	background: #C94102 !important;
}
.step .step-txt:hover .btn-step app-feather-icons {
	color: #ffffff !important;
}
.step .step-txt:hover .step-txt {
	color: #C94102;
}

.steps {
	font-size: 0.85rem;
}

.progress-bar-small {
	height: 5px;
	margin: 0 auto;
	margin-top: 10px;
	width: 75%;
	text-align: center;
}


.input-group-input {
	border-radius: 50px;
	padding: 0 20px;
	padding-left: 35px;
	font-size: 18px;
	color: #1a1b1e;
	height: 80px;
}
.input-group-input.left-input-group {
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.input-group-input.right-input-group {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-group-medium .input-group-input {
	height: 50px;
}
.input-group-medium .input-group-btn {
	position: relative;
	right: -8px;
	height: 40px !important;
}
.input-group-medium .input-group-btn app-feather-icons {
	position: relative;
	top: -7px;
	font-size: 12px;
}


.input-group-btn {
	box-shadow: none !important;
	font-size: 13px;
	padding: 10px 30px;
	display: grid;
	place-items: center;
	height: 100%;
}
.input-group-btn svg {
	width: 22px;
}
.input-group-btn.left-btn {
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.input-group-btn.middle-btn {
	border-radius: 0px;
}
.input-group-btn.right-btn {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}
.input-group-btn.left-btn:after {
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.input-group-btn.middle-btn:after {
	border-radius: 0px;
}
.input-group-btn.right-btn:after {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}


.input-group.single-input {
	position: relative;
}
.input-group.single-input .input-group-input {
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
}
.input-group.single-input .input-group-input.btn-single-and-btn-txt {
	padding-right: 100px;
}
.input-group.single-input .input-group-append {
	position: absolute;
	top: 50%;
	right: 28px;
	transform: translateY(-50%);
	padding-left: 10px;
	padding-right: 0px;
	z-index: 20;
}
.input-group.single-input .input-group-btn {
	height: 55px;
	font-size: 15px;
	font-family: 'Open Sans';
	display: block;
	border-radius: 40px !important;
}



.input-group.double-input {
	position: relative;
}
.input-group.double-input .input-group-input {
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
}
.input-group.double-input .input-group-input.btn-double-and-btn-txt {
	padding-right: 250px;
}
.input-group.double-input .input-group-append {
	position: absolute;
	top: 50%;
	right: 28px;
	transform: translateY(-50%);
	padding-left: 10px;
	padding-right: 0px;
	z-index: 20;
}
.input-group.double-input .input-group-btn {
	height: 55px;
	font-size: 15px;
	font-family: 'Open Sans';
	display: block;
}

.input-group.search-box-opened .input-group-input {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.list-group-item {
	cursor: pointer;
}
.list-group-item .item-data .item-title {
	color: #495057;
}
.list-group-item .item-data .item-text {
	color: #495057;
	font-weight: 300;
}

.list-group-item:hover {
	background: #F65911;
}
.list-group-item:hover .item-data .item-title {
	color: #FFFFFF;
}
.list-group-item:hover .item-data .item-text {
	color: #FFFFFF;
}

.list-group-item.active {
	background: #034380;
}
.list-group-item.active .item-data .item-title {
	color: #FFFFFF;
}
.list-group-item.active .item-data .item-text {
	color: #FFFFFF;
}

.list-group > .list-group-item:first-of-type {
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}

.card-list-item {
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 45px 0px rgba(3, 67, 128, 0.1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.card-list-icon i {
  font-size: 1.75rem;
  color: #034380;
}
.card-list-txt {
  color: #034380;
  margin-left: 8px;
  font-size: 17px;
  font-weight: 400;
}
.card-list-item:hover {
  background-color: #034380;
  transform: translateX(10px);
}
.card-list-item:hover .card-list-icon i {
  color: #ffffff;
}
.card-list-item:hover .card-list-txt {
  color: #ffffff;
}
.card-list-item.active {
  background-color: #FFFFFF;
}
.card-list-item.active .card-list-icon i {
  color: #F65911;
}
.card-list-item.active .card-list-txt {
  color: #F65911;
}
.card-list-item.active:hover {
  background-color: #F65911;
  transform: translateX(10px);
}
.card-list-item.active:hover .card-list-icon i {
  color: #ffffff;
}
.card-list-item.active:hover .card-list-txt {
  color: #ffffff;
}


.custom-scroll {
	overflow-y: overlay;
	overflow-x: hidden;
}
.custom-scroll::-webkit-scrollbar {
  width: 10px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: #d9d9eb;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #F65911;
  box-shadow: inset 0 0 6px #C94102;
  border-radius: 10px;
}




.loader-msg {
  margin-top: -40px;
  font-size: 12px;
	text-align: center;
}

app-loader-inner-small {
	width: 100%;
}

.no-data-container {
  padding: 3em 6em;
	width: 100%;
}
.no-data-icon app-feather-icons {
	color: #F65911;
}
.no-data-icon app-feather-icons svg {
	width: 80px;
	height: 80px;
}

.error-data-container {
  padding: 3em 6em;
	width: 100%;
}
.error-data-icon {
	color: #dc3545;
}
.error-data-icon svg {
	width: 80px;
	height: 80px;
}

.table-no-data app-feather-icons {
	color: #F65911;
}
.table-no-data app-feather-icons svg {
	width: 60px;
	height: 60px;
}


.progress {
	background-color: #c5cbdd;
}




/* map */
/* ----------------------------------------------------------------------- */
.map-instructions {
  color: #1d2127;
  opacity: 0.85;
	font-size: 13px;
	line-height: 18px;
}
.map-instructions.light-txt {
  color: #FFFFFF;
}
.mapboxgl-canvas-container {
  width: 100%;
}
.marker-container {
  position: relative;
}
.mapboxgl-marker .customMarker {
  position: absolute;
  cursor: pointer;
  background: #F65911;
  width: 100px;
  height: 100px;
  /* -width/2 */
  margin-left: -50px;
  /* -height + arrow */
  margin-top: -110px;
  border-radius: 10px;
  padding: 0px;
}
.mapboxgl-marker .customMarker:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 40px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #F65911 transparent;
  display: block;
  width: 0;
}
.mapboxgl-marker .customMarker img {
  width: 90px;
  height: 90px;
  margin: 5px;
  border-radius: 10px;
}
.mapArea {
  height: 15em;
  width: 100%;
}
.editMapArea {
  height: 15em;
  width: 100%;
}


.form-card {
	padding: 50px;
	margin: 20px;
	background: #fdfeff;
	border-radius: 20px;
	border-color: #dbe6f1;
	text-align: initial;
}

.required-lbl {
  color: #dc3545;
}

label {
	margin-bottom: 0.15em;
}

.error-messages,
.help-messages {
	margin-top: 5px;
	line-height: 1em;
}

.help-messages {
	color: #777777;
}

small {
	font-weight: 300;
}

.error-messages span,
.help-messages span {
	font-weight: 300 !important;
	line-height: 1em !important;
	font-size: 0.75em;
}

.note-section {
  margin-top: 30px;
  background: #dfe7ff;
  padding: 30px 25px;
  text-align: left;
  border-radius: 10px;
}

.note-section.help-section {
	padding: 10px;
}

.buttons-container {
	display: grid;
	place-items: center;
	height: 100%;
}


.regular-form .form-control {
	border-radius: 50px;
	height: 50px;
}
.regular-form .form-control::placeholder {
	font-weight: 300;
	color: #b5b7be;
}
.regular-form .buttons-container {
	position: relative;
	height: 100%;
}
.regular-form .buttons-container.right-btns button {
	position: absolute;
	top: 28px;
}

/* input group
----------------------------------------------------------------------- */
.input-group-prepend .input-group-text,
.input-group-prepend .input-group-btn {
	display: block;
	min-width: 60px;
	padding-top: 12px;
	border-radius: 0;
	z-index: 10;
}
.input-group-prepend .input-group-text :first-child,
.input-group-prepend .input-group-btn :first-child,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-prepend>.input-group-btn {
	border-top-left-radius: 40px !important;
	border-bottom-left-radius: 40px !important;
}
.input-group-append .input-group-text,
.input-group-append .input-group-btn {
	border-radius: 0;
	z-index: 10;
}
.input-group-append .input-group-text:first-child,
.input-group-append .input-group-btn:first-child {
	border-top-right-radius: 40px !important;
	border-bottom-right-radius: 40px !important;
}

/* input dropdown
----------------------------------------------------------------------- */
.input-dropdown .btn-dropdown {
	font-weight: 400;
	border-radius: 0px;
	border-top-left-radius: 40px;
	border-bottom-left-radius: 40px;
}
.input-dropdown .btn-dropdown .input-dropdown-caret {
	position: absolute;
	top: 19px;
	right: 8px;
	pointer-events: none;
	display: inline-block;
	height: 0;
	width: 0;
	border-color: #999 transparent transparent;
	border-style: solid;
	border-width: 5px 5px 2.5px;
}
.input-dropdown .form-control {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}
.input-dropdown .dropdown-menu .dropdown-item span {
	color: #054F93;
}
.input-dropdown .dropdown-menu .dropdown-item span.active {
	color: #FFFFFF;
}


/* search selection
----------------------------------------------------------------------- */
.search-selection {
	position: absolute;
	top: -17px;
	left: 15px;
	width: 92.5%;
	z-index: 20;
}
.search-selection .list-group-item {
	display: flex;
}

.search-selection .list-group-item:hover {
	background: #F65911;
	color: #FFFFFF;
}

.search-selection.address-selection .address-data .address-name {
	line-height: 1.15em;
}
.search-selection.address-selection .address-data .address-location {
	line-height: 1.15em;
	font-weight: 300;
	font-size: 0.85em;
}
.search-selection.address-selection .address-data .address-country {
	line-height: 1.15em;
	font-weight: 300;
	font-size: 0.85em;
}

.search-selection.user-selection .social-img-wrap {
	height: 40px;
	width: 40px;
}
.search-selection.user-selection .social-img-wrap .social-img {
	width: 35px;
	height: 35px;
	top: 0.5px;
	left: 0.5px;
}
.search-selection.user-selection .social-img-wrap .social-img img {
	width: 35px;
}
.search-selection.user-selection {
	margin-left: 10px;
}
.search-selection.user-selection .user-data .user-name {
	font-size: 0.95rem;
	margin: 0px;
	cursor: pointer;
	-webkit-transition: all 0.2s ease-in-out 0s !important;
	-o-transition: all 0.2s ease-in-out 0s !important;
	transition: all 0.2s ease-in-out 0s !important;
}
.search-selection.user-selection .user-data .user-name:hover {
	color: #F65911;
}
.search-selection.user-selection .user-data .user-document {
	font-size: 0.85rem;
	line-height: 1em;
	margin: 0px;
}





/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"].solid-range {
	-webkit-appearance: none;
	 appearance: none;
	 background: transparent;
	 cursor: pointer;
	 width: 100%;
}
/* Removes default focus */
input[type="range"].solid-range:focus {
 outline: none;
}
/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"].solid-range::-webkit-slider-runnable-track {
	background-color: #F7B394;
	border-radius: 0.5rem;
	height: 0.5rem;
}
/* slider thumb */
input[type="range"].solid-range::-webkit-slider-thumb {
 -webkit-appearance: none; /* Override default look */
	appearance: none;
	margin-top: -12px; /* Centers thumb on the track */

	/*custom styles*/
	background-color: #F65911;
	height: 2rem;
	width: 1rem;
	border-radius: 5px;
}
input[type="range"].solid-range:focus::-webkit-slider-thumb {   
 /* border: 1px solid #F65911;
 outline: 3px solid #003669;
 outline-offset: 0.125rem;  */
}

/******** Firefox styles ********/
/* slider track */
input[type="range"].solid-range::-moz-range-track {
	background-color: #F7B394;
	border-radius: 0.5rem;
	height: 0.5rem;
}
/* slider thumb */
input[type="range"].solid-range::-moz-range-thumb {
	border: none; /*Removes extra border that FF applies*/
	border-radius: 0; /*Removes default border-radius that FF applies*/

	/*custom styles*/
	background-color: #F65911;
	height: 2rem;
	width: 1rem;
}
input[type="range"].solid-range:focus::-moz-range-thumb {
 /* border: 1px solid #053a5f;
 outline: 3px solid #053a5f;
 outline-offset: 0.125rem;  */
}

.range-slider-lbl {
	font-size: 0.85em;
	line-height: 1.15em;
}


.btn-edit {
	position: relative;
	width: 25px;
	height: 25px;
	padding: 5px;
	box-shadow: none !important;
}

.btn-edit i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	margin: 0px;
	font-size: 10px;
}



.form-group.inline-input-btn {
	position: relative;
	margin: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 40px;
}
.form-group.inline-input-btn:after {
  position: absolute;
	content: "";
	height: 40px;
	width: 3px;
	background-color: #E5ECFC;
	left: 50%;
}
.form-group.inline-input-btn:after {
	display: none;
}
.form-group.inline-input-btn input {
  height: 80px;
	padding: 0 20px;
	border: 1px solid #dee2e6;
  background-color: #ffffff;
}
.form-group.inline-input-btn input::-webkit-input-placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.form-group.inline-input-btn input::placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.form-group.inline-input-btn input:-ms-input-placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.form-group.inline-input-btn input::-ms-input-placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.form-group.inline-input-btn input,
.form-group.inline-input-btn input::placeholder{
	font-size: 18px;
	color: #1a1b1e;
}
.form-group.inline-input-btn button {
	position: absolute;
	right: 15px;
	padding-left: 20px;
	padding-right: 20px;
}

.form-group.inline-input-btn input {
	border-radius: 50px;
	padding-left: 35px;
}

.range-help p {
	font-size: 0.85em;
	font-style: italic;
}

.btn-link-txt {
	font-weight: 600;
}

.form-control {
	padding: 0.375rem 0.95rem;
}

.btns-card-container {
	position: absolute;
	top: 15px;
	right: 15px;
}

.btn-card {
	cursor: pointer;
	position: relative;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #F65911;
	margin-left: 10px;
}
.btn-card app-feather-icons {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #FFFFFF;
}
.btn-card app-feather-icons svg {
	height: 20px;
	width: auto;
}
.btn-card:hover {
	background: #034380;
}
.btn-card.btn-card-edit app-feather-icons {
	margin-left: -2px;
	margin-top: -2px;
}
.btn-card.btn-card-edit {
	background: #51bb25;
}
.btn-card.btn-card-edit:hover {
	background: #1d5d02;
}
.btn-card.btn-card-delete {
	background: #dc3545;
}
.btn-card.btn-card-delete:hover {
	background: #9a0110;
}

.phone-select-btn {
	cursor: pointer;
	border-top-left-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
}
.phone-select-img {
  height: 22px;
}
.phone-select-txt {
  font-size: 0.9em;
}

.icon-boxes-grid {
	height: auto;
	max-height: 40rem;
}
.icon-boxes-wrapper.icon-boxes-grid .single-iconic-box.iconic-box-v2 {
  position: relative;
  width: 30%;
  margin: 10px;
  padding-bottom: 100px;
  border: 1px solid #ddd;
  border-radius: 20px;
}
.iconic-box-icon {
  position: relative;
}
.iconic-box-btn {
  position: absolute;
  bottom: 40px;
}

.incident-icon {
  width: 55px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.incident-icon-type-accident {

}
.incident-icon-type-stop-yield {

}
.incident-icon-type-speeding {
  width: 50px;
  margin-top: -1px;
}
.incident-icon-type-dui {

}
.incident-icon-type-license {

}
.incident-icon-type-major {
  margin-left: 2px;
}
.incident-icon-type-no-insurance {

}
.incident-icon-type-passing {

}
.incident-icon-type-claim {
  width: 53px;
}
.incident-icon-type-other {

}

.custom-list-card.single-feature-box .feature-box-icon {
  background: #F65911;
  border-radius: 50%;
  position: relative;
}
.custom-list-card.single-feature-box {
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  box-shadow: none;
	cursor: pointer;
}

.custom-list-card.single-feature-box .feature-box-icon {
  width: 50px;
  height: 50px;
  max-width: 50px;
  min-width: 50px;
  margin-right: 20px;
  line-height: 0;
}

.custom-list-card.single-feature-box .feature-box-content h4 {
  margin-bottom: -5px;
}

.custom-list-card.single-feature-box:hover .feature-box-icon img, 
.custom-list-card.single-feature-box:hover .feature-box-icon i {
  transform: translate(-50%, -50%);
}

.custom-list-card.single-feature-box.single-feature-box-v2:before, 
.custom-list-card.single-feature-box.single-feature-box-v2:after {
  background-color: #F65911;
}


.single-custom-list.custom-list-card.single-feature-box .feature-box-content h4 {
	font-size: 0.85em;
	font-weight: 300;
}
.single-custom-list.custom-list-card.single-feature-box .feature-box-icon {
	position: absolute;
	top: 8px;
	left: 8px;
	width: 45px;
  height: 45px;
  max-width: 45px;
  min-width: 45px;
}
.single-custom-list.custom-list-card.single-feature-box .feature-box-icon .incident-icon {
	width: 40px;
}
.single-custom-list.custom-list-card.single-feature-box {
	margin: 10px;
}
.single-custom-list.custom-list-card.single-feature-box .feature-box-icon {
	background: #034380;
}

.single-custom-list.custom-list-card.single-feature-box .feature-box-content {
	position: relative;
	left: 40px;
	top: -2px;
}

select:focus {
  outline: none;
}



.modal-content .close:focus {
	box-shadow: none !important;
	right: 0px !important;
}



/* Cambia el color de la barra de selección */
.ng5-slider .ng5-slider-span {
  /* background-color: #4CAF50; */
}

/* Cambia el color de los controles deslizantes (handles) */
.ng5-slider .ng5-slider-pointer {
  background-color: #FFC107;
}

/* Cambia el color de la burbuja de valor (tooltip) */
.ng5-slider .ng5-slider-bubble {
  background-color: #2196F3;
}

/* Cambia el color de la barra (track) */
.ng5-slider .ng5-slider-full-bar,
.ng5-slider .ng5-slider-selection-bar,
.ng5-slider .ng5-slider-floor,
.ng5-slider .ng5-slider-ceil {
  /* background-color: #E0E0E0; */
}

.ng5-slider .ng5-slider-bubble {
	background-color: transparent;
	margin-bottom: -10px;
	font-size: 11px !important;
}

.ng5-slider .ng5-slider-bubble.ng5-slider-model-value, 
.ng5-slider .ng5-slider-bubble.ng5-slider-model-high {
	/* height: 30px;
	width: 30px;
	border-radius: 50%;
	margin: 0 auto;
	color: #FFFFFF;
	background-color: #F65911;
	text-align: center; */
}


.ng5-slider .ng5-slider-pointer {
	width: 21px !important;
	height: 21px !important;
	top: -8px !important;
	background-color: #003669 !important;
}

.ng5-slider .ng5-slider-pointer::after {
	position: absolute !important;
	top: 6px !important;
	left: 6px !important;
	width: 9px !important;
	height: 9px !important;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
	background-color: #F65911 !important;
}

.ng5-slider .ng5-slider-selection {
	background-color: #003669 !important;
}

.footer-logo img {
	width: 120px;
}



app-driver-names,
app-driver-gender,
app-driver-birthday,
app-driver-demographic,
app-driver-education,
app-driver-score-credit,
app-driver-license,
app-driver-contact,
app-incidents,
app-vehicle-types,
app-vehicle-year,
app-vehicle-makes,
app-vehicle-models,
app-vehicle-trim,
app-vehicle-transmission,
app-vehicle-use,
app-vehicle-mileage,
app-vehicle-payment,
app-vehicle-parking,
app-insurance-status,
app-insurance-companies,
app-insurance-expiration,
app-insurance-period,
app-how-long-insuranced,
app-current-coverage,
app-why-not-insuranced,
app-how-long-not-insuranced,
app-insurance-buy-today,
app-insurance-full-coverage,
app-insurance-coverages,
app-data-resume,
app-plans-list,
app-finish
{
	width: 100%;
}



/* CSS */
.radio-container {
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}
.radio-container.radio-no {
	margin-left: 10px;
}

/* Oculta el input radio original */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Crea un círculo personalizado */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #d9d9eb;
  border-radius: 50%;
}

/* Al pasar el cursor - cambia el color de fondo del círculo */
.radio-container:hover input ~ .checkmark {
  background-color: #898989;
}

/* Al seleccionar el radio button - cambia el color del círculo */
.radio-container input:checked ~ .checkmark {
  background-color: #F65911;
}

/* Crea el indicador interior del radio button */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Muestra el indicador interior cuando el radio button está seleccionado */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Estilo del indicador interior (el círculo en el centro) */
.radio-container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}


label {
	line-height: 1.15em;
}

.btn-icon-only {
  padding: 0.75rem 0.75rem;
  height: 45px;
  width: 45px;
  font-size: 12px;
  display: inline-block;
  position: relative;
	border-radius: 50%;
	background: #054F93;
}
.btn-icon-only svg {
	height: 18px;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.btn-icon-only i {

	vertical-align: middle;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.btn-icon-only.btn-icon-text-left {
	margin-left: 8px;
}
.btn-icon-only.btn-filter app-feather-icons {
	position: relative;
	top: 0px;
	left: -2px;
}
.btn-with-outside-txt-lbl {
  cursor: pointer;
  float: right;
  margin-top: -5px;
}
.btn-with-outside-txt-lbl:hover .btn-inner-txt {
	color: #054F93;
}


/* think */
/* ----------------------------------------------------------------------- */

.think-link {
  color: #FF5E00 !important;
  -webkit-transition: all 0.2s ease-in-out 0s !important;
  -o-transition: all 0.2s ease-in-out 0s !important;
  transition: all 0.2s ease-in-out 0s !important;
}
.think-link:hover,
.think-link:active,
.think-link:focus {
  color: #ffffff !important;
}
.think-link.dark:hover,
.think-link.dark:active,
.think-link.dark:focus {
  color: #1d2127 !important;
}



/* responsive */
/* ----------------------------------- */

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
	.filled-btn {
		padding: 0.75rem 0.75rem !important;
    height: 45px;
    width: 45px;
    font-size: 12px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
	}
	.filled-btn i,
	.filled-btn svg {
		vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	}
	.btn-help {
    margin-top: 0px !important;
    margin-right: 0px !important;
	}
  .btn-txt-hide {
		display: none;
	}
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
	.filled-btn {
		padding: 0.75rem 0.75rem !important;
    height: 45px;
    width: 45px;
    font-size: 12px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
	}
	.filled-btn i,
	.filled-btn svg {
		vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	}
	.btn-help {
    margin-top: 0px !important;
    margin-right: 0px !important;
	}
  .btn-txt-hide {
		display: none;
	}
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
	.filled-btn {
		padding: 0.75rem 0.75rem !important;
    height: 45px;
    width: 45px;
    font-size: 12px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
	}
	.filled-btn i,
	.filled-btn svg {
		vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	}
	.btn-help {
    margin-top: 0px !important;
    margin-right: 0px !important;
	}
  .btn-txt-hide {
		display: none;
	}
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}